/* Arrows */

.arrow_back {
  width: 21px;
  height: 21px;
  font-family: MaterialIcons;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-principal);
}
.Rectangle-Copy {
 
 
}
.slick-prev,
.slick-next
{ 
	 border: solid 1px #fff;
  background-color:#fff;
	width: 35px;
  height: 35px;
  padding: 7px;
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: #fff;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: #fff;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
   font-family: MaterialIcons;
  font-size: 21px;
    line-height: 1;

    opacity: .75;
    color: #d8d8d8;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 0px;z-index: 9;
}
[dir='rtl'] .slick-prev
{
    right: 0px;
    left: auto;
}
.slick-prev:before
{
    content: url("../images/arrow_left.jpg");
	right: 25px;
			
}
[dir='rtl'] .slick-prev:before
{
    content: url("../images/arrow_right.jpg");
	position: absolute;
	color: #000;
}

.slick-next
{
    right: 0px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: 0px;
}
.slick-next:before
{
     content: url("../images/arrow_right.jpg");
		color: #000;
}
[dir='rtl'] .slick-next:before
{
    content: url("../images/arrow_left.jpg");
		color: #000;
}