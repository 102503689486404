.foreign_rights_intro_wrapper .cta a {
  border: 3px solid #000;
  background-color: #000;
  height: 52px;
  border-radius: 0;
  font-weight: 700;
  font-size: 17px;
  color: #FFF;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foreign_rights_intro_wrapper {
  display: flex;
  gap: 50px;
}

.left_sec {
  flex-basis: 80%;
}

.right_sec {
  flex-basis: 20%;
  margin-top: 30px;
}
.pdf_wrapper {
  margin-top: 100px;
}
.pdf_wrapper a
{
  border: 3px solid #000;
  border-radius: 0;
  font-weight: 700;
  font-size: 17px;
  color: #000;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  text-align: center;
}


@media (max-width: 1141px) 
{
  .foreign_rights_intro_wrapper {
    display: block !important;
  }
  .cta, .pdf_wrapper {
    display: flex;
    justify-content: center;
  }
  .cta a, .pdf_wrapper a{width: 50%;height: 52px}
  .pdf_wrapper {
    margin-top: 0px;
  }
}
@media (max-width: 768px) 
{
  .cta a, .pdf_wrapper a{width: 100%;height: 52px}
}
