.logo-promo img{
	width: 300px;
}
.promotion-banner{
	margin-top:139px
}
.promotion-header-wrapper {
	display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 20px;
    z-index: 9;
    position: fixed;
    width: 100%;
    background: #fff; 
    top: 0;
  }
  
  .promotion-header-wrapper .right-top-nav li{
    cursor: pointer;
    color: #000;
    transition: color 0.3s;
    position: relative;
	font-size: 0.8rem;
	font-weight: 600;
  }
  
  .promotion-header-wrapper .right-top-nav ul
  {
    text-align: right;
  }
  
  .active-dot {
    position: absolute;
    top: 50%;
    right: -15px; /* Adjust the position of the dot */
    transform: translate(0%, -50%);
    width: 8px;
    height: 8px;
    background-color: #A20825;
    border-radius: 50%;
    display: none;
  }
  
  
  .promotion-header-wrapper .right-top-nav li.active .active-dot {
    display: block;
  }
  
  .promotion-section-3 {
    background-color: #bb1930;
    padding: 50px 0;
  }
  
  .promotion-section-3 .section-3-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
  }
  
  .promotion-section-2 {
    padding: 50px 0;
  }
  .promotion-section-2 h2{
    margin-bottom:30px;
  }
  
  .promotion-section-3 .section-3-wrapper .right-sec h2,
  .promotion-section-3 .section-3-wrapper .right-sec p {
    color: #fff;
    padding-bottom: 10px;
  }
  
  .promotion-section-4 .img-wrapper {
    height: 280px;
    overflow: hidden;
	display: flex;
	align-items: flex-end;
  }
  
  .promotion-section-4 .row {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
.promotion-section-4 .promotion-product p
{
  min-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  text-align: center;
  margin-bottom: 20px;
}
.promotion-section-4 .img-wrapper img{width: 100%;}
  .promotion-product.col-2 {
    flex: 0 0 20%;
    box-sizing: border-box;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: 30px;
  }
  
  .product-listing {
    width: 80%;
    margin: 20px auto;
  }
  
  .product-listing .btn2 {
    margin-top: 0;
  }
  
  .buybutton {
    display: flex;
    justify-content: center;
  }
  
  .section-6-wrapper h2 {
    color: #fff;
    margin-bottom: 30px;
  }
  
  .section-6-wrapper p {
    color: #fff;
  }
  
  .promotion-section-5 .section-7-banner {
    width: 100%;
    padding: 50px 0;
  }
  
  .promotion-section-5 .section-5-banner img {
    width: 100%;
  }
  .promotion-section-5  img {
    width: 100%;
  }
  .two-column-wrapper {
    display: flex;	
  }
  .two-column-wrapper .section-5-banner-left{
    padding-right: 5px;
  }
  .two-column-wrapper .section-5-banner-right{    
	padding-left: 5px;
  }

  .promotion-section-6 .news-desc h1 {
    font-weight: bold;
    font-size: 28px;
  }
  
  .promotion-section-6 {
    padding: 50px 0px;
    background-color: #000;
  }
  .promotion-space{
	width: 100px;
	height: 10px;
  }
  .promotion-section-4 .title{
	text-align: center;
	padding: 50px;
  }
  .author-date {
    display: flex;
    gap: 20px;
    color: #fff;
    margin-top: 10px;
  }
  
  .author-date p:nth-child(2) {
    color: #aaa;
  }
  
  .news-title {
    font-weight: bold;
    font-size: 28px;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .news-desc {
    font-size: 16px;
    color: #fff;
  }
  
  .section-6-news {
    display: flex;
    gap: 50px;
  }
  
  .section-6-news .left-sec,
  .section-6-news .right-sec {
    width: 100%;
  }
  
  .news-img {
    width: 100%;
  }
  
  .news-img img {
    width: inherit;
  }
  
  .promotion-newsletter-section {
    background-color: #000;
    padding: 50px 0px 100px;
    border-top: 8px solid #fff;
    border-bottom: 2px solid #bb1930;
  }
  
  .promotion-newsletter-section h4 {
    color: #cb1620;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
  
  }
  
  .newsletter-wrapper {
    width: 500px;
    margin: 0 auto;
  }
  
  .promotion-newsletter-section .email-input {
    background-color: transparent;
    color: #fff;
    padding-left: 10px;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
  }
  
  .promotion-newsletter-section .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    color: #aaa;
    margin-top: 30px;
  }
  
  /* Hide the browser's default checkbox */
  .promotion-newsletter-section .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .promotion-newsletter-section .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #aaa;
  }
  
  /* On mouse-over, add a grey background color */
  .promotion-newsletter-section .custom-checkbox:hover input~.checkmark {
    background-color: #aaa;
  }
  
  /* When the checkbox is checked, add a blue background */
  .promotion-newsletter-section .custom-checkbox input:checked~.checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .promotion-newsletter-section .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .promotion-newsletter-section .custom-checkbox input:checked~.checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .promotion-newsletter-section .custom-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .promotion-newsletter-section .subscribe-btn {
    background-color: #bb1930;
    color: #fff;
    padding: 10px 20px;
    width: 200px;
    font-weight: bold;
  }
  
  .subscribe-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  
  .promotion-newsletter-section .subscribe-btn.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  
  .promotion-footer-section {
    padding: 15px 50px 5px;
    background-color: #000;
  }
  
  .promotion-footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .promotion-footer-wrapper .left-nav-menu,
  .promotion-footer-wrapper .right-nav-menu {
    display: flex;
    gap: 10px;
  }
  
  .promotion-footer-wrapper .left-nav-menu a,
  .promotion-footer-wrapper .left-nav-menu label {
    font-family: 'Basier Circle';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    color: #aaa;
  }
  
  .promotion-footer-wrapper .right-nav-menu a {
    color: #aaa;
  }
  
  .promotion-footer-wrapper .left-nav-menu a {
    margin-bottom: 0;
  }
  .promotion-section-4 .promotion-product-viewmore
    {
        display: none;
    }

.promo-pocket{
	background-color: #2A2A29;
} 
.promo-pocket .header1{
	z-index: 0!important;
}
.promo-pocket .promotion-header-wrapper{
	background-color: #009fe3;
}
.promo-pocket .buybutton button{
	background-color: #fff;
}
.promo-pocket .promotion-product{color:#fff}
.promo-pocket .nrml-text{color:#fff!important}
.promo-pocket .content-cta{    
	display: flex;
    width: 100%;
    justify-content: center;
	margin: 30px 0px;
}
.promo-pocket .cta{
	width: 370px;
    height: 48px;
    background-color: #BF2C1C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.promo-pocket .cta a{	
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
}
.promo-pocket .content-libraries{
	padding: 50px;
	display: flex;
	justify-content: space-around;	
	min-height: 600px;
	margin-bottom: 30px;
}
.promo-pocket .wrapper-libraries{
	text-align: left;
	width: 40%;
}
.promo-pocket .libraries-list{
	padding: 0;
	color: #fff;
}
.promo-pocket .libraries-list .ant-list-items div{color:#fff}
.promo-pocket .libraries-list .ant-list-items h4{color:#fff}
.promo-pocket .libraries-list .ant-list-items li{color:#fff}
.promo-pocket .libraries-list .ant-list-items li{	
	border-bottom: 1px solid;
	text-align: left;
	padding-bottom: 5px;
	margin: 30px 0;
}
.promo-pocket .buscador-librerias{
	color: #fff;
}
.promo-pocket .localiza-title{
	font-size: 30px;
}
.promo-pocket .localiza-search-input{
	width: 400px;
}
.promo-pocket .ant-btn-icon-only > * {
    font-size: 23px!important;
}
.promo-pocket .wrapper-maps-libraries{
	width: 50%;
}
.promo-pocket .promotion-header-wrapper .right-top-nav li{
	color:white;
}
.promo-pocket .promotion-newsletter-section{
	background-color:inherit!important
}
.promo-pocket button {border:none}
.promo-pocket .active-dot{
	background-color: #BF2C1C;
}
.promo-pocket .promotion-newsletter-section{
	border-bottom: 2px solid #BF2C1C
}
.promo-pocket .promotion-newsletter-section .subscribe-btn{
	background-color: #BF2C1C
}
.promo-pocket .promotion-newsletter-section h4{
	color:#BF2C1C
}
.promo-pocket .promotion-newsletter-section{ padding:0}
.promo-pocket .mb-50 {
    margin-bottom: 20px;
}
.promo-pocket .creditoDC{
	color: #fff;
	margin: 40px
}