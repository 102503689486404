.promo-batman-2024 .header1.mb-50{
	text-align: center;
}
.promo-batman-2024 .header1{
	height: auto;
}
.promo-batman-2024 .main-container{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.promo-batman-2024 .text-center{
	text-align: center !important;
	width: 70%;
}
.promo-batman-2024 .logo-promo img {
    width: 225px;
}
.promo-batman-2024 {
	background-color: #000000;
	color:#fff
} 
.promo-batman-2024 .promotion-section-2 h2{
	color: #fff
}
.promo-batman-2024 .header1{
	z-index: 0!important;
}
.promo-batman-2024 .promotion-header-wrapper{
	background-color: #000000;
}
.promo-batman-2024 .buybutton button{
	background-color: #fff;
}
.promo-batman-2024 .promotion-product{color:#fff}
.promo-batman-2024 .nrml-text{color:#fff!important}
.promo-batman-2024 .content-cta{  
	display: flex;   
	margin: 40px 0px;
	justify-content: center;
}
.promo-batman-2024 .cta{
	width: 465px;
	height: 48px;
	background-color: #BF2C1C;
	display: flex;
	justify-content: center;
	align-items: center;
}
.promo-batman-2024 .cta a{	
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
}
.promo-batman-2024 .content-libraries{
	padding: 50px;
	display: flex;
	justify-content: space-around;	
	min-height: 600px;
	margin-bottom: 30px;
}
.promo-batman-2024 .wrapper-libraries{
	text-align: left;
	width: 40%;
}
.promo-batman-2024 .libraries-list{
	padding: 0;
	color: #fff;
}
.promo-batman-2024 .libraries-list .ant-list-items div{color:#fff}
.promo-batman-2024 .libraries-list .ant-list-items h4{color:#fff}
.promo-batman-2024 .libraries-list .ant-list-items li{color:#fff}
.promo-batman-2024 .libraries-list .ant-list-items li{	
	border-bottom: 1px solid;
	text-align: left;
	padding-bottom: 5px;
	margin: 30px 0;
}
.promo-batman-2024 .buscador-librerias{
	color: #fff;
}
.promo-batman-2024 .localiza-title{
	font-size: 30px;
}
.promo-batman-2024 .localiza-search-input{
	width: 400px;
}
.promo-batman-2024 .ant-btn-icon-only > * {
  	font-size: 23px!important;
}
.promo-batman-2024 .wrapper-maps-libraries{
	width: 50%;
	position: relative;
}
.promo-batman-2024 .promotion-header-wrapper .right-top-nav li{
	color:white;
}
.promo-batman-2024 .promotion-newsletter-section{
	background-color:inherit!important
}
.promo-batman-2024 button {border:none}
.promo-batman-2024 .active-dot{
	background-color: #f2ec00;
}
.promo-batman-2024 .promotion-newsletter-section{
	border-bottom: 2px solid #BF2C1C
}
.promo-batman-2024 .promotion-newsletter-section .subscribe-btn{
	background-color: #BF2C1C
}
.promo-batman-2024 .promotion-newsletter-section h4{
	color:#BF2C1C
}
.promo-batman-2024 .promotion-newsletter-section{ padding:0;border-top: none;}
.promo-batman-2024 .mb-50 {
  	margin-bottom: 20px;
}
.promo-batman-2024 .creditoDC{
	color: #fff;
	margin: 40px
}
.promo-batman-2024 .promotion-product.col-2 {
    flex: 0 0 32%;
    box-sizing: border-box;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: 30px;
}
.promo-batman-2024 .promotion-section-4 .img-wrapper{
	height: 638px;
}

.promo-batman-2024 .promotion-section-4 .row {    
    gap: initial;
}
.product-text-center{
	text-align: center;
}
.promo-batman-2024 .infoWindow p{color:#000000}
.promo-batman-2024 .videoPromo{width: 70%;}
.promo-batman-2024 .promotion-header-wrapper{
	padding: 10px 20px 10px 20px;
}
.promo-batman-2024 .yellowLayer{
	background-color: #f2ec00;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	z-index: 7;
	pointer-events: none;
}