@media only screen and (min-width: 1920px) {
	.product .cont-rht .col-2 .img-wrapper img {
		position: relative!important;
	}
	.product .cont-rht .col-2 .img-wrapper span {
		position: relative!important;
	}
	
  .align-tab.sellos>li>a {
    font-size: 20px;
  }

  .product .btn2.disabled,
  .product .btn2.reservar.disabled {
    width: 160px
  }

  .catalogo li:nth-child(odd) .wrapper {
    padding-right: 10px;
  }

  .showsearcheddiv.showsearcheddiv.fixwidth {
    width: 362px;
  }

  .col_wrap[aria-expanded="true"] {
    border: 4px solid #0282f9;
  }

  .ayuda .col_wrap[aria-expanded="true"] a {
    color: #0282f9;
  }

  .ant-btn-primary.btn2_cont.danger {
    border: 4px solid #0282f9 !important;
    color: #0282f9 !important;
  }

  .ant-btn-primary.btn2_cont.xxl {
    width: 182px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px !important;
  }

  .form input[type="text"],
  .form input[type="password"] {
    height: 42px;
  }

  .row.productrow {
    margin-left: 0;
  }

  .publishline.cont-wrap .row .col-2 p {
    margin: 0 auto;
  }

  .publishline .row {
    margin: 0;
    margin-right: -10px;
  }

  .image_slider_homeproducts .img-wrapper {
    height: calc(calc(100vw * 0.121) * 1.547)
  }

  .image_slider_homeproducts {
    margin-top: 20px;
  }

  .revista .col-sm-8 {
    padding-left: 85px
  }

  .homemagzinesec .image-block {
    width: 421px;
  }

  .homemagzinesec .magzine-desc {
    height: 221px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }

  .editorial_wrap .over-img.artey {
    top: calc(50% - 25px);
  }

  .product .cont-rht {
    margin-left: 15px;
  }

  .product .ant-pagination.pagination {
    margin-left: 35px;
  }

  .image_slider_longnews .slick-next {
    top: 118px;
    right: 0px;
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active.slick-current p {
    margin-left: 15px;
  }

  .actualidadproduct .image_slider_homeproducts .img-wrapper {
    height: 305px;
    width: 198px;
  }

  .image_slider_longnews .noticias_1 .img-wrapper {
    height: 232px;
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active {
    padding-left: 10px;
  }

  .actual-sec-1 .row {
    justify-content: space-between;
  }

  .innersec_images1 {
    margin: 40px auto 0;
  }

  .homepagecontestsec .dark-night img,
  .homepagecontestsec .tshirtdiv img {
    width: 100%;
    max-width: 100%;
  }

  .respos h5 {
    color: white;
    display: flex;
  }

  .hr {
    display: none;
  }

  .respos {
    margin-top: -150px;
    bottom: 0px;
  }

  .homepagelastsec {
    height: calc(100vw * 0.336);
  }

  .homepagelastsec>img {
    z-index: -1;
    object-fit: cover;
    height: calc(100vw * 0.336);
    width: 100%;
    position: absolute;
  }

  .container-fluid.header_wrap {
    max-width: 1900px !important;
    width: 1900px !important;
    padding: 0 100px !important;
  }

  #__next {
    max-width: 100%;
    width: 100%;
  }

  .header1 {
    height: calc(100vw * 0.56);
  }

  .header_wrap .container {
    width: 95%;
    max-width: 95%;
  }

  .heading-container,
  .main-container {
    width: 1900px !important;
    max-width: 1900px !important;
  }

  .container.main-container.myspace-container {
    padding-left: 233px !important;
    padding-right: 233px !important;
  }

  .container.main-container,
  .container.heading-container,
  .jumbotron {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .container.main-container.search-outer-container {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .jumbotron.bgheadung {
    top: 38%;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 1900px !important;
    max-width: 1900px !important;
  }

  .container.main-container.ayuda {
    width: 1450px !important;
    max-width: 1450px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .line-container {
    width: 1900px !important;
    max-width: 1900px !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .ayuda .col-3 {
    flex: 0 0 32%;
    max-width: 32%;
  }

  .ayuda .col-9 {
    flex: 0 0 68%;
    max-width: 68%;
  }

  .ayuda .line4 {
    width: 28%;
  }

  .ayuda .subheading {
    margin-bottom: 40px;
  }

  .ayuda .card-body1 {
    margin-bottom: 80px;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .nav-inner {
    width: 90%;
  }

  .logo {
    width: 120px;
    height: 47px;
  }

  .DC-death-metal-prime {
    /*margin: 160px 144px 0px 0px;*/
    width: 900px;
    font-size: 120px;
    line-height: 122px;
  }

  .A-la-venta-esta-sema,
  .homepagelastsec h2,
  .communicadostitle {
    font-size: 60px;
  }

  .image_slider_homeevents .col-3 {
    padding-right: 0;
    padding-left: 0;
  }

  .image_slider_homeproducts .slick-slide>div>div>img {
    /*height: 362px;*/
    width: 100%;
  }

  .homepage_news_slider_wrapper {
    margin-bottom: 55px;
  }

  .mb-50.banners {
    margin-bottom: 80px;
  }

  .novedades {
    font-size: 14px;
  }

  .marg h3 {
    font-size: 40px;
  }

  .marg p,
  .homepagelastsec p {
    font-size: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .noticias h1,
  .homemagzinesec h2 {
    font-size: 40px;
  }

  .homemagzinesec p,
  .form2 p {
    font-size: 18px;
  }

  .image_slider_homeevents .connoticias1 p {
    line-height: 26px;
    font-size: 18px;
    margin: 20px 0 37px;
  }

  .form2 label {
    font-size: 15px;
  }

  .catalogo li {
    height: 292px;
  }

  .image_slider_homereleases img {
    height: 100%;
  }

  .catalogo a {
    font-size: 37px;
  }

  .catalogo label {
    width: 100%;
    margin-left: 25px;
  }

  .col-sm-8.padmar {
    padding-right: 99px;
  }

  .tshirtdiv .content h1 {
    font-size: 60px;
  }

  .tshirtdiv .content p {
    font-size: 24px;
  }

  .tshirtdiv .content {
    padding: 150px 20px 0 20px;
  }


  .pos-slider h2 {
    font-size: 60px;
    top: calc(100vw * 0.111);
  }

  .image_slider_home_letura .slick-prev,
  .image_slider_home_letura .slick-next {
    top: calc(100vw * 0.130);
  }

  .image_slider_homereleases .slick-prev,
  .image_slider_homereleases .slick-next {
    top: 150px;
  }

  .noticias img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .image_slider_homenews {
    margin-top: 30px;
  }

  .image_slider_home_letura.mb-50 {
    margin-bottom: 80px;
  }

  .image_slider_releases .slick-slide {
    height: 380px;
  }

  .agenda .res {
    width: 257px;
    height: 190px;
  }

  .agenda .res img {
    width: 100%;
    height: 100%;
  }

  .homepage_events_sec .slick-prev {
    left: 90%;
  }

  .event-wrapper .event-title,
  .connoticias1 .event-title {
    font-size: 18px;
    line-height: 26px;
    margin-top: 18px;
    height: 52px;
  }

  .event-wrapper .event-text,
  .connoticias1 .event-text {
    font-size: 18px;
    line-height: 26px;
    height: 50px;
  }

  .cont-w .event-wrapper.col-2 {
    flex: 0 0 16.66%;
    max-width: 245.5px;
    margin-bottom: 60px;
    width: 242px;
    padding-right: 6px;
  }

  .event-wrapper.col-2:nth-child(7n) {
    padding-right: 0px;
  }

  .event-wrapper.col-2:nth-child(6n) {
    padding-right: 6px;
  }

  .actualidad_longnews_heading {
    font-size: 60px;
    min-height: 80px;
  }

  .actualidad_longnews_ellipsis {
    font-size: 18px;
    -webkit-line-clamp: 1;
    height: 30px;
  }

  .actualidad_shortnews_heading {
    min-height: 27px;
  }

  .mt-60.actual-sec-1 {
    margin-top: 70px;
  }

  .actual-sec-1 .col-7 {
    flex: 0 0 73% !important;
    max-width: 73% !important;
  }

  .actual-sec-1 .col-5 {
    flex: 0 0 27% !important;
    max-width: 27% !important;
  }

  .actualidad_wrap_rht .heaact_rht {
    width: 100%;
  }

  .actualidad_wrap_rht .row {
    margin-bottom: 30px;
  }

  .image_slider_actualidadevents .res1 {
    height: 178px;
    width: 100%;
  }

  .image_slider_actualidadvideo .img_Wrap {
    height: 388px;
  }

  .image_slider_actualidadevents .connoticias1 {
    margin-top: 0;
  }

  .actualidad_video_heading {
    font-size: 40px;
    height: 95px;
  }

  .image_slider_actualidadevents .actualidad_shortnewscarr_ellipsis p,
  .actualidad_video_ellipsis,
  .image_slider_actualidadevents .connoticias1 h1 {
    font-size: 18px;
  }

  .image_slider_actualidadevents .connoticias1 h1 {
    margin: 18px 0 0px;
  }

  .actualidad_video_ellipsis {
    height: 60px;
  }

  .image_slider_actualidadevents .actualidad_shortnewscarr_ellipsis p {
    min-height: 42px;
    line-height: 26px;
    margin-bottom: 60px;
  }

  .schedule {
    margin-bottom: 20px;
  }

  .image_slider_actualidadevents .slick-track {
    padding: 0;
  }

  .image_slider_actualidadevents .slick-slide.slick-active.slick-current,
  .image_slider_actualidadevents .slick-slide,
  .image_slider_actualidadevents .slick-slide.slick-cloned {
    margin-left: 0;
  }

  .image_slider_actualidadevents .slick-slide.slick-active {
    margin-left: 7px;
    margin-right: 0px;
  }

  .actualidad_content_list_ellipsis {
    -webkit-line-clamp: 2;
    max-height: 55px;
  }

  .actualidad_content_list_heading {
    font-size: 18px;
  }

  .schedule .col-6:first-child {
    flex: 0 0 59% !important;
    max-width: 59% !important;
    padding-right: 50px;
  }

  .image_slider_actualidadevents .slick-next {
    right: 20px;
    top: 30px;
  }

  .image_slider_actualidadevents .slick-prev {
    right: 60px;
    top: 30px;
    left: unset;
  }

  .schedule .col-6:nth-child(2) {
    flex: 0 0 41% !important;
    max-width: 41% !important;
  }

  .cont-rht-11 .bibli-img {
    height: 305px;
    width: 198px;
  }

  .magzine .listrht .col-6:first-child {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .magzine .col-6.wrapper {
    padding-left: 0;
  }

  .magzine .listrht .col-6:nth-child(2) {
    flex: 0 0 55%;
    max-width: 55%;
  }

  .container.footer_sec {
    padding-left: 100px !important;
    padding-right: 100px !important;
    width: 1900px !important;
    max-width: 1900px !important;
  }

  .container.cont-rht-11 {
    margin-top: 50px;
  }

  .imglft {
    width: 381px;
    height: 536px;
  }

  .listrht {
    float: unset;
    width: auto;
    margin-left: 30px;
  }

  .image_slider_longnews {
    margin-bottom: 60px;
  }

  .magzine {
    margin-bottom: 60px;
    margin-top: 40px;
  }

  .image_slider_homeproducts p {
    margin-top: 19px;
  }

  .magzine .listrht .row {
    margin-bottom: 40px;
  }

  .date {
    width: 70px;
    height: 70px;
  }

  .subs-email input {
    height: 42px;
    width: 300px;
  }

  .contacts {
    margin: 40px 0 0 0;
  }

  .date h5 {
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 2px;
  }

  .connoticias1 .date p {
    font-size: 28px;
    margin-top: 0px;
  }

  .image_slider_homeevents .connoticias1 .date {
    width: 70px;
    height: 70px;
  }

  .image_slider_homeevents .connoticias1 .date h5 {
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 2px;
  }

  .image_slider_homeevents .connoticias1 .connoticias1 .date p {
    font-size: 28px;
    margin-top: 0px;
  }

  .connoticias1 {
    height: 270px;
  }

  .search-container,
  .search-outer-container {
    width: 1660px !important;
    max-width: 1660px !important;
  }

  .search-container .right-bar h2 {
    font-size: 15px;
  }

  .search-container .right-bar img {
    width: 183px;
    height: 282px;
  }

  .right-bar .btn2 {
    font-size: 20px;
    width: 120px;
  }

  .connoticias1 p {
    width: 90%;
  }

  .image_slider_homeevents .connoticias1 .date p {
    width: 100%;
    margin-top: 5px;
    font-size: 26px;
  }

  .img-w {
    height: 178px;
  }

  .cont-w p {
    font-size: 18px;
  }

  .date-w p {
    font-size: 28px !important;
  }

  .date-w h6 {
    font-size: 12px !important;
  }

  .date-w {
    width: 70px;
    height: 70px;
  }

  .eventModal {
    width: 1543px !important;
  }

  .eventModal .ant-modal-content {
    overflow-y: scroll;
  }

  .eventModal .col-5 .eventImg {
    width: 482px;
    height: 649px;
  }

  .eventModal .eventDate {
    width: 417px;
    height: 104px;
  }

  .eventModal .eventDate h5,
  .eventModal .eventDate p {
    font-size: 24px;
  }

  .eventModal .eventDate h5 {
    font-weight: bold;
  }

  .eventModal .eventTitle {
    font-size: 60px;
    line-height: 60px;
  }

  .navigation-center {
    margin-top: 115px;
    margin-left: 60px;
  }

  .drowdown2 label {
    margin-bottom: 10px;
  }

  .serch-right {
    margin-top: 108px;
  }

  .agenda .cont-w {
    margin-top: 60px;
  }

  .agenda .A-la-venta-esta-sema {
    margin: 0;
  }

  .heading-left {
    margin-top: 104px;
  }

  .serch-right select {
    height: 42px;
    width: 184px;
  }

  .eventModal .eventDetail {
    font-size: 19px;
    margin-top: 59px;
    margin-bottom: 150px;
  }

  .cta .col-4 {
    max-width: 255px;
    width: 255px;
    margin-right: 20px;
    padding-right: 0;
    padding-left: 0;
  }

  .eventModal .ant-modal-close-x {
    display: none;
  }

  .eventModal .eventCTA {
    font-size: 20px;
    border: 4px solid #000;
  }

  .image_slider_releases p {
    font-size: 40px;
  }

  .image_slider_releases h5 {
    font-size: 18px !important;
  }

  .image_slider_releases.homereleases .slick-prev,
  .image_slider_releases.homereleases .slick-next {
    top: 145px;
  }

  .subheading {
    font-size: 22px;
    margin: 40px 0 10px 0;
    line-height: 34px;
  }

  .historia.links {
    margin-top: 50px;
    margin-bottom: 102px;
  }

  .historia.links li:first-child {
    width: 227px;
  }

  .historia.links li:nth-child(2) {
    width: 227px;
  }

  .historia.links li:last-child {
    width: 227px;
    margin-right: 0;
  }

  .distribucion .links li {
    width: 250px;
    padding: 15px 20px;
  }

  .links.nomargin {
    margin: 20px 0 40px;
  }

  .innersec_images1 {
    margin: 40px auto 0;
  }

  .ayuda .accordion {
    margin-bottom: 60px;
    margin-top: -43px;
  }

  .ayuda .col_wrap {
    position: relative;
    width: 1000px;
    height: 52px;
    padding: 7px 22px;
  }

  .innersec_images1 ul li {
    margin: 0 0 25px;
  }

  .static .form input[type="text"] {
    margin: 0 0 20px 0;
  }

  .mb-80 {
    margin-bottom: 100px;
  }

  .catTitle p {
    margin-bottom: 43px;
    margin-top: 34px;
  }

  .publishline.cont-wrap {
    margin-bottom: 100px;
  }

  .catalogo.mb-50 {
    margin-bottom: 40px;
  }

  .innerpage p {
    font-size: 19px;
    line-height: 26px;
  }

  .contacts p {
    font-size: 15px;
  }

  .cont-rht-2 li {
    width: 19%;
  }

  .innersec_images li p {
    margin-bottom: 30px;
  }

  .btn2 {
    font-size: 20px;
  }

  .footer_sec .btn2 {
    width: 141px;
    height: 52px;
  }

  .social1 .nav-tabs.icons li {
    width: 39px;
    height: 39px;
    padding: 5px 10px;
  }

  .social1 .nav-tabs.icons li i {
    font-size: 17px;
  }

  .innersec_images3 {
    margin: 50px auto 0;
  }

  .innersec_images1 li p {
    margin-bottom: 15px;
  }

  .cont-rht-2 li:last-child {
    margin: 40px 0px 0 10px;
  }

  /* .favoritos .cont-rht-2 .col-2:last-child {
    margin: 0px 0px 0 10px;
  } */
  .favoritos .cont-rht-2 h2 {
    min-height: 42px;
  }

  .navi2 ul li {
    width: 362px;
    height: 52px;
  }

  .editorial_wrap ul li p {
    width: 80%;
    font-size: 18px;
  }

  .editorial_wrap .line4 {
    width: 25%;
  }

  .editorial_wrap h3 {
    margin: 40px 0 11px 0;
  }

  .footer_sec .form2 p {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .container.main-container.static {
    max-width: 1200px !important;
    width: 1200px !important;
  }

  .rights .inner-wrap .col-2 {
    flex: 0 0 14.2%;
    margin-bottom: 50px;
    max-width: 14.2%;
  }

  .rights .inner-wrap .col-2:nth-child(7n) {
    padding-right: 20px;
  }

  .rights .inner-wrap .col-2:nth-child(8n) {
    padding-right: 0px;
  }

  .rights .inner-wrap .col-2 img {
    width: 100%;
  }

  .rights .ant-pagination.pagination {
    margin-bottom: 60px;
  }

  .rights .inner-wrap .col-2 .img-wrapper,
  .bibli .inner-wrap .col-2 .img-wrapper {
    height: 305px;
    width: 198px;
    overflow: hidden;
  }

  .bibli .inner-wrap .col-2 {
    width: 215px;
    padding-right: 20px;
    margin-bottom: 35px;
  }

  .bibli .inner-wrap .col-2:nth-child(7n) {
    padding-right: 20px;
  }

  .bibli .ant-pagination {
    margin-top: 25px;
    margin-bottom: 60px;
  }

  .bibli .inner-wrap .col-2:nth-child(8n) {
    padding-right: 0;
  }

  .radio1edcion h4 {
    font-size: 14px
  }

  .product .cont-rht .col-2 {
    margin: 0 0 60px 0px;
  }

  .product .cont-rht .col-2:nth-child(5n) {
    padding-right: 20px;
  }

  .product .btn2 {
    width: 119px;
    border: 4px solid #000;
  }

  .product .cont-rht .col-2:nth-child(6n) {
    /*padding-right: 0px;*/
  }

  .product .cont-rht .col-2 .img-wrapper {
    height: 305px;
    overflow: hidden;
    width: 100%;
	display: flex;
	align-items: flex-end;
  }

  .product.content .cont-rht .col-2 .img-wrapper {
    height: 133px;
    overflow: hidden;
    width: 100%;
  }

  .mt-60 {
    margin-top: 80px;
  }

  .rhtcontent {
    width: 20%;
  }

  .lftcontent {
    width: 80%;
  }

  .bgcover {
    width: 450px;
  }

  .revista {
    height: auto;
    padding-bottom: 80px;
  }

  .cont-w .col-2 {
    flex: 0 0 14.5%;
    max-width: 245px;
    width: 250px;
  }

  .cont-w .col-2:last-child {
    padding-right: 0;
  }

  .color-div p {
    font-size: 14px;
  }

  .color-div p,
  .color-div h2,
  .color-div h3 {
    font-size: 14px;
  }

  .delivery-accordion {
    font-size: 14px;
  }

  .note.notebtn a {
    font-size: 20px;
  }

  hr.line7 {
    border: 2px solid #aaa;
    width: 190px;
  }

  .form-control-2 {
    width: 233px;
  }

  .coupon-desc {
    left: 290px;
  }

  .coupon-desc p {
    font-size: 12px;
  }

  .blue-triangle {
    right: 22px;
  }

  .nav-2-wrapper li {
    width: 242px;
  }

  .table-right {
    width: 350px;
  }

  .address-btn {
    float: none;
  }

  .cart-step .rht-cont {
    width: 25%;
  }

  .cart-step .lft-cont {
    width: 70%;
  }

  .mar-wrap-lft {
    width: 95% !important;
  }

  .address-btn {
    width: 259px;
    height: 52px;
    border: 4px solid #0282f9 !important;
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .address-btn.bill-add {
    width: 259px;
    height: 52px;
    border: 4px solid #0282f9 !important;
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .postal-sel-add {
    float: none;
    margin-top: 30px;
  }

  .btn-side a {
    width: 346px;
    text-align: center;
    height: 52px;
  }

  .table-form-3 td:nth-child(2) {
    width: 440px;
  }

  .white-header-row {
    left: 0;
  }

  .search-outer-container .main-row .col-3 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .search-outer-container .main-row .col-9 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .pdf-btn {
    font-size: 20px;
    padding: 5px 25px;
  }

  .communicados .innerpage-2 .subheading p {
    font-size: 24px;
    line-height: 32px;
  }

  .communicados .innerpage-2 .subheading p {
    font-size: 19px;
    line-height: 26px;
  }

  .homereleases .connoticias p {
    font-size: 40px;
    line-height: 50px;
  }

  .homereleases .connoticias h5 {
    font-size: 15px;
  }

  .image_slider_homereleases .img-wrapper {
    height: 295px;
  }

  .productos .slider-for-2 .slick-slide {
    height: 235px;
  }

  .productos .slider-for-2 {
    width: 100%;
  }

  .price button {
    width: 241px;
    height: 52px;
    margin-bottom: 90px;
  }

  .price h4 {
    font-size: 42px;
    margin-bottom: 20px;
  }

  .prod_imgs {
    height: 270px;
  }

  .btn2_cont.login {
    width: 157px;
  }

  .btn2_cont.register {
    width: 262px;
  }

  .btn2_cont.espacio {
    width: 150px;
  }

  .btn2_cont.luego {
    width: 180px;
  }

  .goo-face-rht .facebook,
  .goo-face-rht .google,
  .goo_face_wrap .facebook,
  .goo_face_wrap .google {
    width: 125px;
    height: 52px;
    padding: 11px 0;
  }

  .btn2_cont {
    font-size: 20px !important;
  }

  .btn2.cont.registerbtn {
    width: 157px;
  }

  .mer-filters-card h6 {
    font-size: 18px;
  }

  .homepage_contest_content {
    width: 470px;
    top: 32%;
  }

  .homepage_contest_content h3 {
    font-size: 60px;
  }

  .bannerText p {
    font-size: 24px
  }

  .bannerText h3 {
    font-size: 60px;
  }

  .homepage_contest_content p {
    font-size: 24px;
  }

  .line6.line-footer {
    padding: 31px 0 38px;
  }

  .socialico5 {
    margin-left: 15px;
    font-size: 21px !important;
  }

  .tiendas .cover.mt-60 {
    margin-top: 80px;
  }

  .tiendas .btn1 {
    font-size: 20px;
    width: 141px;
    border: 4px solid #000;
  }

  .tiendas .btn1:first-child {
    margin-top: 40px;
  }

  .arrow3 {
    top: 285px;
  }

  .arrow3.alicante {
    top: 883px;
  }

  .arrow3.arenas {
    top: 1455px;
  }

  .webs .editorial_wrap .A-la-venta-esta-sema,
  .webs .editorial_wrap .line4 {
    width: 422px;
  }

  .contacto .navigation {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .form.mt-30 {
    margin-top: 45px;
  }

  .btn2.contacto {
    width: 141px;
    border: 4px solid #000;
    margin-bottom: 60px;
    height: 52px;
    padding: 8px 10px;
  }

  .dropdown.personMenuDesktop .dropdown-menu {
    left: -143px !important;
  }

  .dropdown.personMenuDesktop .search-triangle-black {
    right: 50px;
  }

  .myspace .heading_lft_cont,
  .myspace .heading_rht_cont {
    margin-top: 30px;
  }

  .table-form tr td:last-child {
    width: 110px;
    padding: 0 0 0 35px;
  }

  .myspace .heading_rht_cont {
    width: 71%;
  }

  .favoritos .link_cont {
    margin-top: 0;
  }

  .btn2 {
    width: 119px;
    border: 4px solid #000;
  }

  .myspace .heading_lft_cont {
    margin-right: 90px;
  }

  .orderdetailimg {
    width: 74px;
    height: 109px;
  }

  .table-form-order tr td:first-child {
    width: 7%;
  }

  .table-form-order p {
    font-size: 15px;
    line-height: 21px;
  }

  .ant-btn-primary.btn2_cont {
    width: 141px;
    height: 52px;
    border: 4px solid #000 !important;
    margin-bottom: 60px;
  }

  .libreros-container {
    margin-top: 211px;
    margin-bottom: 366px;
  }

  .btn2_cont.libreros {
    width: 163px;
    padding: 7px 10px !important;
    height: 52px;
    border: 4px solid #000 !important;
  }

  .innersec_images4 {
    margin: 40px auto 0px;
  }

  .checkbox-list .custom_checkbox_wrapper {
    margin-bottom: 20px;
  }

  .checkbox-list .checkmark {
    top: 3px;
  }

  .note {
    margin-bottom: 10px;
  }

  .step4-2 {
    margin: 10px 0;
  }

  .btn2_cont_btn {
    width: 274px;
    height: 52px;
    font-size: 20px;
  }

  button.btn2_cont_btn.active {
    width: 274px;
    height: 52px;
    font-size: 16px;
  }

  .cont_form li h3 {
    margin-bottom: 20px;
  }

  .btn2_cont.add {
    width: 300px;
    height: 52px;
  }

  .cont_form li p {
    font-size: 19px;
    line-height: 26px;
  }

  .table-form tr td a {
    width: 107px;
  }

  .blue-wrap p {
    font-size: 18px;
  }

  .btn2_cont.detail {
    width: 257px;
    height: 52px;
    margin-bottom: 60px;
  }

  .quan_arrow_back {
    left: 20px;
    top: 46px;
  }

  .quan_arrow_frwd {
    right: 20px;
    top: 46px;
  }

  .resultados .navi2 ul li,
  .resultados .searched-div {
    width: 360px;
  }

  .resultados .searched-div {
    height: 63px;
    font-size: 18px;
    padding: 18px;
  }

  .resultados .producttitlerow .drowdown {
    margin-top: 7px;
  }

  .table-form-3 tr td.img-wrapper img {
    height: 109px;
    width: 72px;
  }

  .align-tab.sellos>li>a {
    padding: 10px 25px !important;
  }

  .image_slider_homenews .slick-prev,
  .image_slider_homenews .slick-next {
    top: calc(100vw * 0.070);
  }

  .image_slider_homenews .releases .slick-prev,
  .image_slider_homenews .releases .slick-next {
    top: calc(100vw * 0.10);
  }

  .ediciones-products .slider-content {
    padding: 0 135px;
  }

  .image_slider_ediciones_content .img-wrapper {
    height: 325px;

  }

  .image_slider_ediciones_content .card-wrapper {
    padding-left: 20px;
  }

  .ediciones-products .slick-slider {
    position: unset;
  }

  .ediciones-products .slick-prev {
    left: unset;
    top: 66px;
    right: 75px;
  }

  .ediciones-products .slick-next {
    right: 30px;
    top: 66px;
  }

  .ediciones-products {
    height: 713px;
    padding-top: 40px;
  }

  .relacionados-products .slick-list {
    margin-left: -20px;
  }

  .ediciones-products .line5,
  .relacionados-products .line5 {
    margin-bottom: 40px;
  }

  .product_icon_wrapper .product_text {
    font-size: 15px;
  }

  .product-details-options .navi3 li {
    padding: 30px 30px;
  }

  .ediciones-products .title-header {
    padding: 0 40px;
  }

  .ediciones-products h2,
  .relacionados-products h2 {
    font-size: 40px;
  }

  .ediciones-products h2 {
    margin-bottom: 20px;
  }

  .relacionados-products h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width:1441px) and (max-width:1919px) {
	.product-details-options .navi3 li a{
		font-size: 17px;
	}
	.product-details-options .navi3 li.active a{
		font-size: 17px;
	}

  .event-wrapper .event-title,
  .connoticias1 .event-title {
    height: 46px;
  }

  .image_slider_actualidadevents .res1 {
    height: 150px;
  }

  .image_slider_homenews .releases .slick-prev,
  .image_slider_homenews .releases .slick-next {
    top: calc(100vw * 0.10);
  }

  .banners .bannerText {
    font-size: 40px;
    line-height: 42px;
  }

  .ckeditor ul li {
    font-size: 18px;
  }

  .ckeditor h2 {
    font-size: 42px;
  }

  .ckeditor h3 {
    font-size: 34px;
  }

  .ckeditor h4 {
    font-size: 22px;
  }

  .catalogo li:nth-child(odd) .wrapper {
    padding-right: 10px;
  }

  .showsearcheddiv.showsearcheddiv.fixwidth {
    width: 252px;
  }

  .col_wrap[aria-expanded="true"] {
    border: 4px solid #0282f9;
  }

  .ayuda .col_wrap[aria-expanded="true"] a {
    color: #0282f9;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px !important;
  }

  .form input[type="text"],
  .form input[type="password"] {
    height: 42px;
  }

  .publishline .row {
    margin: 0;
    margin-right: -10px;
  }

  .arrow3.arenas {
    top: 1142px;
  }

  .arrow3.alicante {
    top: 704px;
  }

  .row.productrow {
    margin-left: 0;
  }

  .img-w {
    height: 154px;
  }

  .quan_arrow_frwd,
  .quan_arrow_back {
    top: 52px;
  }

  .table-form-3 tr td:first-child {
    height: 130px;
  }

  .agenda .res {
    width: 200px;
    height: 150px;
    padding-right: 10px;
  }

  .image_slider_homeproducts .img-wrapper {
    height: calc(calc(100vw * 0.120) * 1.545);
	display: flex;
    align-items: center;
  }

  .revista .col-sm-8 {
    padding-left: 65px
  }

  /* .homemagzinesec .text-block{
    -webkit-line-clamp: 3;
    height: 170px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */
  .editorial_wrap .over-img.artey {
    top: calc(50% - 25px);
  }

  .product .cont-rht .col-2 {
    flex: 0 0 20%;
    max-width: 20%;
    cursor: pointer;
    padding-left: 0;
  }

  .product.biblioteca .cont-rht .col-2 {
    flex: 0 0 14.28%;
    max-width: 14.28%;
    cursor: pointer;
    padding-left: 0;
    padding-right: 15px;
  }

  .product .cont-rht .col-2 {
    margin: 0 0 60px 0px;
  }

  .product .cont-rht .col-2 .img-wrapper {
    height: 248px;
    overflow: hidden;
	display: flex;
	align-items: flex-end;
  }
  .product .cont-rht .col-2 .img-wrapper img {
    position: relative!important;
  }
  .product .cont-rht .col-2 .img-wrapper span {
    position: relative!important;
  }

  .product.content .cont-rht .col-2 .img-wrapper {
    height: 92px;
    overflow: hidden;
  }

  .product.biblioteca .cont-rht .col-2 .img-wrapper {
    height: 257px;
    overflow: hidden;
  }

  .cont-wrap-cover.product .cont-rht {
    margin-left: 85px
  }

  .product.biblioteca .cont-rht {
    margin-left: 0
  }

  .product.biblioteca .row {
    margin-left: 0
  }

  .image_slider_longnews .noticias_1 .img-wrapper {
    height: 232px
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active.slick-current p {
    margin-left: 13px
  }

  .actualidadproduct .image_slider_homeproducts .img-wrapper {
    height: 257px;
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active {
    padding-left: 20px;
  }

  .actual-sec-1 .row {
    justify-content: space-between;
  }

  .innersec_images1 {
    margin: 40px auto 0;
  }

  .homepagecontestsec .dark-night img,
  .homepagecontestsec .tshirtdiv img {
    width: 100%;
    max-width: 100%;
  }

  .homemagzinesec .magzine-desc {
    height: 147px;
    /* overflow: hidden; */
    /* height: 221px; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .hr {
    display: none;
  }

  .respos h5 {
    color: white;
    display: flex;
    /* margin: 0 110px 10px; */

  }

  .respos {
    margin-top: -150px;
    bottom: 0px;
  }

  .homepagelastsec {
    height: calc(100vw * 0.336);
    margin-top: 20px;
  }

  .image_slider_homeproducts {
    margin-top: 20px;
  }

  .homepagelastsec>img {
    z-index: -1;
    object-fit: cover;
    height: calc(100vw * 0.336);
    width: 100%;
    position: absolute;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1440px !important;
    width: 1440px !important;
  }

  .container-fluid.header_wrap {
    max-width: 1440px !important;
    width: 1440px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .container.main-container.static {
    max-width: 1000px !important;
    width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .line-container {
    max-width: 1440px !important;
    width: 1440px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .header1 {
    height: calc(100vw * 0.56);
  }

  .header1.EccHeader {
    height: calc(100vw * 0.33);
  }

  .jumbotron.bgheadung {
    top: 40%;
  }

  .image_slider_homeproducts .slick-prev,
  .image_slider_homeproducts .slick-next {
    top: calc(50% - 30px);
  }

  .container.main-container,
  .container.heading-container,
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .search-container {
    left: 8%;
  }

  .myspace .row {
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1440px) {
	.product-details-options .navi3 li a{
		font-size: 16px;
		padding: 0 20px;
	}
	.product-details-options .navi3 li.active a{
		font-size: 16px;
		
	}
	.product-details-options .navi3 li{
		padding: 25px 0px;
	}
	.product .cont-rht .col-2 .img-wrapper img {
		position: relative!important;
	}
	.product .cont-rht .col-2 .img-wrapper span {
		position: relative!important;
	}
  .event-wrapper .event-title,
  .connoticias1 .event-title {
    height: 46px;
  }

  .image_slider_actualidadevents .res1 {
    height: 150px;
  }

  .product.biblioteca .row {
    margin-left: 0
  }

  .mer-filters-card.col-xl-2 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .banners .bannerText {
    font-size: 25px;
    line-height: 27px;
  }

  .ckeditor ul li {
    font-size: 17px;
  }

  .showsearcheddiv.showsearcheddiv.fixwidth {
    width: 252px;
  }

  .col_wrap[aria-expanded="true"] {
    border: 4px solid #0282f9;
  }

  .ayuda .col_wrap[aria-expanded="true"] a {
    color: #0282f9;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px !important;
  }

  .form input[type="text"],
  .form input[type="password"] {
    height: 42px;
  }

  .cont-wrap.tab-pane.fade.show.active.publishline .row {
    margin-left: 0
  }

  .container.main-container.innerpage.tiendas {
    max-width: 1140px !important;
  }

  .arrow3.madrid {
    top: 188px;
  }

  .arrow3.arenas {
    top: 1003px;
  }

  .arrow3.alicante {
    top: 609px;
  }

  .row.productrow {
    margin-left: 0;
  }

  .eventModal .ant-modal-content {
    overflow-y: auto;
  }

  .table-form-3 tr td:first-child {
    height: 115px;
  }

  .billingAdressSelectedText {
    margin-left: 35px;
  }

  .quan_arrow_back {
    top: calc(48% - 8px);
  }

  .quan_arrow_frwd {
    top: calc(48% - 8px);
  }

  .homemagzinesec .magzine-desc {
    height: 221px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }

  .white-header-row .search-resp-input {
    width: 76%;
    height: 32px;
    margin-top: 10px;
    background: transparent;
    color: #000;
    border: 1px solid #000;
    margin-left: 15px;
  }

  .search-container .right-bar img {
    width: 100%;
    height: 100%;
  }

  .search-container .col-2 {
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .search-container {
    left: 76px;
    width: 100% !important;
  }

  .editorial_wrap .over-img.artey {
    top: calc(50% - 20px);
  }

  .product .cont-rht .col-2 .img-wrapper {

    overflow: hidden;
  }

  .product .cont-rht .col-2 .img-wrapper {
    width: 100%;
    height: calc(calc(100vw * 0.12) * 1.7);
  }

  .product.content .cont-rht .col-2 .img-wrapper {
    width: 100%;
    height: calc(calc(100vw * 0.24) * 0.45);

  }

  .product.biblioteca .cont-rht .col-2 .img-wrapper {
    width: 100%;
    height: calc(calc(100vw * 0.111) * 2.34);
  }

  .product .cont-rht .col-2 {
    flex: 0 0 20%;
    max-width: 20%;
    cursor: pointer;
    padding-left: 0;
  }

  .product.biblioteca .cont-rht .col-2 {
    flex: 0 0 20%;
    max-width: 20%;
    cursor: pointer;
    padding-left: 0;
  }

  .product .cont-rht .col-2 {
    margin: 0 0 60px 0px;
  }

  .image_slider_actualidadevents .slick-slide.slick-active.slick-current .connoticias1 {
    margin-left: 10px
  }

  .image_slider_longnews .noticias_1 img {
    width: 100%;
    height: 100%;
  }

  .image_slider_longnews .noticias_1 .img-wrapper {
    height: calc(calc(100vw * 0.287) * 0.678);
  }

  .actualidadproduct .image_slider_homeproducts .img-wrapper {
    height: calc(calc(100vw * 0.196) * 1.538);
  }

  .actualidadproduct .image_slider_homeproducts img {
    padding: 0
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active {
    padding-left: 10px
  }

  .image_slider_actualidadevents .slick-slide {
    padding-right: 0
  }

  .innersec_images1 {
    margin: 30px auto 0;
  }

  .image_slider_homeproducts {
    margin-top: 20px;
  }

  .respos h5 {
    color: white;
    display: flex;
    margin: 0 110px 10px;
  }

  .agenda .res {
    width: 100%;
    height: calc(calc(100vw * 0.194) * 0.744);
    padding-right: 10px;
  }

  .image_slider_homereleases img {
    height: 100%
  }

  .catalogo li:nth-child(odd) .wrapper {
    padding-right: 10px;
  }

  .catalogo li {
    height: 100%;
    padding: 0 0 0px 0px !important;
    /* background-position-y: -112px !important; */
  }

  .catalogo .eccPublishingLine .wrapper {
    height: calc(calc(100vw * 0.294) * 0.663);
  }

  .image_slider_homereleases .img-wrapper {
    width: 100%;
    height: calc(calc(100vw * 0.294) * 0.663);
  }

  .homepagelastsec>img {
    z-index: -1;
    object-fit: cover;
    height: calc(100vw * 0.527);
    width: 100%;
    position: absolute;
  }

  .homepagecontestsec .dark-night img,
  .homepagecontestsec .tshirtdiv img {
    width: 100%;
    max-width: 100%;
  }

  .respos {
    margin-top: -150px;
    bottom: 0px;
  }

  .homepagelastsec {
    height: calc(100vw * 0.527);
  }

  .hr {
    display: none;
  }

  .ant-modal.cookieModal {
    width: 1140px !important;
    max-width: 1140px !important;
  }

  .tabdataabove,
  .tabdatabelow {
    display: block;
  }

  .jumbotron.bgheadung {
    top: 50%;
  }

  .agenda .res img {
    width: 100%;
    height: 100%;
  }

  .image_slider_homeevents .col-3 {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }

  .imglft {
    width: 210px;
  }

  .listrht_img {
    width: 160px;
    height: 100px;
  }

  .DC-death-metal-prime {
    font-size: 43px;
    width: 600px;
    line-height: 48px;
  }

  .logo img {
    width: 100%;
  }

  .subs-email input {
    width: 300px;
    height: 42px;
  }

  .col_wrap:first-child {
    margin: 43px 0 0 0;
  }

  li.ecc {
    height: 150px;
    padding: 0 0 0px 50px;
    margin: 0px 2px 2px 0 !important;
  }

  .catalogo a {
    font-size: 30px;
  }

  .noticias h1 {
    font-size: 17px;
  }

  .noticias p {
    font-size: 12px;
  }

  .novedades {
    font-size: 10px;
    font-weight: 450;
  }

  .marg h3 {
    font-size: 17px;
  }

  .marg p {
    font-size: 12px;
    line-height: 15px;
    -webkit-line-clamp: 10;
  }

  .A-la-venta-esta-sema,
  .communicadostitle {
    font-size: 25px;
  }

  .box3 {
    height: auto;
  }

  .homepagecontestsec {
    margin: 20px 0 50px;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container.main-container,
  .container.heading-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
    width: 100% !important;
  }

  .container-fluid.header_wrap {
    padding-left: 40px !important;
    padding-right: 40px !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .container.main-container,
  .container.heading-container,
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  

  .socialico5 {
    margin-left: 10px;
  }

  .box5 {
    margin: 0 0 0 15px;
  }

  .search-container .img-wrapper {
    width: 100%;
    height: auto;
  }

  .search-container .main-row {
    padding: 30px !important;
  }

  .social2 {
    padding: 3px 00px;
  }

  .white-header-row {
    width: 550px;
  }

  .search-outer-container {
    left: 49%;
    transform: translateX(-47%);
  }

  .socialico5 {
    margin-left: 10px;
  }

  .tshirtdiv .content h1 {
    font-size: 25px;
  }

  .tshirtdiv .content p {
    font-size: 12px;
  }

  .homemagzinesec h2 {
    font-size: 17px;
  }

  .homemagzinesec p,
  .connoticias1 p {
    font-size: 12px;
    line-height: 15px;
  }

  .date {
    width: 43px;
    height: 43px;
    padding: 2px 5px 0px;
  }

  .date h5 {
    margin-bottom: 2px;
  }

  .date p {
    font-size: 20px;
  }

  .image_slider_homeevents .connoticias1 .date h5 {
    margin-bottom: 2px;
  }

  .image_slider_homeevents .connoticias1 .date p {
    font-size: 20px;
  }

  .image_slider_homeevents .connoticias1 .footer_sec .col-7 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .container.footer_sec {
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 70px;
  }

  .footer_sec .col-1 {
    display: none;
  }

  .footer_sec .col-2.first {
    display: none;
  }

  .homepagelastsec h2 {
    font-size: 43px;
    margin: 0 108px 10px;
  }

  .homepagelastsec h5 {
    font-size: 17px;
    margin: 0 0 20px;
  }

  .homepagelastsec p {
    font-size: 17px;
    margin: 0 108px 30px;
  }

  .respos .row {
    justify-content: center;
  }

  .container.main-container.static {
    max-width: 100% !important;
    width: 100% !important;
  }

  .innersec_images1 ul li img {
    width: 100%;
    height: 396px;
  }

  .innersec_images ul li img {
    width: 100%;
    height: 100%;
  }

  .innersec_images ul li {
    width: 50%;
  }

  .innerpage p {
    font-size: 17px;
    line-height: 23px;
  }

  .distribucion.links li {
    width: 200px;
  }

  .historia .subheading {
    margin: 20px 0 30px;
  }

  .historia .subheading br {
    display: block;
  }

  .my-tab-40 {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .historia.links li {
    padding: 5px 10px;
  }

  .historia.links {
    margin-bottom: 80px;
  }

  .align-tab.sellos>li>a {
    padding: 10px !important;
    font-size: 14px;
  }

  .innersec_images {
    margin: 20px auto 20px !important;
  }

  .my-tab-40 {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .historia.links li:first-child {
    width: 209px;
  }

  .historia.links li:nth-child(2) {
    width: 185px;
  }

  .historia.links li:last-child {
    width: 137px;
  }

  .historia.links li {
    padding: 5px 5px;
  }

  .historia.links {
    margin-bottom: 40px;
  }

  .innersec_images {
    margin: 40px auto 40px;
  }

  .innersec_images3 ul li img {
    width: 100%;
    height: 100%;
  }

  .innersec_images3 ul li {
    width: 33%;
  }

  .ayuda .innersec_images1 ul li img {
    width: 603px;
  }

  .subheading {
    font-size: 20px;
    line-height: 26px;
    margin: 20px 0 10px 0;
  }

  .homepagelastsec .container {
    margin: auto;
  }

  .pos-slider {
    margin-top: 25px !important;
    background-position-y: 0 !important;
  }

  .pos-slider h2 {
    font-size: 25px;
  }

  .pos-slider a {
    top: 43%;
  }

  .image_slider_home_letura .slick-prev,
  .image_slider_home_letura .slick-next {
    top: 50%;
  }

  .header1 {
    height: calc(100vw * 0.56);
  }

  .myspace .A-la-venta-esta-sema1 {
    font-size: 25px;
  }

  .myspace .cont_form .table-form {
    font-size: 12px;
  }

  .container-fluid.footerform {
    max-width: 100% !important;
    width: 100% !important;
  }

  .form2 p {
    font-size: 12px;
  }

  .form2 label {
    font-size: 15px;
  }

  .footer_sec button {
    font-size: 14px;
  }

  .heading-cont-left {
    width: 70%;
  }

  .mleft-2 {
    margin-left: 0 !important;
  }

  .cont-rht-2 li {
    width: 147px;
  }

  .cont-rht-2 li img {
    height: 200px;
  }

  .actual-sec-1 .col-7,
  .actual-sec-1 .col-5 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    padding-right: 0px;
    padding-left: 0px;
  }

  .actual-sec-1 .col-6 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .actualidad_wrap_rht {
    height: 260px;
    justify-content: space-between;
    margin-top: 25px;
  }

  .actualidad_wrap_rht .row:first-child {
    float: left;
    width: 40%;
    justify-content: space-between;
  }

  .actual-sec-1 .row {
    margin-right: 0;
    margin-left: 0;
  }

  .actualidad_wrap_rht .row:first-child .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .actualidad_wrap_rht .row:first-child .col-6:first-child {
    order: 2;
    margin-top: 20px;
  }

  .actualidad_wrap_rht .row:first-child .col-6:nth-child(2) {
    order: 1;
  }

  .actualidad_wrap_rht .row:nth-child(2) {
    order: 1;
    padding-left: 35px;
  }

  .actualidad_wrap_rht .row:nth-child(3) {
    order: 2;
    padding-left: 35px;
  }

  .schedule .col-6,
  .magzine .main-row .wrapper {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .schedule .col-6 {
    padding-right: 0;
    padding-left: 0;
  }

  .schedule .col-6:first-child {
    padding-right: 0
  }

  .wrapper .listrht_img {
    width: 100%;
    height: 150px;
  }

  .image_slider_longnews .connoticias_1 h1 {
    font-size: 17px;
    min-height: 40px;
    margin: 14px 0 10px 0;
  }

  .image_slider_longnews .connoticias_1 p,
  .cont-rht-11 h2 {
    font-size: 12px;
  }

  .container.cont-rht-11 {
    height: auto;
  }

  .magzine {
    margin-top: 0;
  }

  .A-la-venta-esta-sema1 {
    font-size: 25px;
  }

  .main-row .wrapper:first-child {
    margin-bottom: 50px;
  }

  .cont-rht-11 .slick-prev,
  .cont-rht-11 .slick-next {
    top: 130px;
  }

  .actualidad_longnews_heading {
    font-size: 29px;
    min-height: 40px;
  }

  .image_slider_longnews .connoticias_1 p {
    -webkit-line-clamp: 4;
  }

  .actualidad_wrap_rht {
    margin-top: 40px;
    height: auto;
  }

  .image_slider_longnews .noticias_1 img {
    width: 100%;
  }

  .image_slider_actualidadvideo .img_Wrap img {
    height: 347px;
    width: 100%;
  }

  .image_slider_longnews .slick-prev,
  .image_slider_longnews .slick-next {
    top: calc(100vw/10.5);
  }

  .image_slider_longnews .slick-next {
    right: 0px;
  }

  .cont-rht-11 .bibli-img {
    width: 130px;
    height: 200px;
  }

  .image_slider_longnews .slick-prev {
    left: 0px;
  }

  .cont-rht-11 .slick-prev,
  .cont-rht-11 .slick-next {
    top: 150px;
  }

  .image_slider_actualidadevents .connoticias1 p {
    font-size: 13px;
    -webkit-line-clamp: 3;
    min-height: 63px;
  }

  .image_slider_longnews {
    margin-bottom: 10px;
  }

  .actualidadproduct {
    margin-top: 0px !important;
  }

  .imglft {
    height: calc(calc(100vw * 0.363) * 1.526);
    width: calc(100vw * 0.363);
  }

  .image_slider_actualidadevents,
  .image_slider_actualidadvideo {
    margin-bottom: 30px;
  }

  .actualidad_video_heading {
    -webkit-line-clamp: 2;
    font-size: 29px;
  }

  .actualidad_content_list_ellipsis {
    font-size: 16px;
  }

  .img-w {
    height: 200px;
  }

  .cont-w p {
    font-size: 13px;
  }

  .date-w {
    width: 51px;
    height: 51px;
    left: 13px;
    top: 13px;
  }

  .date-w p {
    font-size: 20px !important;
  }

  .date-w h6 {
    font-size: 10px !important;
  }

  .heading-left {
    width: 100%;
    float: none;
    margin-top: 30px;
  }

  h2.A-la-venta-esta-sema1.line7 {
    width: 50%;
  }

  .navigation-center {
    float: left;
    width: 50%;
    margin-left: 0;
    margin-top: 20px;
  }

  .navigation-center .navbar {
    padding: 0;
  }

  .serch-right {
    float: left;
    width: 50%;
    margin-top: 20px;
  }

  .navigation-center .navi3 a,
  .navigation-center .navi3 li.active a {
    font-size: 16px;
  }



  .tabdataabove {
    margin-bottom: 40px;
  }

  .serch-right .drowdown2 {
    margin-left: 10px;
    width: 140px;
  }

  .serch-right select {
    width: 140px;
  }

  .serch-right {
    margin-top: 0;
  }

  .eventModal {
    width: 708px !important;
  }

  .eventModal .eventTitle {
    font-size: 25px;
    line-height: 29px;
  }

  .eventModal .eventDate {
    width: 268px;
    height: 87px;
    top: 30px;
    left: 43px;
    line-height: 23px;
  }

  .eventModal .eventDate h5 {
    text-transform: uppercase;
  }

  .eventModal .ant-modal-body {
    padding: 30px;
  }

  .eventModal .eventDate h5,
  .eventModal .eventDate p {
    font-size: 18px;
  }

  .eventModal .eventDetail {
    font-size: 17px;
    line-height: 23px;
    margin-top: 0;
    height: auto;
  }

  .eventModal .ant-modal-content {
    overflow-y: auto;
  }

  .tabdatabelow .col-4 {
    max-width: 151px;
    width: 151px;
    margin-right: 10px;
  }

  .tabdatabelow .col-4:last-child {
    max-width: 209px;
    width: 209px;
  }

  .eventModal .ant-modal-content {
    padding: 10px !important;
  }

  .eventModal .col-7 .eventTitle,
  .eventModal .col-7 .line4,
  .eventModal .col-7 .cta {
    display: none;
  }

  .eventModal .col-5 {
    flex: 0 0 55%;
    max-width: 55%;
  }

  .eventModal .col-7 {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .tabdatabelow {
    width: 90%;
    margin-top: 30px;
  }

  .contacts p {
    font-size: 12px;
  }

  .distribucion .contacts p {
    font-size: 15px !important;
    line-height: 21px;
  }

  .links.nomargin {
    margin: 30px 0 20px;
  }

  .product .cont-rht .col-2 .img-wrapper {
    width: 100%;
	display: flex;
	align-items: flex-end;
  }

  .table-form-3 tr td:nth-child(2) {
    width: 300px;
  }

  .nav-2-wrapper li {
    width: 176px;
  }

  hr.line7 {
    width: 125px;
  }

  .quan_arrow_back {
    top: 45px;
    left: 10px;
  }

  .quan_arrow_frwd {
    top: 45px;
    right: 10px;
  }

  .ediciones-products .slick-prev {
    left: unset;
    top: 47px;
    background: transparent !important;
    right: 50px;
  }

  .ediciones-products .slick-slider {
    position: unset;
  }

  .ediciones-products .slick-next {
    right: 0;
    top: 47px;
  }
}

@media only screen and (max-width: 1200px) {
	.nav-inner ul li {		
		padding: 5px 15px 20px 5px;		
	}
  .personmenu-triangle-black {
    right: 100px;
  }

  .image_slider_releases h5 {
    font-size: 15px !important;
  }

  .image_slider_releases p {
    font-size: 17px !important;
  }

  .producttitlerow .col-4 {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1141px) {
	
	.cont-wrap-cover.product .cont-rht {
		margin-left: 0px;
		/* margin-right: 15px; */
	}
  .card-wrapper .img-wrapper {
    height: 489px;
  }

  .ckeditor h2 {
    font-size: 29px;
  }

  .ckeditor h3 {
    font-size: 25px;
  }

  .ckeditor h4 {
    font-size: 20px;
  }

  .catalogo a {
    font-size: 23px;
  }

  .catalogo label {
    margin-left: 8px;
  }

  .is-sticky-menumobile+.personmenu-tab-mob.showmenu {
    top: 60px
  }

  .is-sticky-menumobile+.personmenu-tab-mob.showmenu .personmenu-triangle-black.login {
    right: 80px
  }

  .add-radio.billing-choice {
    margin-top: 0;
  }

  .table-form-3 tr td:first-child img {
    width: 100%;
  }

  .table-form-3 tr td:first-child {
    height: 165px;
  }

  .homemagzinesec .magzine-desc {
    height: 222px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }

  .image_slider_homeproducts img {
    padding: 0 3px 0 3px;
  }

  .image_slider_homeproducts .img-wrapper {
    height: calc(calc(100vw * 0.242) * 1.553);
  }

  .homepagelastsec {
    margin-top: 26px;
  }

  .container.main-container,
  .container.heading-container,
  .container-fluid.header_wrap,
  .header_wrap .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .container.main-container,
  .container.heading-container,
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .container-fluid.header_wrap {
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 30px;
    padding-bottom: 22px;
  }

  .container.footer_sec,
  .container.line-container {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  .header_wrap .navbar-expand-md .navbar-toggler {
    display: block;
  }

  .header_wrap .major-nav {
    margin-top: 0px;
    margin-left: 25px;
  }

  .logo {
    width: 102px;
    height: 40px;
  }

  .navbar.navbar-expand-md.postion {
    display: none;
  }

  .navbar.tab-mob {
    display: block;
    padding: 0;
  }

  .navbar.tab-mob .black-header-div {
    background: #000;
  }

  .home-menu .navbar.tab-mob .black-header-div {
    background: #000;
    top: -53px;
    position: absolute;
    width: 100%;
  }

  .home-menu .navbar.tab-mob .navbar-toggler {
    top: -113px;
  }

  .navbar.tab-mob .navbar-toggler {
    padding: 0 0 5px;
    position: absolute;
    top: -60px;
    left: 135px;
  }

  .white-header .navbar.tab-mob .navbar-toggler {
    padding: 0 0 5px;
    position: absolute;
    top: -62px;
    left: 140px;
  }

  .social3 ul li {
    padding-bottom: 0px;
  }

  .blackbars .menubar {
    font-size: 31px;
  }

  .navbar.tab-mob .small-nav-tab-mob li {
    padding: 10px 0px;
    margin: 0 30px 0 0;
  }

  .navbar.tab-mob .small-nav-tab-mob ul {
    padding-left: 30px;
  }

  .navbar.tab-mob .small-nav-tab-mob .sub-menu ul {
    padding-left: 0;
  }

  .navbar.tab-mob .small-nav-tab-mob li span,
  .navbar.tab-mob .small-nav-tab-mob li a {
    font-weight: normal;
    opacity: 0.8;
  }

  .navbar.tab-mob li {
    padding: 10px 0px;
    border-bottom: 1px solid #fff;
    margin-bottom: 0px;
    text-align: left;
    margin: 0 30px 0 30px;
    width: auto;
    position: relative;
  }

  .small-nav-tab-mob {
    padding-bottom: 40px;
  }

  .header1 {
    overflow: visible;
  }

  .navbar.tab-mob li span,
  .navbar.tab-mob li a {
    color: #fff !important;
    font-size: 17px;
    margin-left: 0;
    padding: 0;
    font-weight: bold;
  }

  .navbar.tab-mob .mainmenu {
    padding-top: 44px;
    display: block;
  }

  .sub-menu.show {
    padding-top: 10px;
  }

  .navbar.tab-mob .sub-menu.show li {
    padding: 10px 0px;
    margin: 0 0 0 30px;
  }

  .navbar.tab-mob {
    display: block;
    z-index: 999;
    position: absolute;
    width: 100%;
  }

  .navbar.tab-mob .search-triangle-black {
    display: block;
    left: 143px;
  }

  .sub-menu.show li a {
    font-weight: normal;
    opacity: 0.8;
  }

  .sub-menu.show li:last-child {
    border-bottom: 0
  }

  .navbar.tab-mob li a:hover {
    background-color: #000;
    cursor: pointer;
  }

  .navbar.tab-mob .fa-caret-down,
  .navbar.tab-mob .fa-caret-up {
    color: #fff;
    position: absolute;
    top: 15px;
    right: 0;
  }

  .blackbars .menubar {
    color: #000;
    font-size: 28px;
  }

  .sticky-logo.logo {
    margin-top: 0;
  }

  .whitebars .menubar {
    color: #fff;
    font-size: 28px;
  }

  .is-sticky-menumobile .blackbars .menubar,
  .is-sticky-menumobile .whitebars .menubar {
    color: #000 !important;
  }

  .is-sticky-home {
    height: 61px;
  }

  .is-sticky-home .header_wrap {
    padding-top: 15px;
  }

  .is-sticky-menumobile .home-menu .navbar.tab-mob {
    position: fixed;
    top: 128px;
  }

  .is-sticky-menumobile .common-menu .navbar.tab-mob {
    position: fixed;
    top: 74px;
  }

  .is-sticky-menumobile .common-menu .navbar.tab-mob .black-header-div {
    margin-top: -14px;
  }

  .is-sticky-menumobile .home-menu .navbar.tab-mob .black-header-div {
    top: -65px;
  }

  .is-sticky-menumobile .common-menu .navbar.tab-mob .search-triangle-black {
    top: -24px;
  }

  .socialico5 {
    font-size: 16px !important;
    margin-left: 15px;
  }

  .box5 {
    margin: 0 0 0 20px;
    font-size: 21px !important;
  }

  .small-upper-menu {
    display: none;
  }

  .nav-item.nave-wrap {
    padding-top: 20px;
  }

  #collapsibleNavbar3 ul li a {
    color: #fff !important;
    font-size: 17px;
  }

  #collapsibleNavbar3 .navbar-nav .nav-item ul {
    z-index: 999;
    right: -10px;
  }

  .filterwrap {
    position: relative;
  }

  .filterwrap .navbar-toggler {
    float: right;
    position: absolute;
    right: -13px;
    top: 8px;
  }

  .header_wrap .navbar-expand-md .navbar-nav {
    flex-direction: column;
    padding: 0 12px 0 10px;
    display: flex;
  }

  .header_wrap .collapse:not(.show) {
    display: none !important;
  }

  .navbar-collapse .navbar-nav .nave-wrap .nav-link {
    margin-left: 0px;
    padding-right: 0;
    padding-left: 0;
  }

  .deco-wrap ul.deco {
    width: 768px;
    right: -562px;
    top: 73px;
    background: #000;
    position: absolute;
    z-index: 999;
  }

  #collapsibleNavbar3 .navbar-nav .nav-item ul {
    z-index: 999;
  }

  .nav-inner ul li {
    padding: 0px 0px 20px 0;
  }

  ul.espac.arrownavespac li,
  .b2,
  .b3,
  .b1 {
    padding: 0 !important;
  }

  .navbar-nav .nav-item:hover .arrownavespac {
    display: inline-grid;
    margin: 0 -40px;
  }

  .navbar-nav .nav-item:hover .triangleespac {
    left: 100px;
  }

  .deco-wrap ul {
    padding: 0 !important;
    margin: 0;
    width: 220px;
  }

  .deco li {
    width: 100%;
    padding: 14px 0;
  }

  .deco-wrap {
    width: 100%;
  }

  .b2 {
    height: 35px;
  }

  .b3 {
    height: 0px;
  }

  .white-header .b1,
  .white-header .b2 {
    border: 0;
  }

  .container-fluid.footerform {
    max-width: 100% !important;
    width: 100% !important;
  }

  .ant-modal.cookieModal {
    width: 768px !important;
    max-width: 768px !important;
  }

  .social1 ul li i {
    font-size: 17px;
  }

  .container.main-container {
    max-width: 100% !important;
    width: 100% !important;
  }

  .container-fluid.black-border {
    width: 100% !important;
    max-width: 100% !important;
  }

  .main-container.innerpage.tiendas {
    padding-right: 0 !important;
    margin-bottom: 80px;
  }

  .tiendas .map {
    margin-bottom: 0px;
  }

  .small-upper-menu {
    width: 90% !important;
  }

  .mleft-2 {
    margin-left: 0px !important;
  }

  .cont-rht-2 li {
    width: 130px;
  }

  .cont-rht-2 li img {
    height: 200px;
  }

  .favoritos .cont-rht-2 .col-2 {
    margin: 0px 0 40px 0px;
    padding-right: 14px;
  }

  .favoritos .drowdown-2 {
    float: right;
  }

  .favoritos .col-7 {
    margin-top: -21px;
  }

  .favoritos .heading-cont-left .favoritos .cont-rht-2 .col-2 img {
    height: 200px;
  }

  .favoritos .cont-rht-2 h2 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    font-size: 12px;
    line-height: 15px;
    min-height: 32px;
  }

  .image_slider_longnews .slick-slide.slick-active {
    margin-left: 0px;
    margin-right: 0;
  }

  .image_slider_longnews .slick-slide.slick-active.slick-current .connoticias_1 {
    margin-left: 11px;
  }

  .schedule {
    margin-top: 35px;
    margin-bottom: 42px;
  }

  .image_slider_longnews .slick-next {
    right: 0;
  }

  .magzine {
    margin-top: 20px;
  }

  .main-row .wrapper:first-child {
    margin-bottom: 60px;
  }

  .image_slider_longnews .slick-prev {
    top: 75100pxpx;
  }

  .magzine .listrht {
    float: right;
    width: 386px;
  }

  .magzine .listrht .col-6:first-child {
    flex: 0 0 55%;
    max-width: 55%;
    padding-right: 0;
    padding-left: 0;
  }

  .magzine .listrht .col-6:nth-child(2) {
    flex: 0 0 45%;
    max-width: 45%;
    padding-right: 0;
    padding-left: 0;
  }

  .magzine .main-row .wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .actualidadproduct {
    margin-top: 15px;
  }

  .schedule .col-6:first-child {
    padding-right: 0;
  }

  .image_slider_actualidadvideo .slick-track {
    padding: 0;
  }

  .image_slider_actualidadvideo .img_Wrap img {
    height: 347px;
    width: 707px;
  }

  .image_slider_actualidadvideo {
    margin-bottom: 40px;
  }

  .wrapper .listrht_img {
    width: 210px;
    height: 120px;
  }

  .schedule .section21 .slick-list .slick-track {
    margin: 0;
  }

  .actualidad_wrap_rht .row:first-child {
    width: 32.5%;
  }

  .cont-w .col-2 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0;
    padding-left: 0;
  }

  .actualidad_wrap_rht .shortnewsimg {
    max-width: 50%
  }

  .actualidad_shortnews_heading {
    width: 98%;
  }

  .image_slider_homeproducts p {
    font-size: 12px;
    line-height: 15px;
  }

  .cont-w .row {
    margin-right: 0;
    margin-left: 0;
  }

  .heading_lft_cont,
  .buttonChangePassword {
    display: none;
  }

  .heading_rht_cont {
    float: none;
    width: 100%;
    margin-top: 0px;
    padding: 0 0px;
  }

  .heading_rht {
    float: none;
    width: 100%;
  }

  .heading_rht_cont .row {
    margin-right: 0;
    margin-left: 0;
  }

  .heading_rht_cont .row .col-6 {
    padding-right: 0;
    padding-left: 0;
  }

  .personMenuDesktop {
    display: none;
  }

  .personmenuicon {
    display: block;
    position: relative;
  }

  .personmenu-tab-mob.showmenu {
    display: block;
    top: 90px;
    position: fixed
  }

  .personmenu-tab-mob .row {
    margin-right: 0;
    margin-left: 0;
  }

  .search-outer-container {
    left: 50%;
  }

  .search-triangle,
  .search-container {
    display: none;
  }

  .search-tab-mob {
    display: inline-block;
    width: 100%;
    top: 5px;
  }

  .home-search .search-tab-mob {
    top: 24px;
  }

  .search-resp-input {
    width: 100%;
  }

  .container.main-container.search-outer-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    transform: translateX(-49%);
    left: 49%;
    margin-top: -20px;
    z-index: 10;
  }

  .search-outer-container .search-wrapper {
    width: 100%;
  }

  .search-triangle-black {
    right: 188px;
  }

  .search-wrapper .search-tab-mob .col-10 {
    flex: 0 0 77.333%;
    max-width: 77.333%;
  }

  .search-tab-mob .row {
    margin-right: 0;
    margin-left: 0;
  }

  .search-tab-mob .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .search-tab-mob .list-bar .col-7 {
    flex: 0 0 70%;
    max-width: 70%;
    padding-left: 0;
    padding-right: 0;
  }

  .navigation .navi1 .navbar-nav a {
    font-size: 16px;
  }

  .ayuda .faq-select-tab-mob {
    display: block;
    margin-top: 8px;
    width: 333px;
  }

  .ayuda .subheading br {
    display: block;
  }

  .faq-select-tab-mob .btn2_cont {
    border: 4px solid #000 !important;
    height: 52px;
  }

  .ayuda .card-body1 {
    margin-bottom: 40px;
    padding: 5px
  }

  .tabmobiledropdown #tabmobiledropdownMenuButton {
    border: 0;
    width: 100%;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
  }

  .tabmobiledropdown .dropdown-menu.show {
    display: block;
    background: #000;
    color: #fff;
    width: 102.5%;
    top: -1px !important;
    left: -13px !important;
  }

  .tabmobiledropdown #tabmobiledropdownMenuButton {
    border: 0;
    width: 100%;
    padding: 6px 0;
    font-size: 14px;
    font-weight: bold;
  }

  .tabmobiledropdown .dropdown-menu.show {
    display: block;
    background: #000;
    color: #fff;
    width: 102.5%;
    top: 16px !important;
    left: -13px !important;
  }

  .tabmobiledropdown .dropdown-menu a {
    color: #fff;
  }

  .tabmobiledropdown .dropdown-item:focus,
  .dropdown-item:hover {
    color: #000 !important;
  }

  .tabmobiledropdown #tabmobiledropdownMenuButton:after {
    display: none;
  }

  .ayuda .accordion {
    margin-bottom: 30px;
  }

  .ayuda .line4 {
    width: 40%;
    margin-top: 0;
    height: 4px;
  }

  .ayuda .subheading {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .ayuda .col_wrap {
    width: 100%;
    min-height: 52px;
    height: auto;
  }

  .col_wrap:first-child {
    margin: 40px 0 0 0;
  }

  .container.footer_sec {
    max-width: 100% !important;
    width: 100% !important;
  }

  .faq-select-tab-mob .btn2_cont:focus {
    box-shadow: unset;
  }

  .ayuda .col-3 {
    display: none;
  }

  .ayuda .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card-title1 {
    font-size: 16px;
  }

  .ayuda h2 {
    width: 50%;
    float: left;
    margin: 10px 0 0px 0px;
  }

  .faq-select-tab-mob {
    width: 50%;
    float: right;
  }

  .rights .inner-wrap .col-2 {
    width: 138px;
    margin-bottom: 20px;
    padding-right: 15px;
  }

  .rights .inner-wrap .col-2:nth-child(7n) {
    padding-right: 15px;
  }

  .rights br {
    display: block;
  }

  .inner-wrap p {
    font-size: 12px;

  }

  .rights .inner-wrap p {
    -webkit-line-clamp: 3;
  }

  .inner-wrap {
    margin: 40px 0 0 0;
  }

  .libreros-container {
    margin-top: 170px;
    margin-bottom: 80px;
  }

  .rights .inner-wrap .col-2 .img-wrapper {
    height: 215px;
  }

  .bibli .inner-wrap .col-2 .img-wrapper {
    height: 200px;
  }

  .bibli .inner-wrap .col-2 {
    width: 20%;
    padding-right: 15px;
    margin-bottom: 30px;
    flex: 0 0 20%;
  }

  .bibli .ant-select-selector {
    height: 42px !important;
  }

  .bibli .ant-pagination {
    margin-top: 10px;
  }

  .bibli .inner-wrap .col-2:nth-child(5n) {
    padding-right: 0;
  }

  .bibli .inner-wrap .col-2:nth-child(7n) {
    padding-right: 15px;
  }

  .bibli .col-10 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .bibli .inner-wrap .col-2 p {
    font-size: 12px;
    line-height: 15px;
    webkit-line-clamp: 3;
    margin: 10px 0;
  }

  .bibli .col-2 {
    flex: 0 0 25%;
    max-width: 25%;

  }

  .bibli .drowdown {
    margin-top: -32px;
  }

  .bibli .inner-wrap .col-2 p {
    -webkit-line-clamp: 3;
  }

  .btn2_cont.confirm {
    width: 131px;
  }

  .cont_form_2 h5 {
    font-size: 20px;
    line-height: 26px;
  }

  .catalogo .heading-wrap h2 {
    font-size: 17px;
    line-height: 23px;
  }

  .navigation-w .nav>li>a {
    padding: 10px 8px !important;
    font-size: 12px;
  }


  .cont-w .event-wrapper.col-2 {
    flex: auto;
    padding-right: 5px;
    padding-left: 0;
    margin-bottom: 40px;
  }

  .cont-w {
    margin-top: 30px;
  }

  .cont-w .event-wrapper.col-2:nth-child(4n) {
    padding-right: 0;
  }

  .cont-wrap .row .col-2 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 40px;
  }

  .catTitle p {
    font-size: 17px;
    line-height: 23px;
  }

  .producttitlerow .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .producttitlerow.productlist .col-3 {
    flex: 0 0 62%;
    max-width: 62%;
    margin-bottom: 0;
    display: none;
  }

  .producttitlerow .drowdown {
    margin-top: 6px;
  }

  .producttitlerow .col-5 {
    flex: 0 0 70%;
    max-width: 70%;
    padding-left: 0;
  }

  .producttitlerow .col-4 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .filter-header-tab,
  .restaurar-btn,
  .filter-btn,
  .filter-footer-tab {
    display: block;
  }

  .producttitlerow .filter-btn {
    width: 90%;
    margin-top: 30px;
    height: 52px;
  }

  .filter-div {
    display: none;
  }

  .filter-div-mob {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .col-9.product-div {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .product .cont-rht .col-2 {
    margin: 40px 0 0px 0px;
  }

  .result-search-bar {
    display: block;
    visibility: visible;
  }

  .tabmobiledropdown {
    height: 52px !important;
    border-width: 4px !important;
  }

  .tabmobiledropdown #tabmobiledropdownMenuButton {
    font-size: 16px !important;
  }

  .product .cont-rht .col-2 .img-wrapper {
    height: calc(calc(100vw * 0.169)*1.538);
  }

  .product.content .cont-rht .col-2 .img-wrapper {
    height: calc(calc(100vw * 0.225)*0.49);
  }

  .filter-div-mob .blue-pattern-layer {
    display: block;
  }

  .product-div {
    margin-top: 0px;
  }

  .product .cont-rht .col-2 h2 {
    font-size: 12px;
    line-height: 15px;
    height: 30px;
  }

  .product-div .ant-pagination {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .product .ant-pagination.pagination {
    margin-left: 0;
  }

  .filterwrap1 {
    width: 300px;
  }

  .restaurar-btn {
    position: absolute;
    right: 0;
    top: 25px;
  }

  .restaurar-btn .btn2 {
    width: 160px;
  }

  .filter-header-tab {
    height: 90px;
    background-color: #e2f1ff;
    width: 100%;
    padding: 35px 25px;
    margin-top: 20px;
  }

  .filter-header-tab label {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .filter-header-tab .btn2 {
    float: right;
    margin-top: 0;
  }

  .category_desc {
    font-size: 12px;
    line-height: 15px;
    margin-top: 21px;
  }

  .settings {
    vertical-align: middle;
    margin-right: 15px;
  }

  .setting-filter {
    vertical-align: middle;
    margin-right: 15px;
    float: right;
  }

  .filter-footer-tab {
    height: 90px;
    background-color: #e2f1ff;
    width: 100%;
    padding: 0px 25px;
    margin-top: -3px;
  }

  .blue-pattern-layer {
    margin-top: 60px;
  }

  .blue-pattern-layer img {
    width: 100%;
  }

  .filter-footer-tab .btn2 {
    height: 52px;
    width: 100%;
    background-color: #fff;
  }

  .my-50.actual-sec-1 {
    margin-bottom: 30px;
  }

  .actual-sec-1 .col-5 .col-6.shortnewsimg {
    padding-left: 0;
  }

  .actualidad_wrap_rht .row {
    margin-bottom: 20px;
  }

  .image_slider_homeproducts p,
  .actualidad_shortnews_ellipsis {
    -webkit-line-clamp: 3;
  }

  .image_slider_homeproducts .slick-slide.slick-active.slick-current p {
    margin-left: 4px;
  }

  .actual-sec-1 {
    margin-bottom: 40px;
  }

  .cont-rht-11 .slick-prev,
  .cont-rht-11 .slick-next {
    top: calc(50% - 40px);
  }

  .mb-50 {
    margin-bottom: 30px;
  }

  .connoticias1 {
    height: 210px;
  }

  .tiendas .subheading {
    font-size: 17px;
    line-height: 23px;
  }

  .lftcontent,
  .rhtcontent {
    float: none;
    width: 100%;
  }

  .rhtcontent .btn1 {
    float: left;
    width: 100px;
  }

  .rhtcontent .btn1:nth-child(2) {
    margin-left: 20px;
  }

  .tiendas .bgcover p {
    font-size: 14px;
  }

  .bgcover {
    width: 100%;
  }

  .tiendas .map {
    padding-right: 0;
  }

  .arrow3 {
    top: 207px;
  }

  .arrow3.alicante {
    top: 572px;
  }

  .arrow3.arenas {
    top: 939px;
  }

  .cont_form li p {
    font-size: 17px;
    line-height: 23px;
  }

  .agenda h2.A-la-venta-esta-sema.line7 {
    width: 357px;
  }

  .navigation-center {
    width: 54%;
    margin-top: 10px;
  }

  .serch-right {
    width: 46%;
    margin-top: -13px;
  }

  .serch-right select {
    height: 42px;
    width: 140px;
  }

  .productos .slider-for-2,
  .tienda_rht {
    display: none;
  }

  .tienda_lft,
  .tienda_rht {
    float: none;
  }

  .productos .col-4,
  .productos .col-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tienda_lft ul li {
    font-size: 17px;
  }

  .content_ten p {
    font-size: 12px;
  }

  .price h4 {
    margin-bottom: 20px;
  }

  .price button {
    margin-bottom: 40px;
    width: 226px;
  }

  .mt-60.prodEditions {
    margin-top: 60px;
  }

  .inner-wrap-prod.rowdata .col-2 {
    padding-right: 15px;
  }

  .prodEditions .card-wrapper .title {
    height: 35px;
  }

  .prodEditions.relacionados {
    margin-bottom: 80px;
  }

  .prodEditions .inner-wrap-prod.noslider .col-2 {
    max-width: 130px;
  }

  .image_slider_homereleases .slick-prev,
  .image_slider_homereleases .slick-next {
    top: 80px;
  }

  .tshirtdiv .content {
    padding: 50px 20px 0 20px;
  }

  .arrow4 {
    left: 35px;
  }

  .social-icon-prod-tab .socialico5 {
    font-size: 16px;
  }

  .cont-rht-2 h2 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .cookie-text {
    font-size: 17px;
  }

  .cookieModal {
    padding: 40px 30px;
  }

  .orderskeleton .orderskeletoninput {
    width: 515px !important;
  }

  .img404,

  .number_tag h2 {
    padding: 2px;
    margin: 0;
    background-color: #fff;
  }

  .active_4 .number_tag h2 {
    border: 3px solid #000;
  }

  .number_tag p,
  .number_tag span {
    font-size: 12px;
  }

  hr.line7 {
    width: 100%;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: -o-available;
    left: 35px;
    top: 37px;
  }

  .nav-2-wrapper ul {
    width: 100%;
    display: flex;
  }

  .nav-2-wrapper li {
    max-width: 20%;
    width: 20%;
  }

  .blue-div {
    padding: 18px 15px;
  }

  .blue-div p {
    font-size: 12px !important;
    margin-top: 0;
    margin-left: 0 !important;
  }

  .nav-2-wrapper li:last-child {
    width: 40px;
  }

  .cart-step .lft-cont {
    float: none;
    width: 100%;
  }

  .cart-step .lft-cont br {
    display: none;
  }

  .cart-step .rht-cont {
    float: none;
    width: 100%;
    margin-top: 50px;
  }

  .laterprod-blue-div br {
    display: block !important;
  }

  .laterprod-blue-div {
    height: 95px;
    margin-bottom: 0px;
  }

  .laterprod-blue-div p {
    width: 80%;
  }

  .dudas {
    text-align: right;
    margin-top: 30px;
    display: block;
  }

  .dudas a {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }

  .add-radio {
    height: 27px;
    width: 27px;
    margin-top: 0px;
    margin-right: 15px;
  }

  .postal-text {
    margin-left: 0;
  }

  .no-courier {
    margin-left: 42px;
  }

  .blue {
    font-size: 12px;
    line-height: 15px;
  }

  .info-select {
    margin-top: 40px;
  }

  .cart-step {
    margin-top: 40px;
  }

  .mar-wrap-lft {
    width: 276px !important;
    margin-right: 19px;
  }

  .form-control-2 {
    width: 76%;
  }

  .tohide {
    display: none;
  }

  .checkbox-list {
    display: block;
    width: 768px;
    margin-bottom: 30px;
  }

  .textare-step4 p,
  .step4-2 p {
    font-size: 17px;
  }

  .check-5 img {
    left: -52px;
    margin: 0 0 0 10px;
  }

  .edit img {
    width: 16px;
  }

  .check-step-2 img {
    left: -52px;
    top: -15px;
  }

  .checkbox-list ul li {
    display: flex;
  }

  .textare-step4 {
    padding: 30px 0 0;
  }

  .arrow_down {
    right: 15px;
    bottom: -6px;
  }

  .buttn.belowbtn {
    display: block;
    margin-top: 20px;
  }

  .table-form-3 tr td {
    padding: 8px;
    text-align: center;
  }

  .table-form-3 tr td:nth-child(3) {
    text-align: left;
  }

  input.field_1 {
    width: 30px;
    height: 30px;
  }

  .leftarrowimg,
  .rightarrowimg {
    width: 25px;
    height: 30px;
    vertical-align: bottom;
  }

  .table-form-3 td:nth-child(2) p,
  .table-form-3 tr th {
    font-size: 12px;
  }

  .totalamt {
    font-size: 15px !important;
  }

  .coupon-desc {
    left: 240px;
    padding: 20px 18px;
  }

  .coupon-error {
    display: none;
  }

  .blue-triangle {
    top: 44px;
    right: 19px;
  }

  .table-form-3 img.cross {
    margin-top: 6px;
  }

  .table-form-3 tr td:nth-child(2) {
    width: 250px;
  }

  .table-form-3 tr td:nth-child(3) br {
    display: initial;
  }

  .table-form-3 span {
    margin-right: 0;
  }

  .quan_arrow_back {
    position: unset;
    vertical-align: sub;
    padding-right: 15px;
  }

  .quan_arrow_frwd {
    position: unset;
    vertical-align: sub;
    padding-left: 15px;
  }

  .text-left {
    font-size: 15px !important;
  }

  .shop-add p,
  .ship-add p,
  .ship-method p,
  .postal-add p,
  .sel-add p,
  .postal-sel-add p,
  .selected-add p,
  .no-courier p {
    font-size: 17px;
    line-height: 23px;
  }

  .selected-add p b {
    font-size: 14px;
    line-height: 17px;
  }

  .showonTab {
    display: initial;
  }

  .laterproducts {
    margin-top: 50px;
  }

  .address-btn {
    margin-left: 24px;
    float: none;
    margin-top: 20px;
    font-size: 14px;
    padding: 5px 20px !important;
    display: block;
    margin-bottom: 40px;
  }

  .shop-add span,
  .ship-method span {
    margin-left: 0;
  }

  .shop-add p {
    margin-left: 42px;
  }

  .address-btn.bill-add {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .sel-billing-add p {
    font-size: 17px;
    line-height: 23px;
  }

  .sel-billing-add p.direccion {
    font-size: 14px;
    line-height: 13px;
  }

  .wrap-colp {
    padding: 30px;
  }

  .note {
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .textare-step4 {
    padding: 40px 0 0;
  }

  .checkbox-list .custom_checkbox_wrapper {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 12px;
  }

  .step-5 .form-group {
    margin-bottom: 0px;
  }

  .checkbox-list .checkmark {
    top: 0px;
  }

  .condition-error {
    display: none;
  }

  .sel-btn {
    margin-top: 30px;
  }

  .postal-sel-add {
    float: none;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .step-1 .buttn.belowbtn {
    margin-top: 30px;
  }

  .table-form-order tr th,
  .table-form-order tr td,
  .table-form-order td p,
  .subtotal p .myspace p {
    font-size: 12px;
  }

  .blue-wrap p,
  .order-details-add p {
    font-size: 12px !important;
  }

  .table-form-3 td:nth-child(2) {
    width: 250px;
  }

  .productos .prod_img-div .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .productos .prod_img-div .col-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mt-50 {
    margin-top: 40px;
  }

  .orderdetailimg {
    width: 72px;
    height: 110px;
  }

  .image_slider_homenews .connoticias h1 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    min-height: 60px;
  }

  .home_news_description {
    -webkit-line-clamp: 6;
    font-size: 12px;
    line-height: 15px;
  }

  .image_slider_homenews .connoticias p {
    -webkit-line-clamp: 6;
    line-clamp: 6;
  }

  .pos-slider a {
    top: calc(100vw*0.092);
    left: 87px;
  }

  .image_slider_home_letura .slick-prev,
  .image_slider_home_letura .slick-next {
    top: calc(100vw*0.147);
  }

  .pos-slider h2 {
    top: calc(100vw*0.107);
  }

  .slick-prev,
  .slick-next {
    display: block !important;
  }

  .homepage_events_sec .slick-prev {
    top: -60px;
    left: unset;
    right: 50px;
  }

  .homepage_events_sec .slick-next {
    right: 0px;
  }

  .communicados .f-left {
    float: none;
  }

  .communicados .w-70 {
    width: 100%;
  }

  .pdf-btn-div {
    float: none;
    margin-top: 20px;
  }

  .pdf-btn {
    float: left;
    width: 275px;
    margin-right: 20px;
    border: 4px solid #000;
    font-size: 20px;
  }

  .pdf-btn-div br {
    display: none;
  }

  .innerpage-2 .subheading p {
    font-size: 20px;
  }

  .innerpage-2 p,
  .homereleases .connoticias p {
    font-size: 17px;
    line-height: 23px;
  }

  .homereleases .img-wrapper {
    height: 220px;
  }

  .btn2_cont.login {
    width: 131px;
  }

  .btn2_cont.register {
    width: 159px;
  }

  .btn2_cont.espacio {
    width: 131px;
  }

  .btn2_cont.luego {
    width: 136px;
  }

  .goo-face-rht .facebook,
  .goo-face-rht .google,
  .goo_face_wrap .facebook,
  .goo_face_wrap .google {
    width: 153px;
    height: 39px;
  }

  .goo-face-lft {
    width: 35%;
  }

  .goo-face-rht {
    width: 55%;
  }

  .registrar {
    width: 100%;
  }

  .libreros-container {
    margin-top: 133px;
    margin-bottom: 80px;
  }

  .btn2_cont.libreros {
    width: 155px;
    padding: 10px 14px !important;
  }

  .cont_form_2 h5 {
    font-size: 20px;
    line-height: 26px;
    margin: 20px 0 40px;
  }

  .btn2_cont.registerbtn {
    width: 131px;
  }

  .libreros-container .form input {
    margin-bottom: 30px !important;
  }

  .register-text {
    font-size: 12px !important;
  }

  .mer-filters-card h6 {
    font-size: 13px;
  }

  .card-wrapper .title {
    font-size: 12px;
  }

  .card-wrapper span {
    margin: 0 5px 0 0;
    font-size: 14px;
  }

  .homepage_contest_content {
    width: 210px;
  }

  .homepage_contest_content h3 {
    font-size: 25px;
  }

  .bannerText p {
    font-size: 12px
  }

  .bannerText h3 {
    font-size: 25px;
  }

  .homepage_contest_content p {
    font-size: 12px;
  }

  .image_slider_homenews .slick-prev,
  .image_slider_homenews .slick-next {
    top: calc(100vw*0.066);
  }

  .image_slider_homenews .slick-track {
    padding: 0;
  }

  .image_slider_homenews .connoticias {
    padding: 14px 10px 0;
  }

  .image_slider_homenews {
    margin-bottom: 24px;
  }

  .image_slider_homenews .slick-slide .image_slider_homenews_img {
    height: auto;
  }

  .image_slider_homenews .slick-slide.slick-active.slick-current {
    padding-left: 3px;
  }

  .image_slider_homenews .slick-slide.slick-active {
    padding-right: 3px;
    padding-left: 0px;
  }

  .image_slider_longnews {
    margin-bottom: 25px;
  }

  .image_slider_homeproducts.mb-50 {
    margin-bottom: 0 !important;
  }

  .image_slider_actualidadevents .noticias {
    margin-bottom: 20px;
  }

  .image_slider_longnews .slick-slide {
    margin-right: 0;
  }

  .image_slider_actualidadevents,
  .image_slider_actualidadvideo {
    margin-bottom: 20px;
  }

  .col-sm-8.padmar {
    padding-right: 30px;
  }

  .line6.magzineline {
    margin: 20px 0 40px;
  }

  .line6 {
    margin: 40px 0;
  }

  .image_slider_actualidadvideo p {
    margin-bottom: 0;
  }

  .tortues,
  .pooerosas {
    border-bottom: 3px solid #fff;
  }

  .justice,
  .tortues {
    border-right: 3px solid #fff;
  }

  .line6.line-footer {
    padding: 30px 0 39px;
  }

  .image_slider_actualidadevents .connoticias1 h1 {
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
  }

  .actualidad_video_heading {
    min-height: 40px;
  }

  .image_slider_actualidadevents .connoticias1 p {
    font-size: 13px;
    line-height: 18px;
    -webkit-line-clamp: 2;
    min-height: 38px;
  }

  .modal.image-lightbox {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .editorial_wrap .A-la-venta-esta-sema,
  .webs .editorial_wrap .line4 {
    width: 100%;
  }

  .navigation {
    margin-top: 40px;
    margin-bottom: 43px;
  }

  .editorial_wrap h3 {
    margin: 20px 0 11px 0;
  }

  .editorial_wrap p {
    margin: 0 0 10px;
  }

  .editorial_wrap ul li:nth-child(3) p,
  .editorial_wrap ul li:nth-child(4) p {
    margin: 0 0 40px;
  }

  .mt-60 {
    margin-top: 40px;
  }

  .contacto .navi li:first-child {
    width: 157px;
    height: 52px;
  }

  .contacto .navi li {
    width: 157px;
    height: 52px;
    padding: 8px 10px;
    text-align: center;
  }

  .btn2.contacto {
    border: 4px solid #000;
    margin-bottom: 40px;
    height: 39px;
    padding: 5px 10px;
  }

  .contacto br {
    display: block;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #000 !important;
    text-decoration: none;
    background-color: #e9ecef !important;
  }

  .dropdown-item.active a {
    color: #000 !important;
  }

  .ant-btn-primary.btn2_cont {
    width: 131px;
    height: 39px;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .myspace .cont_form u {
    margin-top: 27px;
  }

  .btn2_cont_btn,
  button.btn2_cont_btn.active {
    width: 215px;
    height: 39px;
  }

  .homepagelastsec h2 {
    font-size: 43px;
    margin: 0 108px 10px;
  }

  .homepagelastsec h5 {
    font-size: 17px;
    margin: 0 108px 20px;
  }

  .homepagelastsec p {
    font-size: 14px;
    margin: 0 108px 30px;
  }

  .subs-email input {
    width: 300px;
    height: 42px;
  }

  .innersec_images li p {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .historia.links {
    margin-bottom: 80px;
  }

  .social1 ul {
    margin-bottom: 0;
  }

  .footer_sec .form2 .form-group label {
    font-size: 10px;
  }

  .links.nomargin {
    margin: 30px 0 40px;
  }

  .innersec_images1 ul li {
    margin: 0 0 20px;
  }

  .innersec_images1 ul li img {
    width: 707px;
    height: 396px;
  }

  .tiendas {
    margin-top: -20px;
  }

  .event-wrapper .event-title,
  .connoticias1 .event-title {
    font-size: 13px;
    line-height: 18px;
    margin-top: 20px;
  }

  .event-wrapper .event-text,
  .connoticias1 .event-text {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 0;
    height: 38px;
  }

  .event-wrapper {
    margin-bottom: 40px;
    width: 173px;
  }

  .agenda {
    margin-bottom: 40px;
  }

  .table-form tr td {
    padding: 15px 10px;
  }

  .table-form tr th {
    font-size: 12px;
  }

  .image_slider_homeevents .connoticias1 p {
    font-size: 12px;
    line-height: 15px;
    -webkit-line-clamp: 4;
    min-height: 60px !important;
    margin: 14px 0 25px;
  }

  .image_slider_homeevents .agenda {
    margin-bottom: 0px;
  }

  .image_slider_homeevents .slick-slide>div {
    display: flex;
  }

  .personmenu-triangle-black {
    right: 125px;
  }

  .personmenu-triangle-black.login {
    right: 96px;
  }

  .resultados .tabmobiledropdown {
    display: block;
    position: relative;
    height: 52px;
  }

  .producttitlerow .tabmobiledropdown::after,
  .resultados .tabmobiledropdown::after {
    content: "\f0d7";
    color: #000;
    top: 8px;
    right: 10px;
    position: absolute;
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: bold;
    z-index: -1;
  }

  .product-details-options {
    display: none;
  }

  .product_details_intro,
  .notab-datos-content p {
    font-size: 12px;
  }

  .product-details-tab-mob-tab .header-title {
    font-size: 16px;
  }

  .product_details_intro::before {
    right: 0;
    bottom: 0;
  }

  .product_details_intro_wrapper .show_all_wrapper {
    right: 30px;
    bottom: 0;
  }

  .show_all_wrapper a {
    font-size: 10px;
  }

  .content_ten_tab {
    display: block;
    padding-top: 20px;
    padding-bottom: 30px;
    border-bottom: solid 1px #000;
  }


  .card-wrapper .title {
    font-size: 15px;
  }

  .product-details-tab-mob-tab {
    display: block;
  }

  .product-details-options-content ul li a {
    font-size: 14px;
    line-height: 20px;
  }

  .product-details-options-content ul li {
    padding: 10px 0;
  }

  .product-details-tab-mob-tab .autores-content .title,
  .product-details-tab-mob-tab .autores-content .description {
    font-size: 14px;
  }

  .product-details-tab-mob-tab .toggle-description.active {
    max-height: fit-content;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .product-details-tab-mob-tab .header-title {
    color: #aaa;
    border: solid 4px #aaa;
  }

  .product-details-tab-mob-tab .header-title.active {
    font-weight: bold;
    color: #000;
    position: relative;
    border: solid 4px #000;
  }

  .product-details-tab-mob-tab .noticia-list ul li {
    padding: 0;
  }

  .product-details-tab-mob-tab .contenidos-content .title {
    font-size: 14px;
  }

  .product-details-tab-mob-tab .contenidos-content .para {
    padding-top: 15px;
  }

  .ediciones-products h2,
  .relacionados-products h2 {
    font-size: 25px;
  }

  .ediciones-products {
    padding-top: 17px;
    height: 450px;
    padding-bottom: 30px;
  }

  .ediciones-products .slick-slider {
    position: relative;
  }

  .ediciones-products .slick-prev {
    left: -45px;
    top: 34%;
  }

  .ediciones-products .slick-next {
    right: -45px;
    top: 34%;
  }

  .prodEditions .card-wrapper .title {
    font-size: 12px;
  }

  .relacionados-products {
    padding-top: 17px;
    position: relative;
  }

  .relacionados-products .slick-slider {
    position: unset;
  }

  .relacionados-products .slick-prev {
    right: 30px;
    top: 30px;
    display: block !important;
    left: unset;
  }

  .relacionados-products .slick-next {
    right: -10px;
    top: 30px;
    display: block !important;
  }

  .ediciones-products .slider-content {
    padding: 0 68px;
  }

  .related-products-wrapper {
    gap: 20px
  }

  .product-details-social-media {
    margin-top: 40px;
  }

  .product-details-tab-mob-tab .arrow4 {
    left: 50%;
  }

  .resenas-content .title {
    font-size: 14px;
  }

  .resenas-content .title span {
    color: #0282f9;
    padding-right: 5px;
  }

  .datos-content p {
    font-size: 14px;
  }

  .product_icon_wrapper .product_text {
    font-size: 10px;
    line-height: 13px;
  }

  .relacionados-products .img-wrapper,
  .ediciones-products .img-wrapper {
    height: calc(calc(100vw * 0.164) * 1.846);
  }
}

@media only screen and (max-width: 1100px) {
  .container.my-2 .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .listrht_img {
    width: 100%;
  }

}

@media only screen and (max-width: 1024px) {
	
  .navigation-w .nav>li>a {
    font-size: 9px;
  }

  .modal-content {
    width: 90%;
    border-radius: 0;
  }

  .date-w {
    width: 50px;
    height: 50px;
  }

  .date-w p {
    font-size: 18px !important;
    line-height: 18px;
  }

  .drop2 {
    width: 120px;
  }

  .drop3 {
    width: 120px;
  }

  .container-fluid.popup {
    padding: 40px 20px;
  }

  .pop-lft {
    width: 30%;
    margin-right: 2%;
  }

  .pop-rht {
    width: 68%;
  }

  .popup-btn {
    margin: 40px 0 0 0;
  }

  .wrap-pop-date {
    width: 160px;
    padding: 20px;
    background: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .popup-btn li a {
    padding: 7px 9px;
  }

  .drowdown-2 {
    float: left;
    margin-left: 20px;
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {

  .card-wrapper .img-wrapper {
    height: 529px;
  }

  .image_slider_actualidadevents .res1 {
    margin-bottom: 5px;
    height: 67px
  }

  .inner-wrap-prod.rowdata.noslider {
    margin: 0
  }

  .mer-filters-card.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .product .btn2.disabled,
  .product .btn2.reservar.disabled {
    width: 125px;
  }

  .banners .bannerText {
    font-size: 19px;
    line-height: 21px;
  }

  .ckeditor ul li {
    font-size: 12px;
  }

  .ckeditor h2 {
    font-size: 20px;
  }

  .ckeditor h3 {
    font-size: 16px;
  }

  .ckeditor h4 {
    font-size: 15px;
  }

  .catalogo label {
    margin-left: 10px;
  }

  .col_wrap[aria-expanded="true"] {
    border: 2px solid #0282f9;
  }

  .producttitlerow .tabmobiledropdown::after,
  .resultados .tabmobiledropdown::after {

    top: 3px;

  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 29px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 29px !important;
  }

  .form input[type="text"],
  .form input[type="password"] {
    height: 29px;
  }

  .is-sticky-menumobile+.personmenu-tab-mob.showmenu .personmenu-triangle-black.login {
    right: 64px;
  }

  .personmenu-triangle-black.login {
    right: 64px;
  }

  .favoritos .cont-rht-2 .row {
    margin-right: 0;
  }

  .cont-wrap.tab-pane.fade.show.active.publishline .row {
    margin-right: -10px;
  }

  .homemagzinesec .magzine-desc {
    height: 171px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
  }

  .eventModal .ant-modal-content {
    overflow-y: scroll;
  }

  .billingAdressSelectedText p {
    margin: 0
  }

  .raw-html-embed {
    font-size: 14px;
  }

  .imglft {
    height: auto;
    width: 100%;
  }

  /*.image_slider_home_letura .slick-slide > div {
    height: calc(100vw * 0.75);
  }*/
  .image_slider_home_letura .slick-slide>div .pos-slider {
    height: 100%;
  }

  .product .cont-rht .col-2 .img-wrapper {
    height: calc(calc(100vw * 0.421) * 1.562);
	display: flex;
    align-items: center;
  }

  .product.content .cont-rht .col-2 .img-wrapper {
    height: calc(calc(100vw * 0.225)*1.49);
    ;
  }

  .product.biblioteca .cont-rht .col-2 .img-wrapper {
    height: calc(calc(100vw * 0.421) * 1.562);
  }

  .editorial_wrap ul li {
    flex: 0 0 100%;
    margin-top: 30px;
  }

  .jumbotron.bgheadung {
    top: 275px;
    width: 280px !important;
  }

  .inner-wrap ul {
    padding: 0 0px;
  }

  .innerpage-2 {
    width: 100%;
    margin: 0 auto;
  }

  .header_wrap .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .cont-rht-3 li {
    width: 30%;
  }

  .modal-content {
    width: 90%;
  }

  .popup-btn {
    margin: 20px 0 0 0;
  }

  .img-pop-wrap {
    width: 50%;
  }

  .popup-btn li {
    float: left;
    margin: 22px 20px 0 0;
  }

  .popup-btn li a {
    padding: 7px 11px;
  }

  .container-fluid.popup {
    padding: 0px 20px;
  }

  .pop-lft {
    width: 100%;
  }

  .pop-rht {
    width: 100%;
  }

  .date-w {
    width: 40px;
    height: 40px;
    padding: 5px;
  }

  .date-w p {
    font-size: 16px !important;
  }

  .navi5 {
    margin: 20px 0 20px 0;
    float: none;
    padding: 0;
  }

  .lft-side {
    width: 44%;
    margin-left: 4.6%;
  }

  .drowdown2 {
    float: right;
    margin-left: 0px;
    width: 50%;
  }

  .drop3 {
    width: 95%;
    margin: 0 5% 0 0;
  }

  .drop3 option {
    width: 50%;
  }

  .drop2 {
    width: 95%;
    margin: 0 0 0 5%;
  }

  .cont-rht li:nth-child(odd) {
    margin: 40px 0 0 26px;
  }

  .header_wrap {
    padding: 0;
    margin: 0;
  }

  .nav-inner ul li {
    float: left;
    padding-left: 14px;
  }

  .navbar-expand-md .navbar-nav {
    padding: 0;
    margin: 0;
  }

  .major-nav {
    float: left;
    margin: 8px 0px 0px 0px;

  }

  .social3 ul li {
    padding: 0;
    margin: 0;
  }

  .social2 ul li {
    padding: 0;
    margin: 0;
  }

  .blackheader ul.drop li a {
    color: #fff !important;
  }

  .blackheader .b1,
  .blackheader .b2,
  .blackheader .b3 {
    border: 0;
  }

  .navbar-nav .nav-item:hover .arrownav {
    margin: 0px -65px;
  }

  .navbar-nav .nav-item:hover .arrownavcata {
    margin: 8px -17px;
  }

  .heading-cont-left-2 {
    width: 65%;
  }

  .header1 {
    background-position-y: -65px;
    height: 512px;
  }

  .socialico5 {
    font-size: 16px;
    margin-left: 8px;
  }

  .box5 {
    font-size: 16px !important;
    padding: 5px;
  }

  .cart-count {
    padding: 3px 8px;
    vertical-align: bottom;
    height: 27px;
  }

  .white-header .cart-count {
    height: 28px;
    padding: 2px 8px;
  }

  .col-sm-1.cubierta {
    width: 100px;
    max-width: 120px;
    flex: 0 0 100.333%;
  }

  .DC-death-metal-prime {
    width: 324px;
    margin-left: 30px;
  }

  .respos .row {
    justify-content: center;
  }

  .footer_sec .col-2.first,
  .footer_sec .col-1 {
    display: none;
  }

  .comics .col-sm-4,
  .comics .container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .col-6.dark-night {
    padding-left: 0px;
  }

  .image_slider_releases .slick-slide {
    width: 735px !important;
  }

  .catalogo label {
    margin-top: 50px;
  }

  .personmenu-triangle-black {
    right: 62px;
  }

  /*}
@media only screen and (max-width: 767px) {*/
  .actualidadproduct .image_slider_homeproducts .img-wrapper {
    height: calc(calc(100vw * 0.437) * 1.562);
    width: 100%;
  }

  .image_slider_longnews .slick-slide.slick-active {
    margin-left: 0px;
    margin-right: 0px;
  }

  .image_slider_longnews .slick-list {
    margin-left: 0px;
    margin-right: 0px;
  }

  .image_slider_longnews .slick-slide {
    padding-left: 0px;
    padding-right: 0px;
  }

  .image_slider_longnews .noticias_1 .img-wrapper {
    height: calc(100vw * 0.532);
  }

  .image_slider_homeproducts img {
    padding-right: 3px;
    padding-left: 1px;
  }

  .image_slider_homeproducts .img-wrapper {
    height: calc(calc(100vw * 0.321) * 1.53);
  }

  .container-fluid.header_wrap {
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .biblioteca-text {
    position: relative;
    top: 32%
  }

  .hr {
    margin-top: 40px;
    color: #000;
    background-color: #000;
  }

  .homepagelastsec img {
    z-index: -1;
    object-fit: cover;
    height: calc(100vw * 0.893);
    width: 100%;
    position: absolute;
  }

  .date {
    top: 2px;
    left: 2px;
  }

  .image_slider_homereleases img {
    height: 100%;
  }

  .catalogo .eccPublishingLine .wrapper {
    height: calc(calc(100vw * 0.434) * 0.690);
  }

  /*.cover-h{
    height: calc(100vw * 0.4968);
  }*/
  .catalogo {
    margin-top: 20px
  }

  .homepage_contest img {
    width: 100%
  }

  .cookie-text {
    font-size: 12px;
    line-height: 15px;
    margin-top: 0 !important;
  }

  .cookiemodalpopup {
    padding: 20px 30px;
  }

  .image_slider_homeproducts .slick-prev::before,
  .image_slider_homeproducts .slick-next::before {
    position: fixed;
    left: 7px;
    top: 8px;
  }

  .ant-modal.cookieModal {
    top: 100px;
  }

  .slick-prev,
  .slick-next {
    display: block !important;
  }

  .nav>li>a {
    padding: 10px 2px !important;
  }

  .align-tab li a {
    font-size: 14px;
  }

  .cont-wrap ul li {
    width: 50%;
  }

  .heading-wrap {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .inner-wrap li {
    float: left;
    width: 47%;
    margin-right: 2.7%;
  }

  .inner-wrap ul {
    padding: 0 0px;
  }

  .lft-titulo {
    float: left;
    width: 100%;
  }

  .rht-titulo {
    width: 100%;
  }

  .rht-titulo a {
    width: 100%;
  }

  .de_lectura ul li {
    padding: 0;
    float: left;
    width: 100%;
    margin: 30px 0 0 0;
  }

  .de_lectura ul li:nth-child(2n+2) {
    margin: 0;
  }

  .de_lectura ul li:nth-child(3n+3) {
    margin: 0;
  }

  .cont-rht-3 li {
    width: 42%;
    margin: 40px 0 0 17px;
  }

  .registrar {
    width: 100%;
    margin-top: 30px
  }

  .registrar .form.name_container {
    display: block
  }

  .registrar .form.name_container .form_name {
    width: 100%;
  }

  .registrar .form.name_container .form_surname {
    width: 100%;
  }

  .editorial_wrap ul li {
    float: left;
    width: 100%;
    margin: 40px 0 0 0;
  }

  .editorial_wrap ul li:nth-child(2n+2) {
    margin: 40px 0 0 0;
  }

  .drowdown-2 {
    float: left;
    margin-left: 0;
    width: 100%;
  }

  .drop-2 {
    width: 100%;
  }

  .heading-cont-left {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .cont_form {
    margin: 30px 0 0 0;
  }

  .cont_form li {
    float: left;
    width: 100%;
    margin-right: 20%;
    margin: 5% 0 0 0;
  }

  .ant-form-item-explain-error li {
    margin: 0 !important;
    font-size: 10px;
  }

  .prefill-btn {
    font-size: 10px;
  }

  .cont_form .ant-pagination li {
    float: none;
    width: auto;
    margin: 0 0 0 5px;
  }

  .heading_lft_cont {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  .heading_rht_cont {
    float: none;
    width: 100%;
  }

  .blue-wrap p,
  .cont_form li h3 {
    font-size: 10px !important;
  }

  .cont_form li p {
    font-size: 12px !important;
    margin-top: 0;
  }

  .tienes_links_myorder {
    text-align: left;
  }

  .btn2_cont {
    float: none;
  }

  .cont_form.address ul li {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .cont_form.address li p {
    display: grid;
  }

  .mleft-2b {
    margin-left: 0 !important;
  }

  .link_cont {
    font-size: 12px;
    float: none;
    text-align: left;
    display: inline-block;
  }

  .btn2_cont.add {
    width: 100%;
    margin-bottom: 20px;
  }

  .address .row .col-6:nth-child(2) {
    margin-top: 40px;
  }

  .table-form-2 tr td {
    padding: 5px;
    font-size: 9px;
  }

  .table-form-2 tr th {
    font-size: 10px;
    text-align: center;
  }

  .pedidos .link_cont {
    float: left;
  }

  .catalogo a {
    font-size: 22px;
  }

  .footer_sec .col-2-hidden {
    display: none;
  }

  .DC-death-metal-prime {
    /*margin: 110px 61px 0 0px;*/
    font-size: 32px;
    padding: 0px;
    width: 280px;
    line-height: 35px;
    margin-left: 0;
  }

  .form2 input[type="text"] {
    width: 100%;
  }

  .tshirt {
    width: 100%;
    float: none;
  }

  .dark-night {
    width: 100%;
    float: none;
  }

  .cont-w li:nth-child(even) {
    margin: 50px 0 0 0;
  }

  .cont-w li {
    width: 48%;
    margin-right: 4%;
  }

  .navi3 li {
    border: 0;
    margin: 10px 0px;
  }

  .navi3 li:hover {
    border: 0;
  }

  .navi3 li.active {
    border: 0;
    margin: 10px 0px;
  }

  .navi3 a {
    color: #000;
  }

  .navi3 .navbar-nav {
    width: 100px;
  }

  .navi3 .navbar-nav {
    width: 100px;
    position: relative;
    right: 0;
    top: 40px;
    z-index: 999;
    background: #fff;
    border: 1px solid #000;
    padding: 10px 10px;
  }

  .agenda-menu-postion {
    position: absolute;
    float: right;
    right: 0;
    top: 0;
  }

  .arrow4 {
    border: 0;
  }

  .navigation-center {
    float: right;
    width: 60%;
    margin: 0;
  }

  .heading-left {
    float: left;
    width: 40%;
  }

  .is-sticky-menumobile .home-menu .navbar.tab-mob {
    top: 100px;
  }

  .is-sticky-menumobile .common-menu .navbar.tab-mob {
    top: 69px;
  }

  .is-sticky-menumobile .common-menu .navbar.tab-mob .black-header-div {
    margin-top: -8px;
  }

  .is-sticky-menumobile .common-menu .navbar.tab-mob .search-triangle-black {
    top: -18px;
  }

  .logo {
    width: 79px;
    height: 31px;
    position: relative;
  }

  .container-fluid.header_wrap .container {
    max-width: 320px !important;
  }

  .black-header .header_wrap .container {
    margin-bottom: 15px;
  }

  .deco {
    background: #fff;
    border: 1px solid #000;
    position: absolute;
    right: 6px;
    width: 100%;
    top: 72px;
    padding: 0;
  }

  .box5 {
    font-size: 20px !important;
    padding: 5px 4px;
  }

  .header_wrap {
    padding: 0;
  }

  .small-nav {
    display: none;
  }

  .header1 {
    overflow: auto;
  }

  .social3 .navbar-nav {
    display: -webkit-inline-box;
  }

  .social1 ul li i {
    font-size: 12px;
  }

  .navbar.tab-mob .mainmenu {
    padding-top: 15px;
  }

  .home-menu .navbar.tab-mob .black-header-div {
    top: -35px;
  }

  .is-sticky-menumobile .home-menu .navbar.tab-mob .black-header-div {
    top: -40px;
  }

  .navbar.tab-mob li span,
  .navbar.tab-mob li a {
    font-size: 12px;
  }

  .sub-menu.show a {
    opacity: 0.8;
  }

  .navbar.tab-mob li {
    margin-left: 20px;
    width: auto;
    margin-right: 20px;
  }

  .navbar.tab-mob .small-nav-tab-mob li {
    width: auto;
    margin-right: 20px;
  }

  .navbar.tab-mob .search-triangle-black {
    left: 105px;
  }

  .white-header .navbar.tab-mob .search-triangle-black {
    left: 105px;
  }

  .is-sticky-menumobile .navbar.tab-mob .search-triangle-black {
    left: 113px;
  }

  .box5 {
    margin: 0 0 0 0px;
  }

  .actualidad_wrap_rht .shortnewscontent {
    max-width: 50%;
  }

  .header_wrap .social3 .navbar-nav li {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .white-header .header_wrap {
    border-bottom: 0;
  }

  .button.navbar-toggler {
    width: 19px;
  }

  .social2 {
    display: none;
  }

  .forchange {
    margin: 0;
  }

  ul.navbar-nav.deco {
    margin-right: 0px;
  }

  .deco-wrap ul.deco {
    width: 320px;
    right: -192px;
    top: 67px;
    background: #000;
  }

  .nav-inner ul.deco li {
    float: none;
    padding: 10px 15px;
    border-bottom: 1px solid #fff;
    margin-bottom: 0px;
    text-align: left;
    margin: 0 20px;
    width: 90%;
    position: relative;
  }

  .nav-item.nave-wrap {
    padding-top: 20px;
  }

  #collapsibleNavbar3 ul li a {
    color: #fff !important;
    font-size: 12px;
  }

  .black .navbar.tab-mob .navbar-toggler {
    top: -4px;
    left: 82px;
  }

  .navbar.tab-mob .navbar-toggler {
    top: 15px;
    left: 100px;
    padding: 0;
    position: fixed;
    /* -webkit-transform: translate(-10%, -10%);
    -moz-transform: translate(-20%, 0%);
    -o-transform: translate(-10%, -10%); */
  }

  .is-sticky-menumobile .home-menu .navbar.tab-mob .navbar-toggler {
    top: 18px;
    left: 110px;
    /* -webkit-transform: translate(-20%, -75%);
  -moz-transform: translate(-20%, -75%);
  -o-transform: translate(-10%, -10%); */
  }

  .home-menu .navbar.tab-mob .navbar-toggler {
    top: 15px;
    left: 102px;
    /* -webkit-transform: translate(-75%, -50%);
    -moz-transform: translate(-70%, -45%);
    -o-transform: translate(-10%, -10%); */
  }

  .is-sticky-menumobile .navbar.tab-mob .navbar-toggler {
    top: 18px;
    left: 110px;
    /* -webkit-transform: translate(-20%, -0%);
  -moz-transform: translate(-20%, -0%);
  -o-transform: translate(-10%, -10%); */
  }

  .navbar.tab-mob .sub-menu.show li {
    margin: 0 0px 0 40px
  }

  .search {
    margin-top: 100px;
  }

  .catalogo .img-wrapper {
    width: 200px;
    height: auto;
    margin: 0 auto;
  }

  .catalogo .img-wrapper img {
    width: 100%;
    height: 100%;
  }

  .small-nav.mob {
    display: inline;
    position: absolute;
    top: 346px;
    width: 320px;
    text-align: left;
    float: none;
    right: -192px;
    background: #000;
    z-index: 999;
    padding-bottom: 20px;
  }

  .navbar.tab-mob .small-nav-tab-mob ul {
    padding-left: 20px;
    padding-right: 0;
  }

  .navbar.tab-mob .editorialmenu li {
    margin-left: 0px;
  }

  .laEditorialMenu .laEditorialUl {
    right: 36px;
    background-color: #000;
  }

  .small-nav.mob ul {
    padding-left: 0;
    width: 320px;
  }

  .small-nav.mob li {
    float: none;
    border-bottom: 1px solid #fff;
    margin: 0 20px;
    padding: 10px 15px;
    width: 90%;
  }

  .small-nav.mob li a {
    color: #fff;
    font-size: 12px;
    font-weight: normal;
  }

  .cont-rht li:nth-child(odd) {
    margin-top: 60px;
  }

  .cont-rht li:nth-child(even) {
    margin-top: 60px;
  }

  .cont-rht-2 li:nth-child(odd) {
    margin-top: 60px;
  }

  .cont-rht-2 li:nth-child(even) {
    margin-top: 60px;
  }

  .cont-wrap-cover {
    width: 100%;
  }

  .filterwrap {
    width: 100%;
  }

  .A-la-venta-esta-sema1,
  .communicadostitle {
    font-size: 20px;
  }

  .bgcover {
    position: absolute;
    top: 0;
    height: 1500px;
    background: #000;
    width: 90%;
    display: none;
    padding: 60px 40px;
    transition-duration: 2s;
    transition-delay: 1s;
    opacity: 0.8;
    margin: 20px;
  }

  .btn1 {
    margin: 20px 0 0 0;
    clear: both;
    transition: all .5s linear;
  }

  .btn {
    margin: 20px 0 0 0;
    clear: both;
    transition: all .5s linear;
  }

  .lft-side {
    width: 100%;
    margin: 0;
  }

  .right-side1 {
    width: 100%;
    margin-left: 4.6%;
    background: red;
    min-height: 1118px;
  }

  .lftcontent {
    float: left;
    width: 60%;
  }

  .rhtcontent {
    float: right;
    width: 40%;
  }

  .drop1 {
    width: 130px;
  }

  .pagination {
    margin: 20px auto;
  }

  .right-side {
    width: 100%;
    float: none;
  }

  .innersec_images ul li {
    float: none;
    width: 100%;
  }

  .innersec_images1 ul li {
    float: none;
    width: 100%;
    margin: 0;
  }

  .links li {
    border: 4px solid #000;
    margin-right: 18px;
    padding: 5px 18px;
    float: left;
    width: auto;
    text-align: center;
    margin-top: 10px;
  }

  .cont-rht-2 li img {
    width: 135px;
    height: 211px;
  }

  .links li:hover {
    padding: 0px 0px;
    margin-right: 0;
  }

  .links li a {
    font-size: 14px;
  }

  .cont-rht-2 li {
    margin: 20px 10px 0 0px;
    width: 135px;
  }

  .favoritos .cont-rht-2 .col-2 {
    padding-right: 10px;
    flex: 50%;
    max-width: 50%;
  }

  .favoritos .cont-rht-2 .col-2:nth-child(5n) {
    padding-right: 10px;
  }

  .favoritos .cont-rht-2 h2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .favoritos .cont-rht-2 .col-2:nth-child(2n) {
    padding-right: 0;
  }

  i.close-2 {
    right: 15px;
  }

  .heading-cont-left-2 {
    width: 100%;
  }

  .cont-rht-2 h2 {
    font-size: 12px;
    line-height: 15px;
  }

  .favoritos .link_cont {
    float: left;
  }

  /* .header1 {
    background-position-y: 0px !important;
    height: 466px;
  } */
  .header1 {
    background-position-y: 0px !important;
    height: 100vh;
    overflow: visible;
  }

  .navbar-nav .nav-item:hover .arrownav,
  .navbar-nav .nav-item:hover .arrownavespac {
    margin: 0px -14px;
  }

  .A-la-venta-esta-sema,
  .communicadostitle {
    font-size: 20px;
  }

  h6,
  .h6 {
    font-size: 11px !important;
  }

  .image_slider_homenews .slick-slide.slick-active.slick-current {
    padding-right: 2px;
    padding-left: 5px;
  }

  .image_slider_homenews .slick-slide.slick-active {
    padding-right: 5px;
    padding-left: 0px;
  }

  .image_slider_homeproducts .slick-prev,
  .image_slider_homeproducts .slick-next {
    top: 50%;
  }

  .marg {
    margin-bottom: 0px;
  }

  .cont-rht-11 .slick-prev,
  .cont-rht-11 .slick-next {
    top: calc(50% - 25px);
  }

  .cont-rht-11 .slick-prev {
    left: -10px;
  }

  .cont-rht-11 .slick-next {
    right: -4px;
  }

  .col-sm-8.padmar,
  .col-sm-4.padmar {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-4.padmar {
    margin: 20px 0 0 0;
    padding: 0;
  }

  .homepagecontestsec .dark-night,
  .homepagecontestsec .tshirtdiv {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .catalogo li:nth-child(odd) .wrapper {
    padding-right: 2px;
  }

  .catalogo li {
    height: 100%;
    width: 50%;
    padding: 0 0 0px 0px !important;
    margin-right: 0px;
    margin-top: 2px;
  }

  .tshirtdiv,
  .tshirtdiv .tshirt {
    height: auto;
  }

  .tshirtdiv .content {
    padding: 60px 10px 0 10px;
  }

  .tshirtdiv .tshirt {
    padding: 30px 20px 20px 10px;
  }

  .tshirt img {
    width: 100%;
    height: 190px;
  }

  .connoticias {
    padding: 0px;
  }

  .comics .col-sm-4,
  .comics .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .homemagzinesec>div {
    width: 100%;
  }

  .homemagzinesec>div>div {
    width: 50%;
    object-fit: cover;
    float: left;
    ;
  }

  .homemagzinesec .text-block {
    padding-left: 20px
  }

  .homemagzinesec div img {
    width: 100%;
  }

  .revista .col-sm-8 {
    margin-top: 25px;
  }

  .revista {
    height: auto;
  }

  .right-sec {
    width: 50%;
    float: right;
  }

  .revista .col-sm-4,
  .revista .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home_news_description {
    -webkit-line-clamp: 2;
    font-size: 10px;
    line-height: 14px;
    min-height: 30px;

  }

  .image_slider_homenews .connoticias {
    margin-bottom: 20px;
  }

  .image_slider_homenews {
    margin-bottom: 0;
  }

  .homepage_news_slider_wrapper {
    margin-bottom: 0;
  }

  .image_slider_homenews .slick-prev {
    top: -41px;
    left: 83%;
  }

  .image_slider_homenews .slick-next {
    top: -41px;
    right: 10px;
  }

  .homepagelastsec h2,
  .homepagelastsec p,
  .respos h5 {
    margin: 0;
    padding: 0 0 10px 0;
  }

  .respos {
    display: none;
  }

  .hdtabdata {
    display: none;
  }

  .eventModal {
    /*width: 300px !important;*/
    position: relative;
  }

  .eventModal .mobdata {
    display: block;
  }

  .eventModal .mobdata .cta .col-6 {
    max-width: 135px;
    width: 135px;
  }

  .eventModal .mobdata .cta .col-9 {
    max-width: 181px;
    width: 181px;
  }

  .eventModal .mobdata .cta .col-6,
  .eventModal .mobdata .cta .col-9 {
    padding-right: 10px;
    padding-left: 0;
  }

  .eventModal .mobdata .cta {
    justify-content: flex-start;
  }

  .eventModal .mobdata .col-12 {
    padding-right: 0;
    padding-left: 0;
  }

  .eventModal .mobdata .eventDate {
    position: unset;
    background: #e2f1ff;
    color: #0282f9;
    width: 260px;
    height: 67px;
    padding: 11px 23px;
    margin-bottom: 20px;
    line-height: 18px;
  }

  .eventModal .ant-modal-content {
    padding: 20px !important;
    overflow-y: scroll;
  }

  .eventModal .mobdata .eventDate h5 {
    color: #0282f9 !important;
    font-size: 16px;
    font-weight: bold;
  }

  .eventModal .ant-modal-body {
    padding: 0px 10px !important;
  }

  .eventModal .mobdata .eventDetail {
    font-size: 14px;
    height: auto;
    margin-bottom: 20px;
  }

  .eventModal .mobdata .cta .col-6 {
    margin-bottom: 10px;
  }

  .eventModal .mobdata .cta .col-9 {
    margin-bottom: 20px;
  }

  .eventModal .mobdata .eventImg img {
    width: 100%;
  }

  .eventModal .mobdata .eventTitle {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 15px;
    line-height: 24px;
  }

  .eventModal .mobdata .eventCTA {
    padding: 3px 4px;
    font-size: 13px;
  }

  .image_slider_releases .slick-slide {
    width: 715px !important;
    height: 400px;
  }

  .actualidad_wrap_rht .row:first-child {
    float: none;
    width: auto;
    justify-content: space-between;
  }

  .actual-sec-1.my-50 {
    margin-top: 40px;
  }

  .actualidad_longnews_heading {
    font-size: 14px;
    min-height: 20px;
  }

  .actualidad_wrap_lft p,
  .actualidad_wrap_rht .heaact_lft p,
  .connoticias_1 p,
  .cont-rht-11 h2,
  .image_slider_actualidadvideo p {
    font-size: 10px;
  }

  .actualidad_longnews_ellipsis {
    -webkit-line-clamp: 3;
    height: 30px;
  }

  .actualidad_shortnews_heading {
    font-size: 12px;
    min-height: 15px;
    width: 90%;
  }

  .actualidad_shortnews_ellipsis {
    -webkit-line-clamp: 2;
    line-height: 14px;
    min-height: 16px;
    width: 90%;
    max-height: 30px;
  }

  .actualidad_wrap_rht .row {
    margin-bottom: 10px;
  }

  .image_slider_longnews .connoticias_1 p {
    -webkit-line-clamp: 2;
    font-size: 12px;
  }

  .image_slider_longnews .slick-prev,
  .image_slider_longnews .slick-next {
    top: calc(50% - 50px);
  }

  .image_slider_longnews .slick-prev {
    left: -5px;
  }

  .actualidad_wrap_rht {
    height: auto;
  }

  .image_slider_longnews .connoticias_1 h1 {
    margin-top: 10px;
    min-height: 33px;
  }

  .image_slider_longnews .slick-slide.slick-active.slick-current .connoticias_1 {
    margin-left: 0;
  }

  .image_slider_homeproducts .slick-slide.slick-active.slick-current p {
    margin-left: 0;
  }

  .image_slider_actualidadevents .connoticias1 {
    margin-top: 0;
  }

  .image_slider_actualidadevents .slick-prev {
    right: unset;
    top: calc(50% - 40px);
    left: -22px;
    background: top;
  }

  .image_slider_actualidadevents .slick-next {
    right: -22px;
    top: calc(50% - 40px);
    background: top;
  }

  .image_slider_actualidadvideo .slick-prev,
  .image_slider_actualidadvideo .slick-next {
    top: calc(50% - 25px);
  }

  .image_slider_actualidadvideo .slick-prev {
    left: -10px;
  }

  .image_slider_actualidadvideo .slick-next {
    right: -15px;
  }

  .actualidad_wrap_rht .row:first-child .col-6:first-child,
  .actualidad_wrap_rht .row:first-child .col-6:nth-child(2) {
    order: unset;
    margin-top: 0;
  }

  .actualidad_wrap_rht .row:first-child .col-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .actualidad_wrap_rht .heaact_rht {
    width: 100%;
  }

  .actual-sec-1 {
    margin-bottom: 5px;
  }

  .schedule {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .magzine {
    margin-top: 0px;
    margin-bottom: 0;
  }

  .image_slider_actualidadevents .connoticias1 h1 {
    font-size: 8px;
    line-height: 10px;
    -webkit-line-clamp: 1;
    min-height: 11px;
  }

  .image_slider_actualidadevents .slick-track {
    padding: 0;
  }

  .image_slider_actualidadevents .slick-slide.slick-active {
    margin-left: 2px;
  }

  .image_slider_homeproducts p,
  .actualidad_shortnews_ellipsis {
    -webkit-line-clamp: 2;
    font-size: 12px;
  }

  .image_slider_longnews {
    height: auto;
  }

  .cont-rht-11 .bibli-img {
    width: 135px;
    height: 211px;
  }

  .image_slider_actualidadevents .connoticias1 p {
    font-size: 10px;
    line-height: 10px;
    -webkit-line-clamp: 2;
    min-height: 20px;
    margin: 0px 0 15px;
  }

  .actualidad_content_list_ellipsis {
    font-size: 12px;
    -webkit-line-clamp: 2;
    max-height: 45px;
  }

  .actualidad_content_list_heading {
    font-size: 12px;
    margin-top: 0;
  }

  .image_slider_actualidadvideo {
    margin-top: 5px;
  }

  .actualidad_video_heading {
    min-height: 18px;
    margin: 11px 0 6px 0;
  }

  .line6.magzineline {
    border-top: 1px solid #000;
  }

  .image_slider_actualidadvideo {
    margin-bottom: 30px;
  }

  .main-row .wrapper:first-child {
    margin-bottom: 0px;
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide,
  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active.slick-current {
    padding-left: 0;
  }

  .actualidadproduct .image_slider_homeproducts .slick-slide.slick-active {
    padding-left: 10px;
  }

  .mag-line {
    border-top: 1px solid #000;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .actualidad_video_ellipsis {
    height: 30px;
  }

  .image_slider_actualidadvideo .img_Wrap {
    width: 100%;
  }

  .image_slider_actualidadvideo .img_Wrap img {
    height: 100%;
    width: 100%;
  }

  .image_slider_longnews .noticias_1 img {
    width: 100%;
    height: 100%;
  }

  .connoticias_1 h1,
  .image_slider_actualidadvideo h2 {
    font-size: 14px;
  }

  .actualidad_wrap_rht .row:nth-child(2),
  .actualidad_wrap_rht .row:nth-child(3) {
    order: unset;
    padding-left: 0px;
  }

  .actualidad_wrap_rht {
    margin-top: 20px;
  }

  .actualidad_wrap_rht .row {
    margin-bottom: 20px;
  }

  .image_slider_actualidadevents .noticias {
    margin-bottom: 0px;
  }

  .container-fluid.my-15 {
    height: auto;
  }

  .wrapper .imglft {
    float: none;
    margin-bottom: 0px;

  }

  .homepage_news_slider_wrapper .slick-current {
    margin-left: 0;
  }

  .image_slider_homeproducts .slick-track {
    padding: 0 3px;
  }

  .image_slider_home_letura .slick-track {
    padding: 0;
  }

  .magzine .listrht .col-6:first-child,
  .magzine .listrht .col-6:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .image_slider_actualidadevents .slick-slide div:first-child {
    display: flex !important;
    flex-direction: column;
  }

  .wrapper .listrht_img {
    height: 100%;
    width: calc(100vw * 0.337);

  }

  .listrht p {
    font-size: 12px;
  }


  .dark-night {
    border-right: 0;
  }

  .listrht {
    float: none;
    width: 100%;
  }

  .magzine .listrht {
    float: none;
    width: 100%;
    margin-top: 40px;
    width: calc(100vw * 0.871);
    height: calc(calc(100vw * 0.871) * 0.654);

  }

  .actualidadproduct {
    margin-top: 0 !important;
  }

  .image_slider_homeproducts {
    margin-top: 20px;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
    width: 100% !important;
  }

  .container.footer_sec {
    max-width: 320px !important;
    width: 320px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ant-modal.cookieModal {
    width: 320px !important;
    max-width: 320px !important;
  }

  .container.main-container.static,
  .container.line-container {
    max-width: 100% !important;
    width: 100% !important;
  }

  .container.main-container {
    max-width: 100% !important;
    width: 100% !important;
  }

  .line-container {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .revista {
    height: auto;
  }

  .revista .slick-prev,
  .revista .slick-next {
    top: -37px;
  }

  .revista .slick-next {
    right: -11px;
  }

  .image_slider_homeevents .connoticias1 p {
    -webkit-line-clamp: 4;
    min-height: 41px !important;
    line-height: 10px;
    font-size: 8px;
    margin: 10px 0 15px;
  }

  .image_slider_homeevents .slick-track {
    display: block !important;
  }

  .revista .col-sm-8 .line5 {
    margin-bottom: 15px;
  }

  .col-sm-8.tortues,
  .col-sm-4.pooerosas,
  .col-sm-4.justice,
  .col-sm-8.ricky {
    flex: 0 50%;
    max-width: 50%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .tortues,
  .pooerosas {
    border-bottom: 2px solid #fff;
  }

  .justice,
  .tortues {
    border-right: 2px solid #fff;
  }

  .search-resp-input {
    font-size: 12px;
    /*height: 31px;*/
  }

  .search-tab-mob .col-2 {
    display: none;
  }

  .search-triangle-black {
    right: 105px;
  }

  .search-resp-input:focus-visible {
    box-shadow: none;
  }
  .search-resp-input .ant-btn-icon-only{
	font-size: 10px;
  }

  .search-tab-mob .header-row {
    padding: 30px 10px;
  }

  .search-tab-mob .list-bar h6 {
    font-size: 10px !important;
  }

  .search-tab-mob .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-pagination.pagination {
    width: 100%;
    justify-content: center;
  }

  .search-tab-mob .header-row .row {
    justify-content: center;
  }

  .search-tab-mob .list-bar ul label {
    font-size: 12px;
    margin: 10px 0;
    color: #000;
  }

  .heading_rht_cont .row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .heading_rht_cont br {
    display: none;
  }

  .check-form label {
    display: none;
  }

  .check-form button {
    width: 200px;
    margin-bottom: 10px;
  }

  .mleft-2b {
    margin-left: 0;
  }

  .cont_form {
    margin: 20px 0 0 0;
  }

  .heading_rht_cont .A-la-venta-esta-sema1 {
    font-size: 20px;
  }

  .heading_rht_cont .A-la-venta-esta-sema1 span {
    display: none;
  }

  .form h4 {
    font-size: 10px;
  }

  .order-details-tr {
    pointer-events: auto;
    cursor: pointer;
  }

  .myspace .footerform {
    display: block !important;
  }

  .myspace .footerform .form2 h2,
  .myspace .footerform .form2 p,
  .myspace .footerform .form2 label,
  .myspace .footerform .form2 span {
    font-size: 10px;
  }

  .myspace .footerform .form2 button {
    font-size: 14px;
    font-weight: bold;
  }

  .myspace .container.footer_sec {
    margin-top: 40px;
  }

  .myspace .table-form.pedidos tr th:nth-child(3),
  .myspace .table-form.pedidos tr td:nth-child(3),
  .myspace .table-form.pedidos tr td:nth-child(6) {
    display: none;
  }

  .table-form tr th {
    font-size: 10px;
  }

  .banners.mb-50 {
    margin-bottom: 2px;
  }

  .image_slider_homereleases .slick-prev,
  .image_slider_homereleases .slick-next {
    top: calc(calc(calc(100vw * 0.875) * 0.532) / 2);
  }

  .image_slider_homereleases {
    width: 100%;
    margin-bottom: 0px;
  }

  .image_slider_homenews {
    margin-top: 20px;
  }

  .marg h3,
  .noticias h1 {
    font-size: 14px;
  }

  .marg p {
    font-size: 10px;
  }

  .homepagecontestsec .row {
    height: auto;
  }

  .pos-slider {
    background-position-y: 0px !important;
    margin-top: 0px !important;
  }

  .box--wrap.heading-container.lectura {
    display: none;
  }

  .pos-slider h2 {
    top: 50%;
    left: 65px;
    font-size: 18px;
  }

  .pos-slider a {
    top: 34%;
    left: 50px;
  }

  .pos-slider .reading-guide-text {
    display: block;
    position: absolute;
    top: 47%;
    left: 65px;
    color: #fff;
    font-size: 8px;
  }

  .image_slider_home_letura .slick-prev,
  .image_slider_home_letura .slick-next {
    top: 50%;
  }

  .p-3.mb-50 {
    margin-bottom: 0;
  }

  .image_slider_home_letura {
    margin-top: 0;
  }

  .homemagzinesec h2 {
    font-size: 14px;
    margin-top: 0;
  }

  .connoticias1 p {
    font-size: 10px;
    line-height: 14px;
    margin: 10px 0 20px;
  }

  .homemagzinesec div {
    font-size: 10px;
    line-height: 14px;
  }

  .homemagzinesec ul {
    margin: 0;
    padding-inline-start: 0;
  }

  .revista .col-sm-4 {
    order: 2;
    padding: 0;
  }

  .revista .col-sm-8 {
    order: 1;
    padding: 0;
  }

  .image_slider_homeevents .col-3 {
    padding-right: 0;
    padding-left: 0;
  }

  .connoticias1 .date p {
    width: 100%;
    font-size: 8px;
    margin: 0px;
  }

  .connoticias1 p {
    width: 90%;
  }

  .container.footer_sec {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 0;
    padding-right: 0;
    margin-top: 50px;
  }

  .form2 h2,
  .form2 p,
  .form2 label,
  .form2 span,
  .form2 button,
  .form2 input {
    font-size: 10px;
  }

  .footer_sec .col-12.form2 {
    padding-left: 0px;
  }

  .social1 ul li {
    font-size: 11px !important;
    padding: 1px 4px 2px 3px;
    margin: 0 0 0 10px;
    height: 21px;
    width: 21px;
  }

  .social1 ul li.box1:last-child {
    padding: 1px 2px 2px 2px;
  }

  .social1 ul li.box1:first-child {
    padding: 1px 4px 2px 4px;
  }

  .footer_sec .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer_sec .btn2 {
    width: 92px;
    height: 34px;
  }

  .footer_sec .svg-inline--fa.fa-w-16 {
    width: 11px;
    height: 13px;
  }

  .social1 {
    margin-top: 0;
    top: -271px;
    left: 208px;
  }

  .justice,
  .tortues,
  .pooerosas,
  .ricky {
    border-right: none;
    border-left: none;
  }

  .justice,
  .tortues,
  .pooerosas,
  .ricky {
    border-bottom: 2px solid #fff;
  }

  .cover-h img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container.main-container,
  .container.heading-container,
  .container.footer.line-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .container-fluid.black-border {
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 0px;
  }

  .line6.line-footer {
    margin: 40px 0 0;
  }

  .header_wrap .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .revista .row {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .revista .image_slider_homeevents .row {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .tortues {
    order: 1;
  }

  .pooerosas {
    order: 2;
  }

  .justice {
    order: 3;
  }

  .ricky {
    order: 4;
  }

  .agenda .res {
    width: 100%;
    height: calc(calc(100vw * 0.2875) * 0.728);
  }

  .date {
    width: 26px;
    height: 27px;
    padding: 4px 5px 0px;
    text-align: left;
  }

  .date h5 {
    font-size: 8px;
    margin-top: -3px;
    text-align: left;
  }

  .image_slider_homeevents .connoticias1 .date {
    width: 26px;
    height: 27px;
    padding: 4px 5px 0px;
    text-align: left;
  }

  .image_slider_homeevents .connoticias1 .date h5 {
    font-size: 8px;
    margin-top: -3px;
    text-align: left;
  }

  .personmenu-tab-mob .list-bar {
    margin: 20px;
  }

  .personmenu-tab-mob .col-12 {
    padding-right: 0;
    padding-left: 0;
  }

  .personmenu-tab-mob .list-bar ul a {
    font-size: 12px;
  }

  .tab-mob .list-bar ul li {
    padding-bottom: 10px;
  }

  .personmenu-tab-mob {
    top: 72px;
  }

  .favoritos .col-7,
  .favoritos .col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .favoritos .col-7 {
    margin-top: 0;
  }

  .favoritos .drowdown-2 label {
    font-size: 10px;
  }

  .favoritos .drowdown-2 select {
    font-size: 12px;
  }

  .container .row,
  .container-fluid .row {
    margin-right: 0;
    margin-left: 0;
  }

  .month-mob {
    display: block;
    position: relative;
  }

  .agenda {
    margin-bottom: 60px;
  }

  .agenda h2.A-la-venta-esta-sema.line7 {
    width: 212px;
    border-bottom: 0;
    padding-bottom: 0;
  }

  .month-mob::after {
    content: "\f0d7";
    color: #000;
    top: 6px;
    right: 10px;
    position: absolute;
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: bold;
    z-index: -1;
  }

  .navigation-center .navi5 {
    display: none;
  }

  .heading-left {
    float: none;
    width: 100%;
  }

  .navigation-center {
    float: none;
    width: 100%;
    margin-top: 10px;
  }

  h2.A-la-venta-esta-sema1.line7 {
    border: 0;
  }

  .serch-right {
    float: none;
    width: 100%;
  }

  .serch-right .drowdown2 {
    float: left;
    margin-left: 0;
    margin-right: 0px;
    margin-top: 20px;
    width: 50%;
  }

  .serch-right .drowdown2:first-child {
    padding-right: 10px;
  }

  .serch-right .drowdown2 select {
    width: 100%;
    height: 29px;
    padding: 2px 10px;
    text-align: left;
    font-size: 12px;
  }

  .cont-w .event-wrapper.col-2:nth-child(3n) {
    padding-right: 0;
  }

  .cont-w .event-wrapper.col-2:nth-child(4n) {
    padding-right: 2px;
  }

  .cont-w .event-wrapper.col-2 {
    max-width: 33%;
    padding-right: 2px;
    padding-left: 0;
    margin-bottom: 40px;
    width: 33%;
  }

  .cont-w .event-wrapper.col-2:nth-child(3n) {
    margin-right: 0;
  }

  .drowdown2 label {
    font-size: 10px;
  }

  .serch-right .drop2 {
    margin: 0 0 0 0%;
  }

  .img-w {
    height: 100px;
    width: 100%;
  }

  .event-wrapper .event-title,
  .connoticias1 .event-title {
    font-size: 10px;
    line-height: 10px;
    margin-top: 7px;
    height: 22px;
  }

  .event-wrapper .event-text,
  .connoticias1 .event-text {
    font-size: 10px;
    line-height: 10px;
    -webkit-line-clamp: 3;
    height: 32px;
    min-height: 32px;
  }

  .event-wrapper {
    margin-bottom: 40px;
    width: 92px;
  }

  .agenda {
    margin-bottom: 0px;
  }

  .month-mob .nav-item a {
    width: 100%;
    display: block;
  }

  .agenda .tabmobiledropdown .dropdown-menu.show {
    top: 3px !important;
  }

  .cont-w .col-2 {
    flex: 5 0 92px;
    max-width: 100px;
    width: 92px;
    margin-bottom: 15px !important;
  }

  .cont-w {
    margin-top: 30px;
  }

  .cont-w p {
    font-size: 10px !important;
    padding: 0 0px 0 0;
    line-height: 10px;
  }

  .date-w h6 {
    font-size: 8px !important;
  }

  .date-w {
    width: 26px;
    height: 26px;
    padding: 3px;
    top: 2px;
    left: 2px;
  }

  .line6 ul li a,
  .line6 p {
    font-size: 5px;
  }

  .container-fluid.footerform {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .homepage_contest {
    height: calc(100vw * 0.8);
    margin-top: 5px;
  }

  .tshirtdiv {
    border: 0 !important;
  }

  .col-sm-8.padmar,
  .col-6.tshirtdiv {
    padding-right: 0px;
    padding-left: 0px;
  }

  .my-50 {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .image_slider_longnews {
    margin-bottom: 10px;
  }

  .cont-rht-11 .col-12,
  .col-6.wrapper,
  .listrht .col-6 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .image_slider_releases {
    height: auto;
  }

  .innerpage p {
    font-size: 12px;
    line-height: 15px;
  }

  .subheading {
    font-size: 15px !important;
    line-height: 18px;
    margin: 20px 0 20px 0;
  }

  .historia .subheading {
    margin: 20px 0 20px;
    font-weight: 600;
  }

  .historia.links li:first-child {
    width: 48%;
    margin-right: 10px;
    padding: 3px 5px;
  }

  .historia.links li:nth-child(2) {
    width: 48%;
    padding: 3px 5px;
  }

  .historia.links li:last-child {
    width: 48%;
    margin-top: 10px;
    padding: 3px 5px;
  }

  .image-lightbox .carousel-control-prev {
    left: -35px;
    top: 32%;
  }

  .image-lightbox .carousel-control-next {
    right: -31px;
    top: 32%;
  }

  .innersec_images ul li img,
  .innersec_images1 ul li img {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .innersec_images li p {
    font-size: 10px;
    margin-bottom: 20px;
  }

  .innersec_images1 {
    margin: 20px auto 0;
  }

  .innersec_images3 {
    margin: 20px auto 0;
  }

  .innersec_images1 p {
    margin-bottom: 10px;
  }

  .mb-80 {
    margin-bottom: 40px;
  }

  .links.nomargin {
    margin: 20px 0 20px;
  }

  .innerpage.historia-last-wrapper {
    margin-top: 20px;
  }

  .historia.links {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .static i {
    font-size: 10px;
  }

  .innersec_images3 ul li img {
    width: 100%;
    height: 100%;
  }

  .innersec_images3 ul li {
    width: 33%;
  }

  .contacts {
    padding: 10px;
    margin: 10px 0 0 0;
  }

  .contacts p,
  .static i {
    font-size: 10px !important;
  }

  .header_wrap .major-nav {
    margin-left: 10px;
  }

  .distribucion .contacts p {
    font-size: 10px !important;
    line-height: 14px;
  }

  .distribucion.links li {
    width: 140px;
    border: 3px solid #000;
  }

  .distribucion .subheading br {
    display: block;
  }

  .navigation .navi1 .navbar-nav {
    display: block;
  }

  .navigation .navi1 .navbar-nav li {
    padding: 0px 10px 5px;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 48%;
    max-width: 50%;
    text-align: center;
    height: 35px;
    border: 2px solid #aaa;
  }

  .navigation .navi1 .navbar-nav li:nth-child(2n) {
    margin-right: 0px;
  }

  .navigation .navi1 .navbar-nav li.active {
    border: 2px solid #000;
  }

  .contacto p {
    font-size: 10px;
    line-height: 14px;
  }

  .form textarea {
    height: 70px;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .btn2.contacto {
    border: 3px solid #000;
    margin-bottom: 0px;
    width: 92px;
    margin-top: 4px;
  }

  .form .form-group label {
    margin-bottom: 0;
  }

  .navigation .navi1 .arrow.down {
    display: none;
  }

  .navigation .navi1 .navbar-nav a {
    font-size: 12px;
  }

  .navigation {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .ayuda h2,
  .faq-select-tab-mob {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .tabmobiledropdown .dropdown-menu.show {
    display: block;
    background: #000;
    color: #fff;
    top: 3px !important;
    left: -13px !important;
    width: 101% !important;
    border-radius: 0;
  }

  .ayuda .line4.line-mob {
    display: block;
    width: 100%;
    height: 3px;
  }

  .ayuda .line4 {
    display: none;
  }

  .col_wrap:first-child {
    margin: 20px 0 0 0;
  }

  .ayuda .faq-select-tab-mob {
    margin-top: 21px;
    width: 100%;
    margin-bottom: 21px;
  }

  .ayuda .col_wrap {
    padding: 0;
    line-height: 12px;
    margin: -3px 0 0 0;
  }

  .ayuda .col-9 {
    padding-right: 0;
    padding-left: 0;
  }

  .faq-select-tab-mob {
    position: relative;
  }

  .faq-select-tab-mob .btn2_cont {
    border: 3px solid #000 !important;
    height: 34px;
  }

  .faq-select-tab-mob::after {
    content: "\f0d7";
    color: #000;
    top: 6px;
    right: 10px;
    position: absolute;
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: bold;
    z-index: -1;
  }

  .card-title1 {
    font-size: 12px;
  }

  .col_wrap {
    padding: 10px;
  }

  .ayuda .svg-inline--fa.fa-w-10 {
    display: none;
  }

  .ayuda .col_wrap.collapsed {
    border: 2px solid #000;
    border-bottom: 0;
    color: #000;
    width: 100%;
    height: 50px;
  }

  .ayuda .col_wrap a {
    height: 100%;
    display: inline-block;
    padding: 12px 10px;
    font-size: 12px;
  }

  .ayuda .col_wrap.collapsed.last {
    border-bottom: 2px solid #000;
  }

  .tabmobiledropdown #tabmobiledropdownMenuButton {
    padding: 0;
    font-size: 14px !important;
  }

  .tabmobiledropdown {
    height: 34px !important;
    border-width: 3px !important;
  }

  .ayuda .col_wrap::after {
    display: none;
  }

  .ayuda .col_wrap.collapsed a {
    color: #000;
  }

  .ayuda .col_wrap.active a {
    color: #0282f9;
  }

  .ayuda .col_wrap.active {
    border: 2px solid #0282f9;
    height: 50px;
  }

  .ayuda .col_wrap a {
    color: #0282f9;
  }

  .ayuda .subheading {
    font-size: 12px !important;
    line-height: 15px;
    margin-bottom: 14px;
    margin-top: 15px;
  }

  .ayuda .innersec_images1 {
    display: none;
  }

  .card-body1 {
    margin-bottom: 30px;
  }

  .card-body1.wrap-colp {
    margin-bottom: 0;
  }

  .rights .inner-wrap .col-2 {
    max-width: 50%;
    width: 50%;
    margin-bottom: 20px;
    padding-right: 10px;

  }

  .rights .inner-wrap p {
    -webkit-line-clamp: 2;
  }

  .rights .inner-wrap .col-2:nth-child(5n) {
    padding-right: 10px;
  }

  .rights .inner-wrap .col-2:nth-child(2n) {
    padding-right: 0px;
  }

  .rights .ant-pagination.pagination {
    margin-bottom: 0px;
  }

  .rights .inner-wrap .col-2 .img-wrapper {
    height: calc(calc(100vw * .421) * 1.562);
  }

  .bibli .inner-wrap .col-2 .img-wrapper {
    height: 211px;
  }

  .bibli .inner-wrap .col-2 {
    width: 50%;
    padding-right: 10px;
    flex: 0 0 50%;
  }

  .bibli .inner-wrap .col-2:nth-child(2n) {
    padding-right: 0;
  }

  .bibli .inner-wrap .col-2:nth-child(5n) {
    padding-right: 10px;
  }

  .bibli .col-10 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .bibli .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .bibli .ant-pagination {
    margin-bottom: 0px;
  }

  .bibli .drowdown {
    float: none;
    margin-left: 0;
    margin-top: 10px;
  }

  .bibli .drowdown .ant-select {
    width: 100% !important;
  }

  .libreros-container {
    margin-top: 97px;
    margin-bottom: 43px;
  }

  .libreros-container .form input {
    margin-bottom: 18px !important;
  }

  .inner-wrap {
    margin: 40px 0 0 0;
  }

  .btn2_cont.confirm {
    width: 140px;
    display: block;
  }

  .btn2_cont.libreros {
    width: 140px;
    padding: 4px 10px !important;
    float: none;
    margin-bottom: 30px;
    height: 34px;
  }

  .libreros-container .link-form {
    float: none;
  }

  .libreros-container .link-form a {
    text-align: left;
    float: none;
    font-size: 12px;
  }

  .btn2_cont.confirm.mleft-2b {
    margin-top: 20px;
  }

  .cont_form_2 h5 {
    font-size: 15px;
    line-height: 18px;
  }

  .cont-wrap .row .col-2 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 0;
    margin-bottom: 30px;
  }


  ul.nav.nav-tabs.align-tab,
  .center-block {
    display: none;
  }

  .cat-select-tab-mob {
    display: block;
  }

  .catalogo .heading-wrap h2 {
    font-size: 12px !important;
    line-height: 15px;
    margin: 10px 0 20px;
  }

  .publishline.cont-wrap {
    margin-bottom: 7px;
  }

  .collection_text {
    font-size: 10px !important;
    line-height: 14px;
    width: 90%;
    min-height: 42px;
  }

  .publishline .subheading {
    font-size: 14px !important;
    line-height: 17px;
    margin-top: 16px !important;
  }

  .catTitle p {
    font-size: 10px;
    line-height: 14px;
    margin: 20px 0;
  }

  .cat-select-tab-mob::after {
    content: "\f0d7";
    color: #000;
    top: 6px;
    right: 10px;
    position: absolute;
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: bold;
  }

  .publishline .row p {
    font-size: 10px;
  }

  .producttitlerow .line4 {
    display: none;
  }

  .resultados .producttitlerow .line4 {
    display: block;
  }

  .search-bar-title {
    margin-top: 20px;
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 10px;
  }

  .resultados .tabmobiledropdown {
    height: 34px;
  }

  .resultados .producttitlerow .col-4 {
    display: none;
  }

  .resultados .producttitlerow {
    margin-bottom: 10px;
  }

  .producttitlerow .filter-btn {
    margin-top: 0px;
    height: 34px;
    padding: 0px 10px;
    width: 100%;
  }

  .filter-div-mob .search {
    margin-top: 0px;
  }

  .showsearcheddiv.showsearcheddiv {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .producttitlerow .col-3 {
    margin-bottom: 0;
  }

  .producttitlerow .col-5,
  .producttitlerow .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .producttitlerow .ant-select-selector {
    height: 29px !important;
  }

  .producttitlerow .col-4 .drowdown {
    float: none;
    margin-left: 0;
    margin-top: 20px;
  }

  .producttitlerow .col-4 .drowdown .ant-select-single .ant-select-selector::after {
    content: "\f0d7" !important;
    color: #000;
    font-family: "FontAwesome";
    visibility: visible;
    right: 20px;
    position: absolute;
  }

  .setting-filter {
    margin-right: 0;
  }

  .producttitlerow .col-4 .ant-select-arrow .anticon {
    display: none;
  }

  .producttitlerow .col-4 .drowdown select {
    height: 30px;
    padding: 0 10px;
    font-size: 12px;
    width: 100%;
  }

  ul.breadcrumb li,
  ul.breadcrumb li a {
    font-size: 10px;
  }

  .product .cont-rht .col-2 {
	width: 135px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0;
    padding-left: 10px;
    margin-top: 30px;
  }

  .product.content .cont-rht .col-2 {
    width: 135px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 0;
    margin-top: 30px;
  }

  .product.biblioteca .cont-rht .col-2 {
    width: 135px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 0;
    margin-top: 30px;
  }

  .product .cont-rht .col-2:nth-child(5n) {
    padding-right: 10px;
  }

  .producttitlerow {
    margin-bottom: 10px;
  }

  .product .cont-rht .col-2:nth-child(2n) {
    padding-right: 0px;
  }

  .producttitlerow .ant-select {
    width: 100% !important;
  }

  .producttitlerow label {
    font-size: 8px;
  }

  .product .btn2 {
    width: 130px;
    height: 34px;
    margin-top: 12px;
  }

  .producttitlerow {
    margin-bottom: 10px;
  }

  .prodEditions .inner-wrap-prod.noslider .col-2 {
    max-width: 135px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  .prodEditions .inner-wrap-prod.noslider .col-2:nth-child(2n) {
    padding-right: 0px;
  }

  .prodEditions.relacionados {
    margin-bottom: 0;
  }

  .producttitlerow {
    margin-bottom: 20px;
  }

  .col-9.product-div,
  .producttitlerow .col-5,
  .producttitlerow .col-4,
  .producttitlerow .col-3,
  .filter-div-mob {
    padding-right: 0;
    padding-left: 0;
  }

  .cont-rht h2 {
    font-size: 12px;
    line-height: 15px;
  }

  .drowdown label {
    font-size: 10px;
  }

  .filter-header-tab label {
    font-size: 10px;
  }

  .filter-header-tab {
    padding: 20px 10px;
    height: 70px;
  }

  .filter-header-tab a {
    display: block;
    width: 100%;
  }

  .filter-header-tab .btn2 {
    display: none;
  }

  .restaurar-btn {
    top: unset;
    width: 100%;
    position: unset;
    margin-bottom: 40px;
  }

  .restaurar-btn .btn2 {
    float: none;
  }

  .restaurar-btn .btn2,
  .filterwrap1,
  .search input,
  .filterwrap .navi {
    width: 100%;
  }

  .search input {
    font-size: 15px;
  }

  .connoticias1 {
    margin-right: 3px;
  }

  .container-fluid.header_wrap {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 18px;
    padding-bottom: 16px;
    max-width: 100% !important;
    width: 100% !important;
  }

  .tiendas .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tiendas .cover,
  .google-map,
  .btn1.bg {
    display: none;
  }

  .cover.mt-60 {
    margin-top: 0;
  }

  .rhtcontent .btn1 {
    width: 95px;
    clear: none;
    margin-top: 0;
  }

  .line4 {
    height: 3px;
  }

  .rhtcontent .btn1.mapa {
    width: 123px;
    display: block;
    margin-left: 10px;
  }

  .tiendas .rhtcontent {
    margin-top: 20px;
  }

  .tiendas .titleformob .A-la-venta-esta-sema,
  .tiendas .titleformob .line4,
  .address-list,
  .address-timing {
    display: block;
  }

  .innersec_images4 ul li {
    float: none;
    width: 100%;
    margin-top: 20px;
  }

  .subs-email input {
    width: 280px;
    height: 29px;
  }

  .innersec_images4 {
    margin: 0px auto 0px;
  }

  .tiendas .hidetxt {
    display: none;
  }

  .address-timing p b {
    font-weight: normal;
  }

  .arrow3 {
    display: none;
  }

  .address-list {
    width: 100% !important;
  }

  .tiendas .cover.showadress {
    display: block !important;
  }

  .tiendas .lftcontent,
  .tiendas .rhtcontent {
    float: none;
    width: 100%;
  }

  .tiendas .subheading {
    width: 100%;
    font-weight: bold;
    line-height: 15px;
    font-size: 12px !important;
  }

  .cover.mt-50 {
    margin-top: 20px;
  }

  .tiendas .cover h2,
  .tiendas .cover .line4 {
    display: none;
  }

  .tiendas .col-6,
  .tiendas .col-12 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .main-container.innerpage.tiendas {
    padding-right: 20px !important;
    margin-top: 37px;
    margin-bottom: 40px;
  }

  .tiendas b {
    font-weight: normal;
  }

  .tiendas .rhtcontent {
    margin-top: 30px;
  }

  .innersec_images4 ul li {
    float: none;
    width: 100%;
    border-right: 0;
    margin-bottom: 20px;
  }

  .btn1.mapa {
    display: inline;
    margin-left: 20px;
  }

  .google-map.show-google-map {
    display: block;
    height: 500px !important;
  }

  .productos .col-4,
  .productos .col-8 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }

  .social-icon-prod-tab,
  .productos .small-bread {
    display: none;
  }

  .tienda_lft ul li {
    font-size: 12px;
  }

  .price h4 {
    font-size: 20px;
  }

  .price button {
    font-size: 14px;
    border: 3px solid #000;
  }

  .productos .tienda_lft {
    margin: 20px 0 15px;
  }

  .content_ten p,
  .content_ten div {
    font-size: 10px;
  }

  .card-wrapper .title {
    height: 40px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .card-wrapper label {
    margin-bottom: 0;
  }

  .productos .prod_img-div .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .productos .prod_img-div .col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .price button {
    width: 140px;
    padding: 5px 20px;
  }

  .producttitlerow.productlist {
    margin-top: 10px !important;
  }

  .category_desc {
    font-size: 10px;
    line-height: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .producttitlerow.productlist .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
  }

  .producttitlerow .ant-select {
    width: 100% !important;
  }

  .mt-60.prodEditions {
    margin-top: 37px;
  }

  .productos .slick-prev {
    left: -15px;
  }

  .productos .slick-next {
    right: -15px;
  }

  .ant-picker-panel-container .ant-picker-panels {
    display: block !important;
  }

  .ant-picker-panel-container {
    text-align: center;
  }

  .btn-white {
    display: block;
  }

  .homepagelastsec {
    margin-top: 26px;
    height: calc(100vw * 0.893);
  }

  .mt-50 {
    margin-top: 30px;
  }

  .table-form tr td {
    padding: 10px 5px;
    font-size: 9px;
  }

  .hideth {
    display: none;
  }

  .cont-rht-2 h2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .pedidos .dudas {
    float: none;
    margin-top: 40px;
  }

  .table-form tr th:last-child {
    display: none;
  }

  .mt-100 {
    margin-top: 0;
  }

  .addressskeleton .ant-skeleton-element {
    margin-bottom: 15px;
  }

  .row.addressskeleton .col-6:nth-child(2) {
    margin-top: 40px;
  }

  button.btn2_cont_btn.active.mleft-2 {
    width: 135px;
    padding: 5px 0px;
    margin: 3px 0 0 0;
    height: 34px;
    font-size: 10px;
  }

  .btn2_cont_btn {
    width: 135px;
    padding: 5px 0px;
    margin-right: 5px;
    font-size: 10px;
    height: 34px;
  }

  .addressskeleton .ant-skeleton-input,
  .orderskeleton .orderskeletoninput {
    width: 250px !important;
  }

  .addressskeleton .addressskeletonbtn span {
    width: 120px;
  }

  .orderskeleton .orderskeletonbtn {
    display: none;
  }

  .favouriteskeleton .col-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .img404,

  .page404 p,
  .page-thanks p {
    font-size: 12px;
  }

  .page404 p {
    font-size: 10px;
  }

  .btn404 {
    margin-top: 10px;
  }

  .number_tag h2 {
    height: 20px;
    width: 20px;
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    border: 3px solid #000;
  }

  .number_tag span,
  .number_tag p {
    font-size: 6px;
    margin-bottom: 5px;
  }

  hr.line7 {
    left: 20px;
    top: 7px;
  }

  .active_4 .number_tag .line7 {
    border: 1px solid #000;
  }

  .nav-2-wrapper li:last-child {
    width: 22px;
  }

  .cart-step table {
    display: table;
  }

  .cart-step table td {
    display: table-row;
    border: 0;
    float: left;
    padding: 5px;
    text-align: left;
    font-size: 12px;
    display: inherit;
  }

  .cart-step table tr {
    border: 1px solid #000;
    position: relative;
    /*display: inherit;*/
  }

  .cart-step table th:not(:first-child),
  .table-form-3.avail-products tr:last-child,
  .table-form-3.reserveproducts tr:last-child,
  .table-right {
    display: none;
  }

  .table-form-3 p {
    font-size: 12px;
    font-weight: bold;
    width: 70%;
    margin-bottom: 5px;
  }

  .table-form-3 tr td:nth-child(2) p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .info-select,
  .laterproducts {
    margin-top: 30px;
  }

  /*.table-form-3 tr:not(:first-child):before {
    font-family: "Material Icons";
    content: "clear";
    color: #000;
    width: 24px;
    height: 20px;
    opacity: 1;
    float: right;
    margin-top: -2px;
    padding: 0;
    font-size: 21px;
  }*/
  .cart-step table tr td:not(:first-child) {
    width: 68%;
  }

  .selected-add {
    margin-top: 0;
  }

  .no-courier {
    margin-bottom: 20px;
  }

  .table-form-3 tr td:nth-child(2) {
    float: left;
  }

  .table-form-3 tr td:last-child {
    width: 40%;
    /*margin-left: 80px;*/
  }

  .table-form-3 span,
  .table-form-3 tr td:nth-child(3) br {
    display: none;
  }

  .table-form-3 img.cross {
    display: none;
  }

  input.field_1 {
    border: 1px solid #000;
    width: 29px;
    height: 29px;
    border-radius: 0;
  }

  .quan_arrow_back {
    border: 1px solid #aaa;
    vertical-align: top;
    padding-right: 5px;
    width: 29px;
    height: 29px;
    color: #aaa;
  }

  .quan_arrow_frwd {
    border: 1px solid #aaa;
    vertical-align: top;
    padding-left: 5px;
    width: 29px;
    height: 29px;
    color: #aaa;
  }

  .table-form-3 tr th {
    font-size: 10px;
  }

  .cart-step .rht-cont {
    width: 100%;
    margin-top: 30px;
  }

  .lft-cont br {
    display: none;
  }

  .cart-step .line4 {
    display: block;
    margin-bottom: 0px;
    height: 3px;
  }

  .laterprod-blue-div {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 37px;
    padding: 20px 10px;
    height: auto;
  }

  .laterprod-blue-div p {
    width: 100%;
  }

  .textare-step4 p,
  .step4-2 p,
  .textare-step4 h2,
  .checkbox-list ul,
  .textare-step4 textarea {
    font-size: 10px;
  }

  .arrow_down {
    right: 6px;
    bottom: -4px;
    padding: 3px;
    border-width: 0 2px 2px 0;
  }

  .precio-total {
    display: inline;
  }

  .number_tag span {
    left: -60px;
  }

  .total.hide {
    display: initial !important;
    font-size: 12px;
  }

  .active_4 .number_tag h2 {
    border: 3px solid #000;
  }

  .number_tag h2 {
    border: 2px solid #000;
  }

  .step-3 .col_wrap_2.collapsed {
    padding: 10px 15px;
  }

  .step-3 .col_wrap_3.collapsed {
    height: auto;
    padding: 10px 15px;
  }

  .step-3 .col_wrap_2 {
    height: auto;
    padding: 10px 15px;
  }

  .step-3 .col_wrap_3 {
    height: 50px;
    padding: 10px 15px;
  }

  .step-4 .col_wrap_2.collapsed {
    height: 60px;
    padding: 10px 15px;
  }

  .step-4 .col_wrap_2 {
    height: 55px;
    padding: 10px 15px;
  }

  .col_wrap_2 a,
  .col_wrap_3 a {
    font-size: 20px;
  }

  .card-title1 span {
    margin: 6px 30px 0 0;
    float: right;
  }

  .card-title1 span.span-hide {
    display: initial;
  }

  .check-step-2 img {
    left: -45px;
    top: -20px;
  }

  .col_wrap_2.edit,
  .col_wrap_3.edit {
    border-bottom: 0;
  }

  .col_wrap_2.edit.collapsed,
  .col_wrap_3.edit.collapsed {
    border-bottom: 4px solid #0282f9;
  }

  .shop-add span,
  .ship-method span {
    display: flex;
  }

  .card-body1.datafilled.show {
    border-left: 4px solid #0282f9;
    border-right: 4px solid #0282f9;
    border-bottom: 4px solid #0282f9;
    border-top: 0;
  }

  .card-body1.wrap-colp {
    border-top: 0;
    border-left: 4px solid #000;
    border-right: 4px solid #000;
    border-bottom: 4px solid #000;
  }

  .shop-add p {
    font-size: 10px;
  }

  .col_wrap_2 .card-title1 span {
    display: none;
  }

  .col_wrap_2.collapsed .card-title1 span {
    display: block;
    text-align: left;
    float: none;
    margin-bottom: 5px;
  }

  .delivery-accordion {
    font-size: 10px;
  }

  .checkbox-list .checkmark {
    width: 13px;
    height: 13px;
  }

  .checkbox-list .custom_checkbox_wrapper {
    padding-left: 20px;
  }

  .checkbox-list .custom_checkbox_wrapper {
    width: 80%;
  }

  .shop-add .blue-div {
    height: 62px;
  }

  .datafilled.edit.col_wrap_3.collapsed {
    border-bottom: 4px solid #0282f9 !important;
  }

  .datafilled.edit.col_wrap_2,
  .datafilled.edit.col_wrap_3,
  .col_wrap_2,
  .col_wrap_3 {
    border-bottom: 0;
  }

  .collapsed.col_wrap_2.edit,
  .collapsed.col_wrap_3.edit {
    border-bottom: 4px solid #0282f9 !important;
  }

  .blue-div p {
    margin-left: 0;
  }

  .collapsed.col_wrap_2,
  .collapsed.col_wrap_3 {
    border-bottom: 4px solid #000 !important;
  }

  .selected-add,
  .ship-method {
    margin-left: 37px;
  }

  .no-courier {
    margin-left: 37px;
  }

  .sel-btn {
    float: none;
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20px;
  }

  .shop-add p,
  .ship-add p,
  .ship-method p,
  .postal-add p,
  .sel-add p,
  .postal-sel-add p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0px;
  }

  .add-radio {
    margin-top: -2px;
    height: 27px;
    width: 27px;
  }

  .ship-add p:not(:first-child),
  .ship-method p:not(:first-child),
  .postal-add p:not(:first-child),
  .sel-billing-add p:not(:first-child) {
    margin-top: 0px;
  }

  .ship-method p,
  .selected-add p,
  .no-courier p {
    font-size: 14px;
    line-height: 16px;
  }

  .postal-sel-add {
    margin-bottom: 0;
    margin-left: 37px;
  }

  .blue-div p {
    font-size: 10px !important;
  }

  .blue-div {
    padding: 12px 15px;
  }

  .checkbox-list {
    width: 100%;
  }

  .checkbox-list .custom_checkbox_wrapper,
  .pink-div-layer,
  .note p {
    font-size: 10px;
    line-height: 14px;
  }

  .note {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .step-5 .form-group label {
    font-size: 10px;
  }

  .pink-div-layer {
    margin-top: -7px;
  }

  .step-4 .note p,
  .step-4 .form-group label {
    font-size: 10px;
  }

  .step-4 .form-group {
    margin-top: 15px;
  }

  .step-4 .card-body1 {
    margin-bottom: 0px;
  }

  .tohidetd {
    display: initial;
  }

  .step-1 .table-form-3 td:nth-child(4) {
    display: none;
  }

  .textare-step4 textarea {
    width: 100%;
  }

  .edit-res::before {
    content: "\f00c";
    color: #0282f9;
    top: 0px;
    left: -32px;
    position: absolute;
    font-family: "FontAwesome";
    font-size: 18px;
    font-weight: normal;
    background-color: #fff;
    border: 3px solid #0282f9;
    padding: 0 2px;
  }

  .step-4 .edit-res::before {
    top: -30px;
  }

  .sel-billing-add p.direccion {
    font-size: 14px;
    line-height: 10px;
  }

  .sel-billing-add p,
  .checkforinvoice {
    font-size: 14px;
    line-height: 16px;
  }

  .add-radio.billing-choice {
    margin-top: -2px;
  }

  .edit-res .check-5,
  .edit-res .check-step-2 {
    display: none;
  }

  .showonTab {
    display: none;
  }

  .info-select {
    background-color: #e2f1ff;
    padding: 10px;
  }

  .info-select h2 {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
    color: #0282f9;
  }

  .info-select [type="radio"]:checked+label,
  .info-select [type="radio"]:not(:checked)+label {
    font-size: 10px;
    color: #0282f9;
    line-height: 14px;
    margin-bottom: 0;
    padding-left: 20px;
    word-spacing: 2px;
    width: 100%;
  }

  .info-select br {
    display: none;
  }

  .info-select form {
    line-height: 14px;
  }

  .info-select [type="radio"]:checked+label::before,
  .info-select [type="radio"]:not(:checked)+label::before {
    width: 13px;
    height: 13px;
    border: 1px solid #0282f9;
  }

  .info-select [type="radio"]:checked+label::after,
  .info-select [type="radio"]:not(:checked)+label::after {
    width: 7px;
    height: 7px;
    background: #0282f9;
  }

  .info-select img {
    display: none;
  }

  .info-select p {
    font-size: 10px;
    color: #0282f9;
    line-height: 14px;
    margin-top: 10px;
  }

  .Rectangle {
    padding: 10px;
  }

  .cart-step {
    margin-bottom: 40px;
  }

  .mar-wrap-lft {
    width: 202px !important;
    margin-right: 19px;
  }

  .form-control-2 {
    width: 70%;
  }

  .coupon-desc {
    left: 104px;
    top: -29px;
    padding: 20px 30px;
  }

  .dudas {
    display: none;
  }

  .blue-triangle {
    top: 40px;
    left: 240px;
  }

  .coupon-error {
    padding: 8px 10px;
    width: 100%;
    height: 34px;
  }

  .coupon-error {
    display: block;
  }

  .Rectangle-4 {
    margin-top: 1px;
  }

  .blue-div,
  .color-div p,
  .color-div h2,
  .color-div h3 {
    font-size: 10px;
  }

  .blue-div br {
    display: initial !important;
  }

  .address-btn {
    float: right;
    width: 208px;
    height: 34px;
    padding: 3px 20px !important;
    margin-bottom: 0;
  }

  .wrap-colp {
    padding-top: 0px;
    padding: 15px;
  }

  .cart-count {
    width: 17px;
    height: 17px;
    position: absolute;
    border: 0;
    background-color: #e2f1ff;
    padding: 0px;
    text-align: center;
    top: -10px;
    right: -10px;
    font-size: 10px;
    line-height: 18px;
  }

  .cart-count.showCart {
    display: block;
    color: #0282f9;
  }

  .white-header .cart-count.showCart {
    display: block;
    color: #fff;
  }

  .is-sticky-home .showCart {
    width: 17px;
    height: 17px;
    border: 0;
    padding: 0px;
  }

  .nav-2-wrapper li {
    max-width: 20%;
    width: 19%;
  }

  .search-tab-mob {
    top: 26px;
  }

  .revista .line5 {
    margin-bottom: 18px;
  }

  .white-header .cart-count {
    top: -10px;
    background-color: #000;
    border: 0;
    right: -10px;
    height: 17px;
    width: 17px;
    padding: 0;
  }

  .image_slider_homeevents .connoticias1 .date p {
    font-size: 8px;
  }

  .header_wrap .social3 .navbar-nav li:last-child {
    position: relative;
    margin-right: 0;
  }

  .productimg {
    width: 39px;
  }

  .table-form-order tr th,
  .table-form-order tr td {
    font-size: 9px;
    padding: 3px;
  }

  .subtotal p,
  .table-form-order p {
    font-size: 9px;
  }

  .table-form-order tr td:nth-child(2) {
    width: 145px;
  }

  .order-details-add p {
    font-size: 12px !important;
  }

  .table-form-order .subtotal p,
  .table-form-order td p {
    font-size: 9px;
  }

  .table-form-order {
    margin-bottom: 20px;
  }

  .order-details-add h3 {
    margin-bottom: 20px;
  }

  .btn2_cont.detail {
    margin-top: 40px;
    width: 187px;
    height: 34px;
  }

  .detail {
    margin: 30px 0;
  }

  .productos .header_img {
    width: 100%;
  }

  .orderdetailimg {
    width: 40px;
    height: 58px;
  }

  .image_slider_homereleases .img-wrapper {
    width: 100%;
    height: calc(calc(100vw * 0.875) * 0.532);
    padding: 0 1px;
  }

  .image_slider_homenews .connoticias h1 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    min-height: 51px;
    line-height: 17px;
    margin-bottom: 6px;
  }

  .image_slider_homenews .connoticias p {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    height: 28px;
    line-height: 14px;
  }

  .innerpage-2 .subheading p {
    font-size: 15px;
    line-height: 18px;
  }

  .pdf-btn {
    margin-bottom: 20px;
    font-size: 14px;
    float: none;
    width: 100%;
  }

  .innerpage-2 p,
  .communicados li {
    font-size: 12px;
    line-height: 15px;
  }

  .homereleases .img-wrapper {
    height: 149px;
  }

  .homereleases .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 1px solid #ccc;
  }

  .homereleases .connoticias p {
    font-size: 14px;
    line-height: 17px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .btn2_cont.login {
    width: 140px;
  }

  .btn2_cont.register {
    width: 140px;
  }

  .btn2_cont.espacio,
  .btn2_cont.luego {
    width: 140px;
    display: block;
    margin-bottom: 15px;
  }

  .goo-face-rht .facebook,
  .goo-face-rht .google,
  .goo_face_wrap .facebook,
  .goo_face_wrap .google {
    width: 135px;
    height: 35px;
    padding: 3px 0;
  }

  .btn2_cont.registerbtn {
    width: 140px;
  }

  .register-text {
    font-size: 10px !important;
  }

  .goo-face-rht .facebook {
    margin-right: 10px;
  }

  .goo-face-lft {
    float: none;
    width: 100%;
    border-top: 1px solid #000;
  }

  .goo-face-rht {
    float: none;
    width: 100%;
    border-top: 1px solid #000;
    margin-top: 30px;
  }

  .goo-face-rht .cont_form_2 h3 {
    margin: 15px 0;
  }

  .goo-face-rht br {
    display: none;
  }

  .form .ant-form-item .ant-select .ant-select-selector {
    font-size: 12px;
    margin-bottom: 0;
  }

  .form input[type="text"],
  .form input[type="password"] {
    margin-bottom: 18px;
    font-size: 12px
  }

  #addAddress .ant-select {
    margin-bottom: 8px;
  }

  .myspace .cont_form u {
    margin-top: 10px;
    width: 100%;
    display: block;
    font-size: 12px;
    text-align: right;
  }

  .cont_form_2 h3 {
    font-size: 10px;
  }

  .error-msg {
    font-size: 10px;
    font-weight: bold;
  }

  .mer-filters-card h6 {
    font-size: 14px !important;
  }

  .card-wrapper .title {
    font-size: 12px;
  }

  .card-wrapper span {
    margin: 0 0px 0 0;
  }

  .homepage_contest_content {
    width: 150px;
    left: 20px;
  }

  .homepage_contest_content h3 {
    font-size: 20px;
  }

  .bannerText p {
    font-size: 10px
  }

  .bannerText h3 {
    font-size: 20px;
  }

  .homepage_contest_content p {
    font-size: 10px;
  }

  .line6.line-footer {
    padding: 11px 0 30px;
  }

  .novedades {
    font-weight: 400;
  }

  .image-lightbox .modal-dialog {
    padding-left: 0;
    padding-right: 0;
    margin: 0 !important;
  }

  .image-lightbox .carousel-control-prev::before,
  .image-lightbox .carousel-control-next::before {
    background: #000;
    color: #fff;
    display: none;
  }

  .image-lightbox .carousel-inner p {
    font-size: 10px;
  }

  .editorial_wrap ul li {
    margin: 20px 0 0 0;
  }

  .editorial_wrap h3 {
    margin: 20px 0 11px 0;
    font-size: 10px;
  }

  .editorial_wrap ul li:nth-child(2n+2) {
    margin: 30px 0 0 0;
  }

  .editorial_wrap ul li:nth-child(3) p,
  .editorial_wrap ul li:nth-child(4) p {
    margin: 0 0 0px;
  }

  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 15px;
  }

  .ant-form-item-control-input-content {
    font-size: 10px !important;
  }

  .ant-btn-primary.btn2_cont,
  .btn2_cont.right {
    width: 140px;
    height: 34px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 4px 15px !important;
  }

  .subs-email input {
    width: 280px;
    height: 29px;
  }

  .image-lightbox .carousel-control-prev::before,
  .image-lightbox .carousel-control-next::before {
    width: 33px;
  }

  .personmenu-tab-mob.showmenu {
    top: 61px;
    position: fixed;
  }

  .container.main-container.search-outer-container {
    position: fixed;
  }

  .sticky-search .search-outer-container .search-tab-mob {
    top: 95px;
  }

  

  .comic_specifics b,
  .comic_specifics a {
    font-size: 14px !important;
  }

  .price button {
    margin-bottom: 30px;
  }

  .product-details-social-media {
    display: none;
  }

  .content_ten_tab {
    border: 0;
  }

  .product_details .title,
  .product_details .author {
    height: 10px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .product-details-options-content ul li a {
    font-size: 10px;
    line-height: 14px;
  }

  .product-details-options-content ul li {
    padding: 5px 0;
  }

  .product_details_intro,
  .notab-datos-content p {
    font-size: 14px;
  }

  .related-products-wrapper {
    display: block;
  }

  .product-details-tab-mob-tab .header-title {
    font-size: 14px;
  }

  .ediciones-products {
    width: 100%;
    height: 431px;
    padding-bottom: 0px;
  }

  .ediciones-products h2,
  .relacionados-products h2 {
    font-size: 20px;
  }

  .ediciones-products .slider-content {
    padding: 0 68px 30px;
  }

  .relacionados-products {
    width: 100%;
    padding-top: 40px;
    position: unset;
  }

  .relacionados-products .slick-slider {
    position: relative;
  }

  .relacionados-products .slick-prev {
    right: unset;
    top: 30%;
    display: block !important;
    left: -20px;
  }

  .relacionados-products .slick-next {
    right: -22px;
    top: 30%;
    display: block !important;
  }

  .product-details-tab-mob-tab .autores-content .title,
  .product-details-tab-mob-tab .autores-content .description {
    font-size: 14px;
  }

  .product-details-tab-mob-tab .contenidos-content .title {
    font-size: 14px;
  }

  .resenas-content .title,
  .resenas-content .sub-title {
    font-size: 14px;
  }

  .product-details-options-content .noticia-list ul li a {
    font-size: 14px;
  }

  .product-details-options-content .agenda-content .title a {
    font-size: 14px;
  }

  .content_ten_tab {
    display: none;
  }

  .mt-60.prodEditions {
    margin-top: 0;
  }

  .product-details-tab-mob-tab {
    margin-bottom: 40px;
  }

  .product-details-tab-mob-tab .arrow4 {
    border: solid black;
    border-width: 0 4px 4px 0;
  }

  .datos-content p {
    font-size: 14px;
  }

  .relacionados-products .img-wrapper,
  .ediciones-products .img-wrapper {
    height: 209px;
  }

}

@media only screen and (max-width:640px) {
  .card-wrapper .img-wrapper {
    height: 455px;
  }
}

@media only screen and (max-width:540px) {
  .card-wrapper .img-wrapper {
    height: 355px;
  }

  .img-pop-wrap {
    width: 100%;
  }

  .wrap-pop-date {
    width: 80%;
    padding: 9px;
    background: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .search-tab-mob .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  .search-resp-input {
    width: 100%;
  }

  .search-wrapper .search-tab-mob .col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width:500px) {
  .line6 ul li {
    float: left;
    margin-right: 6px;
  }

  .search-tab-mob .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .image_slider_homenews .slick-prev {
    top: -41px;
    left: 76%;
  }
}

@media only screen and (max-width:480px) {
  .card-wrapper .img-wrapper {
    height: 320px;
  }

  .btn2_cont.danger.ml-2.xxl {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width:430px) {
  .card-wrapper .img-wrapper {
    height: 270px;
  }
}

@media only screen and (max-width: 375px) {
  .card-wrapper .img-wrapper {
    height: 230px;
  }

  .cont-rht li:nth-child(odd) {
    margin: 60px 0 0 0px;
  }

  .cont-rht li:nth-child(even) {
    margin: 60px 0 0 22px;
  }

  .img-pop-wrap {
    width: 100%;
  }

  .wrap-pop-date {
    width: 80%;
    padding: 9px;
    background: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .ant-btn-primary.btn2_cont,
  .btn2_cont.right {
    width: 110px;

  }
}

@media only screen and (max-width: 360px) {
  .banners .bannerText {
    font-size: 18px;
    line-height: 20px;
  }

  .cont-rht li:nth-child(odd) {
    margin: 50px 0 0 0px;
  }

  .cont-rht li:nth-child(even) {
    margin: 50px 0 0 10px;
  }

  .cont-rht-2 li:nth-child(odd) {
    margin: 50px 0 0 0px;
  }

  .cont-rht-2 li:nth-child(even) {
    margin: 50px 0 0 10px;
  }

}

@media only screen and (max-width: 320px) {
  .cont-rht li:nth-child(odd) {
    margin: 60px 0 0 0px;
    width: 120px;
  }

  .cont-rht li:nth-child(even) {
    margin: 60px 0 0 10px;
    width: 120px;
  }

  .cont-rht li img {
    width: 120px;
  }

  .drop1 {
    width: 100px;
  }
}