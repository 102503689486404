@font-face {
    font-family: 'DunbarLow-Bold';
    src: url('../public/assets/fonts/DunbarLow-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DunbarLow-Medium';
    src: url('../public/assets/fonts/DunbarLow-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DunbarLow-Regular';
    src: url('../public/assets/fonts/DunbarLow-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DunbarLow-Light';
    src: url('../public/assets/fonts/DunbarLow-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../public/assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../public/assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.landing-header-bg {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%),
        url(/assets/junti-ito/Fondo_Coleccionable_Junji_Ito.jpg);
    background-position: center bottom;
    height: auto;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    border-bottom: solid 1px #ce1823;
    padding-bottom: 150px;
}

.landing-mobile-bg {
    display: none;
}

.center-img {
    object-fit: contain;
    object-position: top center;
    top: 200px !important;
}

.upper-image-content {
    padding-top: 150px;
}
.upper-image{margin: 0 12%;}
.upper-image img {
    width: 100%;
    height: 100%;
  }
.header-left-content .img{
    height: 305px;
    width: 215px;   
}
.header-sec {
   display: flex;
   justify-content: space-between;
    margin-right: 10%;
}
.header-title{
    width: 400px;
}
.header-title img{
    width: 100%;
}
.is-sticky .header-title{display: none;}
.header-left {
    width: 600px;
    height: 600px;
    background-image: url(/assets/junti-ito/web-manchon.svg);
    position: relative;
    margin-top: -76px;
    background-size: cover;

}
.header-left-content {
    position: absolute;
    top: 40%;
    left: 30%;
}

.header-left .heading {
    font-family: DunbarLow-Light !important;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    color: #fffce5;
    margin-bottom: 0;
    text-transform: uppercase;
}

.header-left .title {
    font-family: DunbarLow-Bold !important;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #fffce5;
    text-transform: uppercase;
    width: 70%;
}

.header-left .price {
    font-family: Poppins-Regular !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    margin-bottom: 20px;
}

.header-left .img {
    border: 2px solid #cb1520;
}

.header-right h1 {
    font-family: DunbarLow-Bold !important;
    font-size: 44px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    margin-bottom: 25px;
}

.header-right h2 {
    font-family: DunbarLow-Regular !important;
    font-size: 24px;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    margin-bottom: 20px;
}

.header-right {
    width: 750px;
    margin-top: 100px;
    padding-left: 50px;
}

.header-right h3 {
    font-family: Poppins-Light !important;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    height: fit-content;
    padding-bottom: 20px;
}

.header-right .details {
    margin-top: 15px;
}

.header-right .details a {
    font-family: Poppins-Light !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    text-decoration: underline;
}

.header-right .cta {
    display: flex;
    gap: 75px;
    margin-top: 30px;
}

.header-right .cta-left,
.header-right .cta-right {
    width: 297px;
    height: 48px;
    background-color: #cb1520;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-right .cta a {
    font-family: DunbarLow-Bold !important;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fffce5;
}

.landing-header {
    position: absolute;
    top: 27px;
    width: 100%;
}

.landing-header .header-content {
    display: flex;
    justify-content: space-between;
    padding: 0 37px;
}

.landing-header .logo {
    width: 95px;
    height: 128px;
    position: relative;
}

.landing-header .menu ul li {
    font-family: DunbarLow-Bold;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: right;
    color: #fffce5;
    position: relative;
    cursor: pointer;
}

.is-sticky {
    position: fixed;
    top: 27px;
    background-color: transparent;
    transition: transform 0.4s ease 0s;
    z-index: 400;
    width: 100%;
}

.landing-header .menu ul li.active:after {
    content: "";
    background-color: #cb1520;
    width: 10.4px;
    height: 10.4px;
    position: absolute;
    top: 5px;
    border-radius: 50%;
    right: -20px;
}

.primeras-sec {
    position: relative;
    border-bottom: solid 1px #ce1823;
}

.primeras-sec .background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/assets/junti-ito/web-back-entregas.jpg);
    height: 880px;
    background-position: center bottom -300px;
    background-size: cover;
}

.primeras-sec .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.primeras-sec .content .heading {
    font-size: 24px;
    font-family: DunbarLow-Bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    color: #cb1520;
    margin-top: 61px;
}

.primeras-sec .content .columns {
    padding: 40px 80px 0;
    display: flex;
    gap: 60px;
}

.primeras-sec .content .columns .primeras-card {
    width: 100%;
    height: auto;
}

.primeras-sec .content .columns .primeras-card h5 {
    font-family: DunbarLow-Light !important;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    color: #fffce5;
    width: 80%;
    text-transform: uppercase;
}

.primeras-sec .content .columns .primeras-card .img {
    border: solid 0.9px #fffce5;
    margin-bottom: 15px;
}


.primeras-sec .content .columns .primeras-card h2, .primeras-sec .content .columns .primeras-card h2 a{
    font-family: DunbarLow-Bold !important;    
    color: #fffce5;
    text-transform: uppercase;
}

.primeras-sec .content .columns .primeras-card h3 {
    font-family: DunbarLow-Light !important;
    font-size: 26px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #fffce5;
}

.primeras-sec .content .cta {
    width: 300px;
    height: 48px;
    background-color: #cb1520;
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.primeras-sec .content .cta a {
    font-family: DunbarLow-Bold !important;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fffce5;
}

.primeras-sec-extended {
    /* background-image: url(/assets/junti-ito/primeras-extended-bg.jpeg); */
    height: fit-content;
    background-position: center top;
    padding: 75px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background: #000;
    
}

.primeras-sec-extended .row {
    gap: 40px 0px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.primeras-sec-extended .row .col-md-3 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-bottom: solid 1px #ce1823;
    padding-bottom: 20px;
}
.primeras-sec-extended .row .col-md-3:first-child{ padding-left: 80px !important;}
.primeras-sec-extended .row .col-md-3:nth-child(4n){
    padding-right: 80px !important;
}
.primeras-sec-extended .row .col-md-3:nth-child(4n+1){
    padding-left: 80px !important;
}
.primeras-sec-extended .heading {
    font-family: DunbarLow-Light !important;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    color: #fffce5;
    text-transform: uppercase;
    margin-bottom: 0;
}

.primeras-sec-extended .title, .primeras-sec-extended .title a{
    font-family: DunbarLow-Bold !important;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #fffce5;
    width: 80%;
}

.primeras-sec-extended .entrega-date {
    font-family: Poppins-Light !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
}

.subscribete-sec {
    padding: 80px 80px 40px;
    background-color: #000;
    height: auto;
    display: flex;
    border-bottom: solid 1px #ce1823;
}

.subscribete-sec .subscribete-left h1 {
    font-family: DunbarLow-Bold !important;
    font-size: 44px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    width: 60%;
    text-transform: uppercase;
}

.subscribete-sec .subscribete-left h1 br{display: none;}

.subscribete-sec .subscribete-left h1 br:first-child{display: block;}

.subscribete-sec .subscribete-left .red-text {
    color: #cb1520;
}

.subscribete-sec .subscribete-left,
.subscribete-sec .subscribete-right {
    width: 50%;
}

.subscribete-sec .subscribete-left h3 {
    font-family: DunbarLow-Regular !important;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 1px;
    color: #fffce5;
    text-transform: uppercase;
    margin-top: 25px;
    width: 95%;
}

.subscribete-sec .subscribete-left .cta {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 30px;
}

.subscribete-sec .subscribete-left .cta a {
    font-family: DunbarLow-Bold !important;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fffce5;
    width: 300px;
    height: 48px;
    background-color: #cb1520;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribete-sec .subscribete-left .info {
    margin-top: 35px;
}

.subscribete-sec .subscribete-left .info p {
    font-family: Poppins-Light !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    margin-bottom: 0;
}

.subscribete-sec .subscribete-right {
    padding-left: 60px;
}

.subscribete-sec .subscribete-right .poster {
    width: 100%;
}

.subscribete-sec .subscribete-right .poster-info {
    padding: 20px 0 38px;
}

.subscribete-sec .subscribete-right .poster-info p {
    font-family: Poppins-Light !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
}

.subscribete-sec .subscribete-right .poster-info h4 {
    font-family: DunbarLow-Bold !important;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #fffce5;
}

.subscribete-sec .promoFinalizada {
    color: black;
    padding: 20px;
    background-color: white;
    width: 300px;
    font-size: 26px;
    font-weight: bold;
}
.author-sec {
    background-image: url(/assets/junti-ito/web-back-junji_ito.jpg);
    height: 750px;
    background-position: center top;
    background-size: cover;
    position: relative;
    border-bottom: solid 1px #ce1823;
}

.author-sec .content {
    position: absolute;
    left: 800px;
    top: 240px;
}
.author-sec .content br{display: none;}

.author-sec .content h1 {
    font-family: DunbarLow-Bold !important;
    font-size: 100px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #fffce5;
    text-transform: uppercase;
}

.author-sec .content .description {
    font-family: Poppins-Light !important;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    width: 600px;
    margin-top: 40px;
}

.subscribe-sec {
    padding: 80px 0px;
    background: #000;
    height: 450px;
    border-bottom: solid 1px #ce1823;
}

.subscribe-sec h2 {
    font-family: DunbarLow-Bold !important;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    color: #cb1520;
}

.subscribe-sec .subs-input {
    margin-top: 35px;
    text-align: center;
}

.subscribe-sec .subs-input input {
    width: 500px;
    height: 48px;
    border: solid 1px #fffce5;
    background: #000;
    font-family: DunbarLow-Bold !important;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffce5;
    padding-left: 25px;
}

.subscribe-sec .checkbox {
    margin-left: 50px;
    width: 500px;
    margin: 30px auto 0;
}

.subscribe-sec .checkbox span {
    font-family: Poppins-Light !important;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.45;
    letter-spacing: normal;
    color: #fffce5;
}
.subscribe-sec .checkbox .input{display: flex;}
.checkbox-card {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.subscribe-sec .cta {
    display: flex;
    justify-content: center;
    margin-top: 47px;
}

.subscribe-sec .cta a {
    font-family: DunbarLow-Bold !important;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fffce5;
    width: 240px;
    height: 48px;
    background-color: #cb1520;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-junji-ito {
    padding: 20px 80px;
    display: flex;
    background: #000;
    justify-content: space-between;
}

.footer-junji-ito .footer-left ul {
    padding-left: 0;
    display: flex;
    gap: 15px;
    margin-bottom: 0;
}

.footer-junji-ito .footer-left ul li , .footer-junji-ito .footer-left ul li a{
    font-family: DunbarLow-Regular !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #fffce5;
}

.footer-junji-ito .footer-right ul {
    padding-left: 0;
    display: flex;
    gap: 15px;
    margin-bottom: 0;
}

.footer-junji-ito .footer-right ul li i {
    color: #fff !important;
}

.btn-radio {
    position: relative;
    margin-bottom:30px;
}

.btn-radio input {
    position: absolute;
    z-index: 9;
    opacity: 0;
}

.btn-radio [type="radio"]:checked+label::before,
.btn-radio [type="radio"]:not(:checked)+label::before {
    border: 1px solid #FFF;
    background: #cb1520;
    top: 5px
}

.btn-radio [type="radio"]:checked+label::after,
.btn-radio [type="radio"]:not(:checked)+label::after {
    background: #FFF;
}
.landing-subs-modal{width: 584px !important; }
.landing-subs-modal .ant-modal-content{ background-color: #cb1520; padding: 65px 80px;}
.landing-subs-modal .ant-modal-body {padding: 0 !important;}
.landing-subs-wrapper h2
{
    font-family: DunbarLow-Bold !important;
  font-size: 44px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fffce5;
  margin-bottom: 33px;
}
.landing-subs-wrapper .btn-radio label{
    font-family: Poppins-Regular !important;
    font-size: 20px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fffce5 !important;
}
.landing-subs-wrapper .cta{
    width: 400px;
    height: 48px;
    border: solid 3px #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.landing-subs-wrapper .cta a
{
    font-family: DunbarLow-Bold !important;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fffce5;
}
.landing-subs-wrapper .anticon-close
{
    display: none;
}
.checkbox-card .input{
position: relative;
}
.styled-checkbox {
    position: absolute; 
    opacity: 0;
    z-index: 2;
    top: 5px;
    left: 5px;      
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: #000;
      border: solid 1px #fffce5;
    }
  
    
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
  
    &:checked + label:before {
      background: #000;
    }
        
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: #fffce5;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }



@media only screen and (min-width: 1920px) {

    .upper-image {
        margin: 0 10%;
      }
      .header-left-content {left: 31%;}
    .landing {
       
        background: #000;
    }
    .landing > * {
        margin: 0 auto;
        width: 1900px !important;
        max-width: 1900px !important;
    }
    .header-right {
        width: 1100px;
    }
    .is-sticky {
        width: 1900px !important;
    }

    .primeras-sec .background {
        height: 1000px;
    }

    .subscribete-sec {
        height: auto;
    }

    .author-sec {
        height: 900px;
        background-position: top -100px left -150px;
    }
    .author-sec .content {top: 200px;}
    .subscribete-sec .subscribete-left h3 {width: 80%;}
}
@media only screen and (min-width: 1441px) and (max-width: 1919px)
{
    .primeras-sec .background {height: 1000px;}
    .author-sec {background-position: left -100px top -50px;}
    .header-right {
        width: 900px;
    }
    .header-sec {margin-right: 12%;}
    
}
@media only screen and (max-width: 1440px) {
    .author-sec .content {
        left: 50%;
    }
    .author-sec .content .description {
        width: 75%;
    }
    .header-left-content {left: 28%;}
    .header-left {
        margin-top: -78px;
        margin-left: 0px;
        width: 500px;
        height: 500px;
    }
    .header-right {padding-left: 30px;}
    .header-right h1 {font-size: 40px;}
    .header-right h2 {font-size: 20px;}
    .header-right h3 {font-size: 18px;}
    .header-right .cta a {font-size: 16px;}
    .header-right {
        width: 665px;
    }
    .primeras-sec .background {height: 930px;}
}
@media only screen and (max-width: 1350px) 
{
      .header-right {
        right: 145px;
      }
      
}
@media only screen and (max-width: 1300px) 
{
    .header-left {
        width: 450px;
        height: 450px;
    }
    .upper-image {
        margin: 0px 10%;
      }
      .header-left {
        margin-top: -58px;
      }
}

@media only screen and (max-width: 1250px) {

    .header-left-content {
        top: 35%;
        left: 24%;
    }

    .header-left {
        width: 400px;
        height: 400px;
    }

}
@media only screen and (max-width: 1200px)
{
    .header-left {margin-left: 0;}
    .header-left-content {
        top: 35%;
    }
    .header-left .title {width: 80%;}

}
@media only screen and (max-width: 1140px) {
    .header-sec .header-left{
        display: none;
    }
    .header-sec{
        justify-content: center;
    }
    .header-right {
        margin-top: 30px;
    }

    .landing-header-bg {
        display: block;
        justify-content: center;
        align-items: center;
        height: 1300px;
    }
    .upper-image {
        height: 500px;
    }

}

@media only screen and (max-width: 1100px) {
    .author-sec .content {
        left: 38%;
        top: 50%;
    }

    .author-sec .content .description {
        width: 90%;
    }

    .author-sec {
        background-position: left -150px top;
    }

    .subscribete-sec {
        padding: 40px;
    }

    .primeras-sec .content .columns {
        padding: 40px 40px 0;
    }

    .primeras-sec .content .columns {
        gap: 30px;
    }

}

@media only screen and (max-width: 1000px) {
    .author-sec {
        background-position: left top -110px;
        height: 1100px;
    }

    .author-sec .content {
        left: 20%;
        top: 52%;
    }


}

@media only screen and (max-width: 900px) {

}

@media only screen and (max-width: 768px) {
    .landing-header-bg {
        display: none;
    }

    .landing-mobile-bg {
        display: block;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%), url(/assets/junti-ito/Fondo_Coleccionable_Junji_Ito.jpg);
        background-position: bottom center;
        height: auto;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        border-bottom: solid 1px #ce1823;
        padding: 20px;
    }

    .mobile-logo {
        width: 35px;
        height: 48px;
        position: relative;
    }

    .top-bar {
        display: flex;
        gap: 30px;
    }

    .landing-mobile-bg .header-right-content h1 {
        font-family: DunbarLow-Bold !important;
        font-size: 25px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        color: #fffce5;
    }

    .landing-mobile-bg .header-right-content h2 {
        font-family: DunbarLow-Regular !important;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: 1.08px;
        color: #fffce5;
        margin-bottom: 20px;
        width: 80%;
    }
    .landing-mobile-bg .header-right-content h2 br{
        margin-bottom: 10px;
    }
    .landing-mobile-bg .header-right-content h3 {
        font-family: Poppins-Light !important;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #fffce5;
        margin-bottom: 20px;

    }

    .cta .cta-left,
    .cta .cta-right {
        width: 100%;
        height: 37px;
        background-color: #cb1520;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cta .cta-right {
        margin-bottom: 10px;
    }

    .cta .cta-left a,
    .cta .cta-right a {
        font-family: DunbarLow-Bold !important;
        font-size: 13px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fffce5;
    }

    .details a {
        font-family: Poppins-Light !important;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fffce5 !important;
        text-decoration: underline !important;

    }

    .details {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .header-mobile-title {
        width: 80%;
    }

    .header-mobile-title img {
        width: 100%;
    }

    .overlay-img {
        width: 100%;
        padding: 20px 0;
    }

    .overlay-img img {
        width: 100%;
        object-fit: cover;
    }

    .is-sticky {
        display: none;
    }

    .upper-image img {
        display: block;
        object-fit: cover;
        width: 100%;
    }

    .landing-header .logo {
        width: 36px;
        height: 48px;
    }

    .menu {
        display: none;
    }

    .primeras-sec .content .columns {
        padding: 25px 20px 0;
        flex-wrap: wrap;
        gap: 20px;
    }

    .header-right {
        width: 100%;
        padding: 0 40px;
        margin-top: 50px;
    }

    .header-right h1 {
        font-size: 25px;
    }

    .primeras-sec .content .columns .primeras-card .img {
        height: auto;
    }

    .header-right-content {
        position: relative;
    }

    .header-right .cta {
        display: block;
    }

    .header-right h2 {
        font-size: 13px;
    }

    .header-right h3 {
        font-size: 12px;
        height: auto;
    }

    .primeras-sec {
        border-bottom: solid 1px #ce1823;
    }

    .primeras-sec .content .columns .primeras-card {
        height: auto;
        flex: 45%;
        margin-bottom: 15px;
    }

    .primeras-sec .content .heading {
        font-size: 17px;
        margin-top: 32px;
        margin-bottom: 0;
    }

    .primeras-sec .content .columns .primeras-card h2,
    .primeras-sec .content .columns .primeras-card h3,
    .primeras-sec .content .cta a {
        font-size: 13px;
    }
    .primeras-sec .content .columns .primeras-card h2{margin-bottom: 2px;}
    .primeras-sec .content .columns .primeras-card h5 {
        font-size: 8px;
    }

    .primeras-sec .content .cta {
        margin-top: 5px;
        width: 230px;
        height: 37px;
    }

    .primeras-sec .background {
        height: 1380px;
    }

    .primeras-sec-extended {
        padding: 37px 0px;
    }

    .primeras-sec-extended .col-md-3 {
        width: 50%;
        flex: 0 0 50%;
    }

    .primeras-sec-extended .heading,
    .primeras-sec-extended .entrega-date {
        font-size: 10px;
    }
    .primeras-sec-extended .entrega-date{
        margin-bottom: 15px;
    }
    .primeras-sec-extended .row {
        gap: 20px 0px;
        margin-right: 0;
        margin-left: 0;
    }
    .primeras-sec-extended .row .col-md-3:nth-child(2n+1){
        padding-left: 20px !important;
    }
    .primeras-sec-extended .title {
        font-size: 12px;
        margin-bottom: 2px;
    }
    .primeras-sec-extended .row .col-md-3
    {
        border-bottom: solid 1px #ce1823;
    }
    .subscribete-sec {
        display: block;
        padding: 25px 0px;
        height: auto;
        border-bottom: solid 1px #ce1823;
    }

    .subscribete-sec .subscribete-left {
        width: 100%;
        padding: 0 40px;
    }

    .subscribete-sec .subscribete-right {
        width: 100%;
        padding: 0 20px;
        margin-top: 30px;
    }

    .subscribete-sec .subscribete-left h1 {
        font-size: 25px;
        width: 100%;
    }
    .subscribete-sec .subscribete-left h1 br{display: block;}
    .subscribete-sec .subscribete-left h3 {
        font-size: 13px;
    }

    .subscribete-sec .subscribete-left .cta {
        margin-top: 20px;
    }

    .subscribete-sec .subscribete-left .cta a {
        width: 240px;
        height: 36px;
        font-size: 13px;
    }

    .subscribete-sec .subscribete-left .info p {
        font-size: 12px;
    }

    .subscribete-sec .subscribete-left .info {
        margin-top: 20px;
    }

    .subscribete-sec .subscribete-left .info br {
        display: none;
    }

    .subscribete-sec .subscribete-left .info p:nth-child(2) {
        margin-bottom: 15px;
    }

    .subscribete-sec .subscribete-right .poster-info h4 {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .subscribete-sec .subscribete-right .poster-info p {
        font-size: 10px;
    }

    .subscribete-sec .subscribete-right .poster-info {
        padding-bottom: 22px;
    }

    .author-sec {
        background-position: left top -100px;
        height: 850px;
    }

    .author-sec .content {
        left: 0;
        width: 100%;
        top: 65%;
        padding: 0;
    }
    .author-sec .content .author-name{padding-left: 50px; padding-right: 75px; width: 100%;}
    .author-sec .content .author-name img{width: 100%;}
    .author-sec .content br{display: block;}
    .author-sec .content h1 {
        font-size: 30px;
    }

    .author-sec .content .description {
        font-size: 12px;
        width: 100%;
        padding: 0 40px;
    }

    .subscribe-sec {
        padding: 40px;
        height: auto;
    }

    .subscribe-sec .subs-input input {
        width: 100%;
        height: 37px;
        font-size: 13px;
        padding-left: 17px;
    }

    .subscribe-sec .checkbox {
        margin-left: 0;
        width: 100%;
    }

    .subscribe-sec .checkbox span {
        font-size: 9px;
    }

    .subscribe-sec .cta {
        margin-top: 20px;
    }

    .subscribe-sec .cta a {
        font-size: 13px;
        height: 37px;
    }

    .subscribe-sec h2 {
        font-size: 16px;
    }
    .footer-junji-ito .footer-left ul li:first-child {display: none;}

    .footer-junji-ito {
        display: block;
        position: relative;
        height: 210px;
    }

    .footer-left {
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
    }

    .footer-right {
        position: absolute;
        width: 100%;
        top: 20px;
        left: 0;
        height: 50px;
        border-bottom: solid 1px #ce1823;
    }

    .footer-junji-ito .footer-left ul {
        display: block;
        width: 100%;
        text-align: center;
    }

    .footer-junji-ito .footer-right ul {
        justify-content: center;
        gap: 40px;
    }
    .landing-subs-modal {
        width: 300px;
        height: 320px;
      }
      .landing-subs-modal .ant-modal-content {
        padding: 32px 20px;
      }
      .landing-subs-wrapper h2 {font-size: 25px;margin-left: 25px;}
      .landing-subs-wrapper .btn-radio label {font-size: 12px !important;}
      .landing-subs-wrapper .cta 
      {
        width: 100%;
        height: 37px;
        border: solid 1px #fff;
      }
      .landing-subs-wrapper .cta a {font-size: 12px;}
      .landing-subs-modal .anticon-close{display: none !important;}
      .btn-radio [type="radio"]:checked+label::before,
        [type="radio"]:not(:checked)+label::before {            
            top: 0px
        }
}

@media only screen and (max-width: 700px) {
    .primeras-sec .background {
        height: 1280px;
    }
}

@media only screen and (max-width: 600px) {
    .author-sec .content {
        top: 60%;
    }

    .author-sec {
        background-position: left -230px top -100px;
    }

    .primeras-sec .background {
        height: 1150px;
    }
}

@media only screen and (max-width: 550px) {
    .primeras-sec .background {
        height: 1100px;
    }
}

@media only screen and (max-width: 500px) {
    .author-sec {
        background-position: left -280px top -100px;
    }

    .primeras-sec .content .columns .primeras-card {
        flex: 44% 1;
    }

    .primeras-sec .background {
        height: 1030px;
    }
}

@media only screen and (max-width: 450px) {
    .primeras-sec .background {
        height: 980px;
    }

}

@media only screen and (max-width: 380px) {

    .author-sec {
        background-position: left -250px top -100px;
    }

    .primeras-sec .background {
        height: 920px;
    }
}

@media only screen and (max-width: 320px) {
    .primeras-sec .background {
        height: 870px;
    }
}