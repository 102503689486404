@media only screen and (min-width: 1920px) {
    .promotion-section-4 .img-wrapper {
    height: 390px;
    overflow: hidden;
    width: 100%;
  }
  .promotion-product.col-2 {
    max-width: 24%;
  }
}
@media only screen and (max-width: 1141px) {
	
	.promotion-product.col-2 {
		flex: 0 0 50%;
	}
    .promotion-section-3 .section-3-wrapper {
        display: block;
    }

    .promotion-section-3 .section-3-wrapper .left-sec {
        margin-bottom: 20px;
    }

    .promotion-section-3 .promotion-video-wrapper img {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
	.promo-pocket .creditoDC{
		color: #fff;
		margin: 5px;
		font-size: 12px;

	}
	.promo-pocket .ant-input-search-large{
		width:320px!important
	}
	.promo-pocket .cta a{
		height: auto;
	}
	.promo-pocket .logo{display: none;}
	.promo-pocket .promotion-header-wrapper {
        display: block;
    }
	.promo-pocket .localiza-search-input{
		width: 100%;
	}
	.promo-pocket .content-libraries {
		padding: 30px;		
	}
	.promo-pocket .wrapper-libraries {	
		width: 100%;
	}
	.promo-pocket .wrapper-maps-libraries {		
		display: none;
	}
	.promotion-section-4 .img-wrapper {
		height: calc(calc(100vw* .421)* 1.562);
	}
	.promotion-header-wrapper{
		display: none;
	}
	.promotion-banner{
		margin:0
	}
    .section-3-wrapper .right-sec {
        width: 100%;
        float: none;
    }

    .promotion-section-4 .product-listing {
        margin: 20px 0;
        width: 100%;
    }

    .promotion-section-4 .row {
        gap: 0;
    }

    .promotion-product.col-2 {
        max-width: 50%;
        margin-bottom: 30px;
    }

    .promotion-section-4 p {
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .text-left {
        font-size: 15px !important;
    }

    .promotion-section-4 .promotion-product-viewmore {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .promotion-product-viewmore a {
        background-color: #000;
        padding: 10px 20px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
    }

    .section-6-news {
        display: block;
    }

    .section-6-news .right-sec {
        float: none;
    }

    .section-6-news .left-sec,
    .section-6-news .right-sec {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }

    .section-6-news .author-date {
        display: flex;
        gap: 10px;
        font-size: 10px;

    }

    .section-6-news .author-date p {
        margin-bottom: 5px;
    }

    .section-6-news .news-title {
        font-size: 18px;
    }

    .section-6-news .news-desc {
        font-size: 12px;
    }

    .section-6-news .news-img {
        width: 45%;
    }

    .section-6-news .news-content {
        width: 55%;
    }

    .promotion-section-6 .news-img img {
        width: 100%;
        height: auto;
    }

    .promotion-section-6 {
        padding: 30px 0px 20px;
    }

    .promotion-newsletter-section {
        border-top: 2px solid #fff;
        padding: 30px 0px 30px;
    }

    .promotion-newsletter-section .newsletter-wrapper {
        width: 100%;
    }

    .promotion-newsletter-section h4 {
        font-size: 18px;
    }

    .promotion-newsletter-section .custom-checkbox {
        font-size: 12px;
    }

    .promotion-footer-section {
        padding: 10px 10px 5px;
    }

    .promotion-footer-wrapper .right-nav-menu {
        display: flex;
        gap: 5px;
        justify-content: flex-end;
    }

    .promotion-footer-wrapper .right-nav-menu a {
        font-size: 9px;
    }

    .promotion-header-wrapper .right-top-nav {
        display: none;
    }

    .promotion-section-2 {
        padding: 20px 0;
    }
    .promotion-section-3 .section-3-wrapper .right-sec p{
        margin-bottom: 0;
    }
    .promotion-footer-wrapper .left-nav-menu label,.promotion-footer-wrapper .left-nav-menu a{
        font-size: 5px;
    }
    .promotion-footer-wrapper .left-nav-menu, .promotion-footer-wrapper .right-nav-menu
    {
        gap: 5px;
    }
    .promotion-footer-section .right-sec
    {
        width: 20%;
        float: none;
    }
    .promotion-footer-section .left-sec
    {
        width: 80%;
    }
    .promotion-section-4 h2
    {
        display: block;
    }
}