.news_image_slider {
    cursor: pointer;
}

.news_image_slider {
    height: auto;
    margin: 0 5px;
}

.news_slider_wrapper {
    margin-bottom: 70px;
}

.news_image_slider .slick-next,
.news_image_slider .slick-prev {
    top: calc(100vw * .07);
}

.news_image_slider .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}

.news_image_slider .slick-slide,
.news_image_slider .slick-slide.slick-cloned {
    padding-left: 0;
    margin-right: 0;
}

.news_image_slider .slick-slide {
    padding-right: 5px;
    padding-left: 0;
}

.news_image_slider .slick-slide .news_image_slider_img {
    position: relative;
    overflow: hidden;
}

.news_description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    line-height: 21px;
}
@media (max-width: 1141px) 
{
    .news_image_slider .connoticias h1 {
        -webkit-line-clamp: 3;
        line-clamp: 3;
        min-height: auto;
      }
      .news_description {
        -webkit-line-clamp: 6;
        font-size: 12px;
        line-height: 15px;
      }   
      .news_image_slider .connoticias {
        padding: 14px 10px 0;
      }
}
@media (max-width: 767px) 
{
    .news_image_slider .connoticias h1 {
        -webkit-line-clamp: 3;
        line-clamp: 3;
        min-height: auto;
        line-height: 17px;
        margin-bottom: 6px;
      }
      .news_description {
        -webkit-line-clamp: 2;
        font-size: 10px;
        line-height: 14px;
        min-height: 30px;
      }
      .news_image_slider {
        margin-top: 20px;
      }
      .news_image_slider .connoticias {
        margin-bottom: 20px;
      }
      .foreign_rights_intro_wrapper h4 {
        font-size: 12px;
        line-height: 15px;
      }

}
