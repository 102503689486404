@font-face {
  font-family: 'Material Icons' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  src: url(https://example.com/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular');
  font-size: 24px !important;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-BoldItalic.eot');
  src: local('Basier Circle Bold Italic'), local('BasierCircle-BoldItalic'),
    url('../styles/fonts/BasierCircle-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-BoldItalic.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-BoldItalic.woff') format('woff'),
    url('../styles/fonts/BasierCircle-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-Bold.eot');
  src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
    url('../styles/fonts/BasierCircle-Bold.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-Bold.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-Bold.woff') format('woff'),
    url('../styles/fonts/BasierCircle-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-MediumItalic.eot');
  src: local('Basier Circle Medium Itali'), local('BasierCircle-MediumItalic'),
    url('../styles/fonts/BasierCircle-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-MediumItalic.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-MediumItalic.woff') format('woff'),
    url('../styles/fonts/BasierCircle-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-SemiBoldItalic.eot');
  src: local('Basier Square SemiBold Ita'), local('BasierSquare-SemiBoldItalic'),
    url('../styles/fonts/BasierSquare-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-SemiBoldItalic.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-SemiBoldItalic.woff') format('woff'),
    url('../styles/fonts/BasierSquare-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-Italic.eot');
  src: local('Basier Circle Italic'), local('BasierCircle-Italic'),
    url('../styles/fonts/BasierCircle-Italic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-Italic.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-Italic.woff') format('woff'),
    url('../styles/fonts/BasierCircle-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-SemiBold.eot');
  src: local('Basier Circle SemiBold'), local('BasierCircle-SemiBold'),
    url('../styles/fonts/BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-SemiBold.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-SemiBold.woff') format('woff'),
    url('../styles/fonts/BasierCircle-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-Medium.eot');
  src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
    url('../styles/fonts/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-Medium.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-Medium.woff') format('woff'),
    url('../styles/fonts/BasierCircle-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-Regular.eot');
  src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
    url('../styles/fonts/BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-Regular.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-Regular.woff') format('woff'),
    url('../styles/fonts/BasierCircle-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../styles/fonts/BasierCircle-SemiBoldItalic.eot');
  src: local('Basier Circle SemiBold Ita'), local('BasierCircle-SemiBoldItalic'),
    url('../styles/fonts/BasierCircle-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierCircle-SemiBoldItalic.woff2') format('woff2'),
    url('../styles/fonts/BasierCircle-SemiBoldItalic.woff') format('woff'),
    url('../styles/fonts/BasierCircle-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-SemiBold.eot');
  src: local('Basier Square SemiBold'), local('BasierSquare-SemiBold'),
    url('../styles/fonts/BasierSquare-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-SemiBold.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-SemiBold.woff') format('woff'),
    url('../styles/fonts/BasierSquare-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-Medium.eot');
  src: local('Basier Square Medium'), local('BasierSquare-Medium'),
    url('../styles/fonts/BasierSquare-Medium.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-Medium.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-Medium.woff') format('woff'),
    url('../styles/fonts/BasierSquare-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-BoldItalic.eot');
  src: local('Basier Square Bold Italic'), local('BasierSquare-BoldItalic'),
    url('../styles/fonts/BasierSquare-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-BoldItalic.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-BoldItalic.woff') format('woff'),
    url('../styles/fonts/BasierSquare-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-Italic.eot');
  src: local('Basier Square Italic'), local('BasierSquare-Italic'),
    url('../styles/fonts/BasierSquare-Italic.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-Italic.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-Italic.woff') format('woff'),
    url('../styles/fonts/BasierSquare-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-Regular.eot');
  src: local('Basier Square Regular'), local('BasierSquare-Regular'),
    url('../styles/fonts/BasierSquare-Regular.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-Regular.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-Regular.woff') format('woff'),
    url('../styles/fonts/BasierSquare-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Square';
  src: url('../styles/fonts/BasierSquare-Bold.eot');
  src: local('Basier Square Bold'), local('BasierSquare-Bold'),
    url('../styles/fonts/BasierSquare-Bold.eot?#iefix') format('embedded-opentype'),
    url('../styles/fonts/BasierSquare-Bold.woff2') format('woff2'),
    url('../styles/fonts/BasierSquare-Bold.woff') format('woff'),
    url('../styles/fonts/BasierSquare-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


html {
  overflow-y: scroll;
}

body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  max-width: 1900px;
}

Link {
  cursor: pointer;
}

button.btn2_cont_btn.active {
  border: 3px solid #e2f1ff;
  padding: 5px 10px;
  width: 200px;
  border-radius: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #0282f9;
  list-style: none;
  text-align: center;
  margin: 5px 0;
  background: #e2f1ff;
}

.mleft-2b {
  margin-left: 20px !important;
}

.h6,
h6 {
  font-size: 14px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'Basier Circle' !important;
}

p,
label {
  font-family: 'Basier Circle' !important;
}

.homepagelastsec {
  cursor: pointer
}

.homepagelastsec p {
  font-family: 'Basier Circle' !important;
}

.container.main-container,
.container.heading-container {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.step4-3-textfield {
  padding: 5px 15px;
  border-radius: 0;
  border: 1px solid #000;
  margin: 10px 0;
  width: 250px;
}

.step4-3 p {
  margin: 0;
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.step4-2 p {
  font-family: 'Basier Circle';
  font-size: 18px;
  margin: 0 0 5px 0;
}

.bizum {
  /*background: url("") no-repeat;*/
  width: 30px;
  display: inline-block;
  height: 25px;
  background-size: contain;
}

.paypal {
  /*background: url("") no-repeat;*/
  width: 30px;
  display: inline-block;
  height: 25px;
  background-size: contain;


}

input:focus {
  outline: none;
}

.card-c {
  left: 0px;
  position: relative;
  top: 5px;
  width: 30px;
}

td.noborder {
  border: 0 !important;
}

.white-header .small-nav li a {
  color: #fff;
}

.white-header .nav-inner ul li a {
  color: #fff;
}

.white-header .box5 {
  border: 1px solid #fff;
  color: #fff;
}

/*.white-header .header_wrap {
  border-bottom: 1px solid #fff;
}*/
.white-header .navbar-nav .nav-item:hover .arrownavcata {
  background: #fff;
}

.white-header ul.cata.arrownavcata li a {
  color: #000;
}

.white-header .navbar-nav .nav-item:hover .arrownav {
  background: #fff;
}

.white-header ul.novedades.arrownav li a {
  color: #000 !important;
}

.white-header .navbar-nav .nav-item:hover .arrownavespac {
  background: #fff;
}

.white-header .b1 {
  border-right: 1px solid #000;
}

/*.white-header .b2 {    border-right: 1px solid #000;}*/
.white-header ul.espac.arrownavespac li a {
  color: #000;
}

.white-header .triangle-4 {
  border-bottom: solid 11px #fff;
}

.white-header .triangle-4cata {
  border-bottom: solid 11px #fff;
}

.white-header .triangle-4espac {
  border-bottom: solid 11px #fff;
}

.laEditorial {
  position: relative;
}

.laEditorialMenu {
  display: none;
  min-width: 218px;
  position: absolute;
  left: -85px;
  top: 35px;
}

.laEditorial:hover .laEditorialMenu {
  display: block;
  z-index: 999;
  background: #000;
  float: none;
}

.laEditorialMenu .search-triangle-black {
  right: 113px;
}

.laEditorialMenu .laEditorialUl {
  margin: 0;
  padding: 40px;
  text-align: left;
  list-style-type: none;
  background-color: #000;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  height: 205px;
  position: relative;
  z-index: 999;
}

.laEditorialMenu .laEditorialUl li {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

.laEditorialMenu .laEditorialUl li a {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 13px;
}

ul.espac.arrownavespac {
  display: none;
}

.triangle {
  display: block;
  margin: 0 auto;
  position: absolute;
}

.triangle-4 {
  width: 11px;
  height: 11px;
  border-bottom: solid 11px #000;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
}

.triangle {
  display: none;
}

.novedades {
  position: relative;
  font-weight: 700;
  font-size: 14px;
}

.navbar-nav .nav-item:hover .triangle {
  display: table;
  position: absolute;
  top: -11px;
  left: 118px;
}

.arrownav {
  display: none;
}

ul.novedades.arrownav {
  margin: 0;
  padding: 35px 40px;
  position: absolute;
  top: 13px;
}

ul.novedades.arrownav li {
  float: none;
  margin-bottom: 15px;
  padding: 0;
}

ul.novedades.arrownav li a {
  color: #fff;
  font-weight: bold;
  font-family: 'Basier Circle';
  font-size: 14px;
}

.navbar-nav .nav-item:hover .arrownav {
  display: block;
  position: relative;
  background: #000;
  float: none;
  margin: 0 -80px;
}

.spacer-2 {

  margin: 16px 0 0 0;
  position: absolute;
}

/*CATÁLOGO MENU START*/
.trianglecata {
  display: block;
  margin: 0 auto;
  vertical-align: middle;
  position: absolute;
}


.triangle-4cata {
  width: 11px;
  height: 11px;
  border-bottom: solid 11px #000;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
}

.trianglecata {
  display: none;
}

.navbar-nav .nav-item:hover .trianglecata {
  display: table;
  position: absolute;
  top: -11px;
  left: 74px;
}

.cata {
  position: relative;
}

.trianglecata {
  display: none;
}

.arrownavcata {
  display: none;
}

ul.cata.arrownavcata {
  margin: 0;
  padding: 35px 40px;
  position: absolute;
  top: 24px;
}

ul.cata.arrownavcata li {
  float: none;
  margin-bottom: 15px;
  padding: 0;
}

ul.cata.arrownavcata li a {
  color: #fff;
  font-weight: bold;
  font-family: 'Basier Circle';
  font-size: 14px;
}

.navbar-nav .nav-item:hover .arrownavcata {
  display: block;
  position: relative;
  background: #000;
  float: none;
  margin: 0 -50px;
}

.spacer-2cata {
  margin: 6px 0 0 0;
  position: absolute;
}

/*CATÁLOGO MENU END*/
/*Espacios MENU START*/
.triangleespac {
  display: block;
  margin: 0 auto;
  vertical-align: middle;
  position: absolute;
}


.triangle-4espac {
  width: 11px;
  height: 11px;
  border-bottom: solid 11px #000;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
}

.triangleespac {
  display: none;
}

.navbar-nav .nav-item:hover .triangleespac {
  display: table;
  position: absolute;
  top: -11px;
  left: 280px;
}

.espac {
  position: relative;
}

.triangleespac {
  display: none;
}

.arrownavcata {
  display: none;
}

ul.espac.arrownavespac {
  margin: 0;
  padding: 35px 40px;
  position: absolute;
  top: 15px;
}

ul.espac.arrownavespac li {
  float: left;
  margin-bottom: 15px;
  padding: 0 40px 0 0;
}

ul.espac.arrownavespac li:last-child {
  padding: 0;
}

ul.espac.arrownavespac li a {
  color: #fff;
  font-weight: bold;
  font-family: 'Basier Circle';
  font-size: 14px;
}

.navbar-nav .nav-item:hover .arrownavespac {
  display: table;
  position: relative;
  background: #000;
  float: none;
  margin: 0 -260px;
}

.spacer-2espac {
  margin: 15px 0 0 0;
  position: absolute;
}

ul.espac.arrownavespac li ul li {
  float: none;
}

.b1 {
  border-right: 1px solid #fff;
  padding: 0 30px 0 0 !important;
}

.b2 {
  padding: 0 30px 0 0 !important;
}

/*Espacios MENU END*/
.cata-wrap {
  position: relative;
}

.nave-wrap {
  position: relative;
}

.actual-wrap {
  position: relative;
}

.espac-wrap {
  position: relative;
}

.address-lft label {
  font-family: 'Basier Circle';
  font-size: 15px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000;
  color: #000;
  font-weight: bold;
  padding: 0;
}


.address-wrap {
  display: table;
  padding: 0 0 0 35px;
  width: 100%;
}

.address-lft p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #000;
}

.address-lft {
  float: left;
  width: 50%;
}

.address-rht {
  float: right;
  width: 50%;
}

ul.wrap-step-c {
  padding: 0;
  margin: 0;
}

ul.wrap-step-c li label {
  font-weight: bold;
  font-family: 'Basier Circle';
  font-size: 13px;
}

ul.wrap-step-c li p {
  font-size: 18px;
  line-height: 25px;
  padding: 0 0 0 35px;
}

.wrap-step-c li img {
  margin: 0 0 0 35px;
}

.amount {
  margin: 20px 0 0 0;
}

.address-rht a {
  font-weight: bold;
  font-family: 'Basier Circle';
  float: right;
  background: #e2f1ff;
  border: 3px solid #0282f9;
  padding: 5px 25px;
  margin: 90px 0 0 0;
}

.wrap-field {
  border-bottom: 1px solid #000;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  display: table;
  width: 100%;
}

.Rectangle-3>div:last-child {
  border-bottom: 0;
}

.amount .lft-2-cont {
  float: left;
  width: 50%;
}

.amount .right-2-cont {
  float: right;
  width: 50%;
}

.amount .lft-2-cont p {
  font-family: 'Basier Circle';
  font-size: 10px;
  color: #000;
  margin: 0;
}

.amount .right-2-cont p {
  font-family: 'Basier Circle';
  font-size: 10px;
  color: #000;
  margin: 0;
  text-align: right;
}

.lft-img {
  width: 15%;
  float: left;
  margin-right: 3%
}

.lft-img img {
  width: 100%;
}

.rht-cont-2 p {
  font-family: 'Basier Circle';
  font-size: 12px;
  color: #000;
}

.rht-cont-2 {
  width: 82%;
  float: right;
}

.Rectangle {
  background: #e2f1ff;
  padding: 20px;
  margin-bottom: 2px;
}

.cart-step .line4 {
  display: none;
}

.cart-step .lft-cont {
  float: left;
  width: 76%;
}

.cart-step h3 {
  margin-bottom: 0;
}

.cart-step .rht-cont {
  float: right;
  width: 20%;
}

.Rectangle-3 {
  background: #f9f9f9;
  padding: 20px;
  display: table;
  width: 100%;

  margin-top: 1px;
}

.Rectangle-5 {
  background: #f9f9f9;
  padding: 20px;
  display: table;
  width: 100%;
  margin-top: 2px;
}

.Rectangle-4 {
  background: #f9f9f9;
  padding: 20px;
  display: table;
  width: 100%;
  margin-top: 7px;
  position: relative;
}

.Rectangle p {
  color: #0282f9;
  margin: 0;
  padding: 0;

}

.btn-side.grey-btn a,
.buttn.grey-btn,
.btn2.grey-btn {
  border: 4px solid #aaa !important;
  color: #aaa;
}

.btn-side.grey-btn a,
.buttn.grey-btn a {
  color: #aaa;
}

.btn-side.grey-btn span,
.buttn.grey-btn span {
  color: #aaa;
}

.btn-side.f-left a {
  float: left;
  width: 100%;
  text-align: center;
}

.buttn.belowbtn {
  display: none;
}

.form-control-2 {
  width: 130px;
  height: 25px;
  margin: 0 0 0 1px;
  padding: 0 0 1px 5px;
  border: 1px solid #0282f9;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: Basier Circle;
  font-size: 15px;
}

.Rectangle-4 .form-control-2 {
  font-size: 12px;
}

.btn2.grey-btn:hover {
  color: #aaa;
}

.disabled-grey-btn {
  border: 4px solid #aaa !important;
  color: #aaa;
}

.disabled-grey-btn a {
  cursor: default;
}

.mar-wrap-lft {
  float: left;
  width: 89% !important;
}

.mar-wrap-rht {
  float: right;
  cursor: pointer;
}

.coupon-desc {
  background-color: #e2f1ff;
  padding: 20px;
  position: absolute;
  width: 182px;
  height: 70px;
  top: -25px;
  left: 125px
}

.dudas {
  display: none;
}

.blue-triangle {
  width: 10px;
  height: 10px;
  border-top: solid 11px #e2f1ff;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
  position: absolute;
  top: 45px;
  right: 38px;
}

.coupon-error {
  background: #ffeef5;
  padding: 20px;
  margin-bottom: 0px;
  width: 252px;
  height: 56px;
}

.coupon-text {
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  width: 85px;
}

.form-control-2.coupon-error-input {
  border: 1px solid #f9026a;
}

.coupon-error p {
  color: #f9026a;
  font-size: 12px;
}

.coupon {
  display: none;
}

.coupon-desc p {
  color: #0282f9;
}

.mar-wrap-rht:hover~.coupon {
  display: block;
}

.btn5 {
  background: #0282f9;
  border: 0;
  font-family: 'Basier Circle';
  font-size: 12px;
  color: #fff;
  padding: 3px 10px;
}

.Rectangle-4 p {
  font-family: 'Basier Circle';
  font-size: 12px;
}

.buttn {
  border: 4px solid #000;
  margin: 30px 0 0 0;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  cursor: pointer
}

.buttn.rightside {
  width: 230px;
  margin-bottom: 20px;
}

.table-right {
  float: right;
  text-align: right;
  width: 230px;
}

.table-right a {
  color: #000;
  font-weight: bold;
  font-size: 14px;
}

.buttn a {
  color: #000;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.buttn span {
  color: #000;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.precio-total {
  font-size: 12px;
  font-weight: normal;
  display: none;
}

.checkbox-list ul {
  padding-left: 0;
}

.checkbox-list .checkmark {
  width: 19px;
  height: 19px;
}

.checkbox-list .custom_checkbox_wrapper {
  font-size: 10px;
  padding-left: 25px;
  line-height: 14px;
  margin-bottom: 9px;
}

.checkbox-list .checkmark.error {
  border: 1px solid #f9026a;
}

.checkbox-list ul li {
  display: inline-block;
}

.checkbox-list {
  margin-top: 30px;
}

.buttn a:hover {
  text-decoration: none;
  color: #000;
}

.Rectangle-3 h2 {
  float: left;
  font-weight: bold;
  width: 50%;
  margin: 0;
  padding: 0;
}

.Rectangle-3 h3 {
  float: right;
  font-weight: bold;
  width: 50%;
  margin: 0;
  padding: 0;
  text-align: right;
}

.Rectangle-5 h2 {
  float: left;
  width: 50%;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.Rectangle-5 h3 {
  float: right;
  width: 50%;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-align: right;
}

.Rectangle-2 {
  background: #fff7e2;
  padding: 20px;
  margin-bottom: 5px;
}

.Rectangle-2 p {
  color: #8a6400;
  font-family: 'Basier Circle';
  margin: 0;
  padding: 0;
}

.color-div p,
.color-div h2,
.color-div h3 {
  font-size: 12px;
}

.lft-cont {
  float: left;
  width: 74%;
}

.rht-cont {
  float: right;
  width: 24%;
}

.nav-2-wrapper ul {
  margin: 0;
  padding: 0;
}

hr.line7 {
  position: absolute;
  border: 1px solid #aaaaaa;
  top: 47px;
  left: 52px;
  width: 160px;
}

.active_4 .number_tag h2 {
  color: #000;
  border: 4px solid #000;
}

.active_4 .number_tag p {
  color: #000;
}

.active_4 .number_tag .line7 {
  border: 2px solid #000;
}

.active_4 .number_tag span {
  color: #000;
}


.arrow_down {
  position: absolute;
  right: 20px;
  bottom: -5px;
  border: solid black;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
  -moz-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(45deg);
}

.number_tag span {
  font-weight: bold;
  font-family: 'Basier Circle';
  text-align: center;
  position: absolute;
  color: #aaa;
  width: 140px;
  left: -35px;
}

.number_tag p {
  font-weight: bold;
  font-family: 'Basier Circle';
  text-align: center;
  color: #aaaaaa;
}

.number_tag {
  display: table;
  position: relative;
}

.number_tag_2 h2 {
  border: 4px solid #aaaaaa;
  height: 52px;
  width: 52px;
  text-align: center;
  font-weight: bold;
  font-family: 'Basier Circle';
  font-size: 20px;
  padding: 10px;
  margin: 0 auto;
}

.number_tag_2 p {
  font-weight: bold;
  font-family: 'Basier Circle';
  position: absolute;
  top: -36px;
  color: #aaa;
}

.number_tag_2 {
  display: table;
}

.number_tag_2 {
  position: relative;
}

.nav-2-wrapper li {
  float: left;
  display: table;
  width: 210px;
  position: relative;
}

.top-nav-2 {}

.top-nav-2 li {
  float: left;
  text-align: center;
}

.number_tag h2 {
  border: 4px solid #aaa;
  height: 52px;
  width: 52px;
  text-align: center;
  font-weight: bold;
  font-family: 'Basier Circle';
  font-size: 20px;
  padding: 10px;
  color: #aaa;
  margin: 0 auto;
}

.number_tag h2.goback:hover {
  background-color: #0282f9;
  color: #fff;
  cursor: pointer;
}

i.ico-pos {
  position: absolute;
  right: 12px;
  top: 12px;
}

.cal {
  position: relative;
}

.card_logo {
  margin: 50px 0 30px 0;
}

.tienes_links a {
  text-align: right;
  font-weight: bold;
  font-family: 'Basier Circle';
  text-decoration: underline;
  font-size: 14px;
  color: #000;
  float: right;
  margin-top: 100px;
}

.tienes_links_myorder {
  text-align: right;
  font-weight: bold;
  text-decoration: underline;
  font-size: 14px;
  color: #000;
}

h2.A-la-venta-esta-sema.line7 {
  border-bottom: 4px solid #000;
  padding-bottom: 8px;
  display: table;
}

.header1 {
  position: relative;

}

.header1>div:first-child {
  position: unset !important;
  cursor: pointer;
}

.header1 .backgroundimg {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-position: top;
  object-fit: cover;
}


li {
  list-style: none;
}

.small-menu {
  float: right;
}

.small-menu li {
  float: left;
  margin: 0 0 0 15px;
}

.small-menu li a {
  font-family: 'Basier Circle';
  font-size: 10px;
  color: #fff;
}

.line {
  padding: 0;
  height: 1px;
  margin: 6px 0;
  border: solid 1px #fff;
}

a.nav-link.font-style {
  font-size: 14px;
  color: #000;
}

.navbar .navbar-brand {
  font-size: 30px;
}

.navbar .nav-link {
  font-size: 20px;
  margin-left: 10px;
}

.fa-bars {
  color: #007bff;
  font-size: 30px;
}

.social ul {
  line-height: 33px;
}

.social ul li {
  float: left;
}

.social ul li a {
  color: #fff;
}

i.fa.socialico {
  font-size: 20px;
  margin-left: 15px;
}

.box {
  font-size: 18px !important;
  border: 1px solid #fff;
  padding: 4px;
  margin: 0 0 0 10px;
}

.DC-death-metal-prime {

  /*margin: 144px 144px 0 0px;*/
  text-shadow: 4px 4px 17px rgb(0 0 0 / 27%);
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #fff;
  font-size: 80px;
  line-height: 83px;
}

.jumbotron.bgheadung {
  background: none;
  padding: 0;
  position: absolute;
}

.line1 {
  height: 1px;
  background: rgba(0, 0, 0, .5);
  margin-top: 5px;
}

.line4 {
  height: 4px;
  background: #000;
  margin-top: 5px;
}

.line5 {
  height: 3px;
  background: #000;
  margin-bottom: 30px;
  margin-top: 5px;
}

.box3 .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.A-la-venta-esta-sema,
.communicadostitle {
  font-size: 42px;
  font-family: 'BasierCircle-Bold';
  font-weight: bold;
  color: #000;
}

.image_slider_homeproducts .img-wrapper {
  /* height: 270px; */
  position: relative;
  display: flex;
    align-items: center;
}

.image_slider_homeproducts img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image_slider_actualidadevents .slick-track,
.image_slider_longnews .slick-track {
  padding: 0;
}

.image_slider_actualidadevents .slick-slider.slick-initialized {
  margin-right: -5px;
}

.image_slider_homeproducts p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 16px;
  line-height: 21px;
  cursor: pointer
}

.image_slider_homeproducts {
  margin-top: 5px;
  height: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.image_slider_homeproducts .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.image_slider_home_letura {
  height: auto;
}

.image_slider_home_letura .slick-slide {
  margin: 0;
  padding: 0;
}

.image_slider_home_letura .slick-slide.slick-active.slick-current {
  margin: 0;
  padding: 0 5px;
}

/*.image_slider_home_letura .slick-slide > div{height: calc(100vw * 0.258);}*/
.image_slider_homereleases img {
  max-width: 100%;
  margin: 4px 0 0px 0;
  height: 240px;
  width: 100%;
}

.image_slider_homereleases .slick-prev,
.image_slider_homereleases .slick-next {
  top: 125px;
}

.image_slider_homereleases {
  margin-top: 30px;
  margin-bottom: 15px;
}

.slick-slide {
  margin-right: 5px;
}

.image_slider_homereleases .slick-track {
  padding: 0;
}

.image_slider_homereleases .slick-slide {
  margin-right: 0;
  cursor: pointer;
}

.image_slider_homeevents .slick-slide:last-child {
  margin-right: 0;
  width: 0 !important;
}

.col-sm-8.padmar {
  margin: 0;
  padding-left: 0;
  padding-right: 82px;
}

.col-sm-4.padmar {
  margin: 0;
  padding-right: 0;
  padding-left: 0;
}

.catalogo li {
  float: left;
  width: 50%;
  margin-top: 5px;
  height: 240px;
}

.catalogo li:nth-child(2) {
  margin-right: 0;
}

.catalogo li:nth-last-child(1) {
  margin-right: 0
}

.catalogo a {
  color: #fff;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 37px;
}

.catalogo a:hover {
  color: #fff;
  text-decoration: none;
}

.catalogo label {
  width: 100%;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  margin-left: 15px;
  z-index: 1;
}

.logo {
  float: left;
  width: 121px;
  height: 49px;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 100%;
}

.catalogo .eccPublishingLine {
  display: inline-block;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.catalogo .eccPublishingLine img {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.catalogo .eccPublishingLine .wrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.marg {
  margin-bottom: 30px;
}

.marg p {
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.marg h3 {
  margin-bottom: 18px;
  margin-top: 15px;
  font-family: 'Basier Circle';
  font-weight: bold;
  ;
  font-size: 32px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #000;
  ;
}

.dark-night {
  border-right: 2.5px solid #fff;
  float: left;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.dark-night img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tshirtdiv {
  border-left: 2.5px solid #fff;
  background-color: #edeeee;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  cursor: pointer
}

.homepagecontestsec {
  margin: 60px 0;
}

.homepagecontestsec .row {
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
}

.slick-prev:before {
  font-family: "Material Icons";
  content: "arrow_back";
  color: #000;
}

.slick-next:before {
  font-family: "Material Icons";
  content: "arrow_forward";
  color: #000;
}

.ant-pagination-item-link .anticon {
  position: relative;
}

.ant-pagination-item-link .anticon svg {
  display: none !important;
}

.anticon.anticon-left:after {
  font-family: "Material Icons";
  content: "arrow_back";
  color: #000;
  font-size: 27px;
  position: absolute;
  top: -5px;
  left: -18px;
}

.anticon.anticon-right:after {
  font-family: "Material Icons";
  content: "arrow_forward";
  color: #000;
  font-size: 27px;
  position: absolute;
  top: -5px;
  right: -18px;
}

.ant-image-preview-root .anticon.anticon-right:after {
  display: none;
}

.ant-image-preview-root .anticon.anticon-left::after {
  display: none;
}

.ant-pagination-item {
  font-family: 'Basier Circle' !important;
  font-size: 14px;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  display: block !important;
}

.tshirtdiv .content {
  padding: 100px 20px 0 20px;
  width: 44%;

}

.tshirtdiv .tshirt {
  padding: 50px 20px 0 0px;
  width: 44%;

}

.tshirtdiv .content h1 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 25px;
  font-size: 40px;
}

.tshirtdiv .content p {
  font-family: 'Basier Circle';
  font-size: 20px;
}

.tshirt img {
  width: 100%;
}

.image_slider_homereleases .img-wrapper {
  width: 100%;
  height: 245px;
  position: relative;
}

.noticias img {
  object-fit: cover;
  width: 100%;
  min-width: 100%;
  height: 100%;
}

.image_slider_homenews .slick-slide,
.image_slider_homenews .slick-slide.slick-cloned {
  padding-left: 0;
  margin-right: 0;
}

.image_slider_homenews .slick-slide {
  padding-right: 5px;
  padding-left: 0px;
}

.image_slider_homenews .slick-slide .image_slider_homenews_img {
  /* height: 205px; */
  position: relative;
  overflow: hidden;
}

.image_slider_homenews .connoticias h1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image_slider_homenews .connoticias p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image_slider_homenews .slick-slide .image_slider_homenews_img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image_slider_homenews {
  height: auto;
  margin: 0 5px;
}

.image_slider_homenews .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.connoticias {
  padding: 34px 34px 0;
}

.home_news_description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 21px;
}

.noticias h1 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 28px;
}

.noticias p {
  font-family: 'Basier Circle';
  font-size: 18px;
  color: #000;
}

.image_slider_homenews,
.cover-h,
.homemagzinesec {
  cursor: pointer;
}

.image_slider_homenews .slick-prev,
.image_slider_homenews .slick-next {
  top: calc(100vw * 0.070);
}

.image_slider_homenews .releases .slick-prev,
.image_slider_homenews .releases .slick-next {
  top: calc(100vw * 0.130);
}

.revista .slick-prev,
.revista .slick-next {
  top: 55px;
}

.homepage_news_slider_wrapper {
  margin-bottom: 70px;
}

.tiendas .A-la-venta-esta-sema {
  cursor: pointer;
}

.for-cover {
  height: 369px;
  padding: 0;
  margin: 0;
}

.my-15 {
  margin: 15px 0 5px;
}

.container-fluid.homemagzinesec {
  margin: 0;
  padding: 0;
}

.pos-slider {
  position: relative;
  overflow: hidden;
}

.pos-slider.banners {
  cursor: pointer;
}

.pos-slider h2 {
  position: absolute;
  top: calc(100vw * 0.121);
  left: 150px;
  color: #fff;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 42px;
}

.pos-slider .reading-guide-text {
  display: none;
}

.pos-slider a:hover {
  text-decoration: none;
}

.cover-h {
  height: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}

.cover-h img {
  width: 100%;
  height: 100%;
}

.justice,
.tortues {
  border-right: 1.5px solid #fff;
}

.pooerosas,
.ricky {
  border-left: 1.5px solid #fff;
}

.tortues,
.pooerosas {
  border-bottom: 3px solid #fff;
}

.banners .row {
  margin-right: 0;
  margin-left: 0;
}

.banners .bannerText {
  position: absolute;
  top: 43%;
  left: 10%;
  font-size: 58px;
  font-family: 'Basier Circle';
  font-weight: bold;
  line-height: 60px;
}


.homemagzinesec h2 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 27px;
  margin-top: 30px;
}

.homemagzinesec p {
  font-family: 'Basier Circle';
  font-size: 13px;
  line-height: 19px;
  color: #000;
  margin: 10px 0 10px;
}

.homemagzinesec img {
  width: 100%;
  height: 100%;

}

.homemagzinesec ul {
  padding-left: 0;
}

.homepage_events_sec img {
  width: 100%;
  height: 130px;
  object-fit: cover
}

.image_slider_home_letura .slick-prev,
.image_slider_home_letura .slick-next {
  top: calc(100vw * 0.135);
}

.image_slider_home_letura .pos-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.res {
  position: relative;
}

.res1 {
  position: relative;
  cursor: pointer
}

.res2 {
  position: relative;
}

.image_slider_homeevents .row {
  margin-right: 0;
  margin-left: 0;
}

.image_slider_homeevents img {
  width: 100%;
}

.connoticias1 {
  padding: 0;
  height: 250px;
}

.image_slider_homeevents .connoticias1 {
  height: 100%;
  cursor: pointer
}

.image_slider_homeevents .connoticias1 p {
  -webkit-line-clamp: 3;
  height: 52px !important;
  font-size: 15px;
  line-height: 21px;
  color: #000;
  margin: 20px 0 50px;
  width: 90%;
}

.date {
  position: absolute;
  background: #fff;

  padding: 5px;
  top: 5px;
  left: 10px;
  width: 60px;
  height: 60px;
}

.image_slider_homeevents .connoticias1 .date h5 {
  text-align: center;
  font-size: 10px;
  padding: 0;
  margin-top: 5px;
  color: #000;
  text-transform: uppercase;
  font-weight: normal;
}

.image_slider_homeevents .connoticias1 .date p {
  font-weight: bold;
  text-align: center;
  padding: 0;
  font-size: 20px;
  margin: 0;
}

.homepage_events_sec .slick-prev {
  top: -60px;
  left: 88%;
}

.homepage_events_sec .slick-next {
  top: -60px;
  right: 0;
}

.box--wrap:hover .slick-prev,
.box--wrap:hover .slick-next {
  display: block !important;
}

.row.ultimos {
  justify-content: flex-start;
  align-items: center;
}

.cubierta img {
  object-fit: cover;
}

.cubierta {
  width: 111px;
  height: 172px;
  border: 2px solid #fff;
  padding: 0 10px 0 15px;
  max-width: 130px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}

.homepagelastsec h2 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 42px;
  color: #fff;
  margin: 0;
  padding: 100px 0 0 0;
}

.homepagelastsec p {
  font-family: 'Basier Circle';
  font-size: 14px;
  color: #fff;
  margin-bottom: 50px;
}

.homepagelastsec h5 {
  font-family: 'Basier Circle';
  font-size: 13px;
  color: #fff;
  margin-bottom: 10px;
}

.respos {
  position: relative;
  bottom: -75px;
}

.line6 {
  border-top: 2px solid #000;
  margin: 50px 0;
}

.line6.line-footer {
  border-top: 1px solid #000;
  margin: 50px 0 0;
  padding: 30px 0;
}

.line6 ul {
  text-align: center;
}

.line6 ul li {
  float: left;
  margin-right: 25px;
}

.line6 ul li:last-child {
  margin-right: 0;
}

.line6 ul li a {

  font-family: 'Basier Circle';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  color: #000;
}

.line6 ul {
  text-align: center;
  margin: 0px auto 0px;
  padding: 0;
}

.line6 p {
  text-align: center;
  margin: 0px auto 20px;
  font-family: 'Basier Circle';
  font-size: 12px;
}

.line6.line-footer p {
  font-weight: normal;
  margin: 0px auto 0px;
  margin: 0px auto 0px;
}

.footer-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
}

.footer_sec .col-12.form2 {
  padding-left: 0px;
  padding-right: 0;
}

.footer_sec .row {
  margin-right: 0;
  margin-left: 0;
}

.footer-btn-wrapper .btn-wrapper .btn2 {
  margin-top: 0;
}

.social1 ul li {
  float: left;
  width: 39px;
  height: 39px;
  font-size: 18px !important;
  border: 1px solid #000 !important;
  padding: 6px 9px 6px 10px;
  margin: 0 0 0 10px;
}

.social1 ul li a {
  color: #000;
}

i.fa.socialico1 {
  font-size: 18px;
  margin: 0px;
  padding: 6px;
}

.social1 ul li.box1:last-child {
  padding: 6px 6px 6px 9px;
}

.form2 h2 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

.form2 p {
  font-family: 'Basier Circle';
  font-size: 14px;
  color: #000;
  font-weight: normal;
}

.form2 label {
  font-family: 'Basier Circle';
  font-size: 14px;
  color: #000;
}

.form input[type="text"],
.form input[type="password"] {
  font-family: 'Basier Circle';
  font-size: 15px;
  border: 1px solid #000;
  padding: 10px 15px;
  width: 100%;
  margin: 0 0 30px 0;
}

.form2 span {
  font-family: 'Basier Circle';
  font-size: 14px;
  color: #000;
  margin: 0 0 0 10px;
}

.form2 input[type=checkbox] {
  border: 1px solid #000;
}

button {
  border: 2px solid #000;
  background: none;
  padding: 3px 30px;
}

.checkbo {
  margin: 15px 0;
}

.footer_sec {
  margin: 90px 0 0px 0;
}

.footerform {
  display: flex;
  justify-content: center;
}

.footerform .form-group {
  font-size:
    15px;
  margin-top: 25px;
  margin-bottom: 0;
}



/*inner Page*/


.innersec_images1 p i {
  font-size: 14px;
}

.innersec_images1 img {
  width: 100%;
}

.innersec_images {
  margin: 40px auto 0;
}

.innersec_images ul li {
  float: left;
  width: 500px;
}

.innersec_images ul {
  margin: 0;
  padding: 0;
}

.innersec_images ul li img {
  width: 500px;
  height: auto;
  margin: 0 0 10px 0;
}

.innersec_images3 {
  margin: 30px auto 0;
  display: inline-block;
}

.innersec_images3 ul li {
  float: left;
  width: 333px;
}

.innersec_images3 ul {
  margin: 0;
  padding: 0;
}

.innersec_images3 ul li img {
  width: 100%;
  height: 187px;
  margin: 0;
}

.innersec_images4 {
  margin: 30px auto 0px;
  display: table;
}

.innersec_images4 ul {
  padding: 0;
  display: flex;
  margin-left: -5px
}

.innersec_images4 ul li {
  width: 33.33%;
  padding-left: 5px;
}

.innersec_images4 ul li:last-child {
  border-right: 0px solid #fff;
}

.innersec_images4 ul li img {
  width: 100%;
  height: auto;
  margin: 0;
}

.ayuda .accordion {
  margin-bottom: 37px;
}

.subheading {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  margin: 30px 0 10px 0;
  line-height: 30px;
}

.navigation {
  margin-top: 50px;
  margin-bottom: 30px;
}

.navigation .navbar {
  padding: 0;
}

.navigation .navi1 .navbar-nav {
  flex-direction: initial;
}

.btn2.contacto {
  width: 100px;
  padding: 10px 10px;
  margin-top: 15px;
  margin-bottom: 45px;
}

.contacto br {
  display: none;
}

.A-la-venta-esta-sema1 {
  font-size: 42px;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #000;
  margin: 10px 0 0 0;
}

.A-la-venta-esta-sema a {
  color: #000;
}

.A-la-venta-esta-sema2 {
  font-size: 28px;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #000;
  margin: 60px 0 0 0;
}

.innerpage-2 {
  width: 88%;
  margin: 0 auto;
}

.innerpage-2 .image {
  width: auto;
}

.communicados ul {
  padding-left: 0px;
}

.communicadostitle {
  margin-top: 30px;
}

.innerpage p {
  font-family: 'Basier Circle';
  font-size: 18px;
  margin: 10px 0 0 0;
  line-height: 25px;
}

.innerpage-2 p {
  font-family: 'Basier Circle';
  font-size: 18px;
  margin: 10px 0;
  line-height: 25px;
}

.innerpage-2.communicados a {
  color: #3522db;
  text-decoration: underline;
}

.links.nomargin {
  margin: 20px 0 30px;
}

.links {
  margin: 30px auto;
  display: table;
  padding: 0;
}

.distribucion .subheading br {
  display: none;
}

.links ul {
  margin: 0;
  padding: 0;
}

.links li {
  border: 4px solid #000;
  margin-right: 40px;
  padding: 5px 20px;
  ;
  float: left;
  width: auto;
  text-align: center;
  list-style: none !important;
}

.links li:hover {
  border: 4px solid #000;
  background: #000;
  margin-right: 40px;
  padding: 5px 20px;
}

.links li a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  list-style: none !important;
}

.links li:hover a {
  color: #fff;
}

.historia.links li:first-child {
  width: 209px;
  padding: 5px 10px;
}

.historia.links li:nth-child(2) {
  width: 185px;
  padding: 5px 10px;
}

.historia.links li:last-child {
  width: 137px;
  padding: 5px 10px;
}

.historia.links {
  margin-bottom: 70px;
}

.modal.image-lightbox {
  background-color: rgba(0, 0, 0, 0.9) !important;
  padding-top: 80px;
}

.image-lightbox .modal-dialog {
  max-width: 100% !important;
  padding-left: 50px;
  padding-right: 50px;
}

.image-lightbox .modal-content {
  padding: 0;
  background: transparent;
}

.image-lightbox .modal-body {
  padding: 0;
}

.image-lightbox .carousel-item img {
  width: 90%;
}

.image-lightbox .carousel-inner p {
  padding-top: 10px;
  color: #fff;
}

.historia .subheading {
  margin-bottom: 30px;
}

.historia .subheading br {
  display: none;
}

.image-lightbox .carousel-item {
  text-align: center;
}

.arrows-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.image-lightbox .carousel-control-prev-icon,
.image-lightbox .carousel-control-next-icon {
  display: none;
}

.image-lightbox .carousel-control-prev {
  opacity: 1;
  width: auto;
}

.image-lightbox .carousel-control-next {
  opacity: 1;
  width: auto;
}

.image-lightbox .carrousel.slide {
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
}

.image-lightbox .carousel-control-prev:before {
  font-family: "Material Icons";
  content: "arrow_back";
  color: #000;
  width: 35px;
  height: 35px;
  background: #fff;
  opacity: 1;
  /*position: absolute;
top:0;
left: 4px;*/
  z-index: 999;
  padding: 2px 5px;
  font-size: 21px;
}

.image-lightbox .carousel-control-next:before {
  font-family: "Material Icons";
  content: "arrow_forward";
  color: #000;
  width: 35px;
  height: 35px;
  background: #fff;
  opacity: 1;
  /*position: absolute;
   top:0;
   left: 4px;*/
  z-index: 999;
  padding: 2px 5px;
  font-size: 21px;
}

.btn {
  border: 3px solid #000;
  padding: 5px 10px;
  ;
  float: left;
  width: 100px;
  border-radius: 0;
  float: right;
  margin: 20px 0 0 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
  list-style: none;
  text-align: center;
}

.btn2 {
  border: 3px solid #000;
  padding: 5px 10px;
  ;
  float: left;
  width: 100px;
  border-radius: 0;
  float: left;
  margin: 20px 0 0 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
  list-style: none;
  text-align: center;
  transition: color .3s;

}

.btn-white {
  border: 3px solid #fff;
  padding: 5px 10px;
  width: 130px;
  border-radius: 0;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  display: none;
  color: #fff !important;
}

.btn2_cont:hover {
  color: #000 !important;
}

.btn2_cont {
  border: 3px solid #000 !important;
  padding: 5px 10px !important;
  float: left;
  border-radius: 0 !important;
  font-family: 'Basier Circle';
  font-weight: bold !important;
  font-size: 14px !important;
  color: #000 !important;
  list-style: none;
  text-align: center;
  background: transparent !important;
}

.btn2_cont.libreros {
  width: 100px;
  padding: 7px 10px !important;
  height: 39px;
}

.btn2_cont.login {
  width: 105px;
}

.btn2_cont.register {
  width: 160px;
}

.btn2_cont.add {
  width: 215px;
}

.btn2_cont.registerbtn {
  width: 118px;
}

.btn2_cont.espacio {
  width: 120px;
}

.btn2_cont.luego {
  width: 136px;
}

.link_cont {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
  list-style: none;
  float: right;
}

.btn1:hover {
  color: #000;
}

.btn:hover {
  color: #000;
}

.btn2:not(.grey-btn):hover {
  color: #fff;
  background-color: #000;
}

.btn1 {
  border: 3px solid #000;
  padding: 5px 10px;
  ;
  float: left;
  width: 100px;
  border-radius: 0;
  float: right;
  margin: 10px 0 0 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
  list-style: none;
  text-align: center;
}

.map {
  position: relative;
}

.mapbg {
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 0.5
}

.bgmap {

  background: red;
  height: 100%;

}

.contacts {
  background: #e2f1ff;
  padding: 30px 40px;
  margin: 30px 0 0 0;
}

.contacts p {
  color: #0282f9;
  font-family: 'Basier Circle';
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.form h4 {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
  list-style: none;
  font-weight: normal;
}

.form input[type="text"],
.form input[type="password"] {
  font-family: 'Basier Circle';
  font-size: 15px;
  border: 1px solid #000;
  padding: 10px 15px;
  width: 100%;
  margin: 0 0 30px 0;
  height: 29px;
}

.form .ant-form-item {
  margin-bottom: 30px !important;
}

.form .ant-form-item input {
  margin-bottom: 0px !important;
}

.form .ant-form-item .ant-select .ant-select-selector {
  border-color: #000;
  margin-bottom: 10px;
}

.form .ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100% !important;
}

.form-5 h4 {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
  list-style: none;
}

.form-5 input[type="text"] {
  font-family: 'Basier Circle';
  font-size: 15px;
  border: 1px solid #000;
  padding: 10px 15px;
  width: 100%;
  margin: 0 0 30px 0;
}

.form textarea {
  font-family: 'Basier Circle';
  font-size: 15px;
  border: 1px solid #000;
  padding: 10px 15px;
  width: 100%;
  margin: 0 0 30px 0;
  min-height: 230px;
}

.navi ul {
  padding: 0;
}

.navi li {
  padding: 14px 20px;
  border: 4px solid #aaa;
  float: left;
  margin-right: -4px;
}

.navi li:last-child {
  border-right: 4px solid #aaaaaa;
}

.navi a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #aaa;
  list-style: none;
}

.navi3 a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #aaa;
  list-style: none;
}

.navi3 li:active a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.navi3 li.active a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.navi3 li.active {
  position: relative;
  padding: 8px 20px;
  border: 4px solid #000;
  z-index: 999;
}

.navi3 li:active {
  border: 4px solid #000;
  z-index: 999;
  position: relative;
}

.navi li:active {
  padding: 14px 20px;
  border: 4px solid #000;
  z-index: 999;
  position: relative;
}

.navi li:active:last-child {
  border-right: 4px solid #000;
  z-index: 999;
  position: relative;
}

.navi li:active a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.navi li.active a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.navi li.active {
  position: relative;
  padding: 14px 20px;
  border: 4px solid #000;
  z-index: 1;
}

.contacto .navi li {
  padding: 7px 20px;
}

.contacto .navi li:first-child {
  width: 181px;
  height: 52px;
}

.arrow4 {
  position: absolute;
  left: calc(50% - 8px);
  bottom: -11px;
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
}

.navi5 {
  margin: 10px 0 0 0;
  padding: 20px 0;
}


.arrow {

  position: absolute;
  left: 80px;
  bottom: -11px;

  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/*check box properties*/

.form-group input {
  padding: 0;

  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #000;

  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -7px;
}

.form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  width: 5px;
  height: 12px;
  border: 1px solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.navi1 {
  margin: 0;
  padding: 0;
}

.button.navbar-toggler {
  background: url("../public/assets/images/hamburg-white.png") no-repeat;
  color: #000;
  border: 0;
  float: right;
  padding: 0;
}

.black .button.navbar-toggler::after {
  content: "\f0c9";
  color: #000;
  font-family: "FontAwesome";
  font-size: 22px;
  border: 0;
  float: right;
  padding: 0;
  position: relative;
  top: -31px;
}

.navbar.tab-mob {
  display: none;
}

/*check box properties end*/
.tab-mob .sub-menu .navbar-nav,
.navbar.tab-mob .search-triangle-black {
  display: none;
}

.tab-mob .sub-menu.show .navbar-nav {
  display: block;
}

.mapouter1 {
  min-height: 1000px;
  width: 100%;
  position: relative;

}

.close1 {
  color: #fff;
  background: url("../public/assets/images/close.png") no-repeat;
  width: 22px;
  height: 22px;
  color: #fff;
  border: 0;
  transition-duration: 2s;
  transition-delay: 1s;
}

.bgcover {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  width: 300px;
  display: none;
  padding: 80px 40px 60px;
  transition-duration: 2s;
  transition-delay: 1s;
  opacity: 0.8;
}

.timetable-details {
  position: absolute;
  top: 30px;
}

.bgcover p {
  color: #fff;
  font-family: 'Basier Circle';
  font-size: 14px;
}

.bgcover button {
  margin-bottom: 40px;
}

.bgcover.showbgcover {
  display: block;
}

.tiendas .subheading {
  font-weight: normal;
  width: 80%;
}

.tiendas .titleformob .A-la-venta-esta-sema,
.tiendas .titleformob .line4,
.address-list,
.address-timing {
  display: none;
}

.tiendas .btn1 {
  margin-top: 20px;
}


.tiendas .line6 {
  margin: 0px !important
}

.tiendas .maxHeight {
  max-height: 59vh;
}

.tiendas .maxHeightScroll {
  max-height: 85vh;
  overflow-y: scroll;
}

.tiendas .maxHeightHide {
  overflow: hidden;
  height: calc(100vh - 115px);
}

/*Bootstrap Collapsible Panel With Up/Down Arrow Icon*/

.panel-heading.active a:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.close1 .button#hide {
  color: #fff;
  background: url("../public/assets/images/times-solid.svg") no-repeat;
  width: 16px;
  height: 16px;
  color: #fff;
}



.accordion .card-header:after {
  content: url("../public/assets/images/chevron-down-solid.svg");
  float: right;


}

.icon-flot {
  float: right;
}

/*Bootstrap Collapsible Panel With Up/Down Arrow Icon end*/
.card-title1 span {
  font-family: 'Basier Circle';
  margin: 6px 15px 0 0;
  float: right;
  font-weight: normal;
  font-size: 12px;
  text-align: right;
}

.card-title1 span.span-hide {
  display: none;
}

.col_wrap {
  border: 4px solid #000;
  padding: 10px 22px;
  border-radius: 0;
  cursor: pointer;
  margin: -4px 0 0 0;
}

.col_wrap_2 {
  border: 4px solid #000;
  padding: 7px 22px;
  border-radius: 0;
  cursor: pointer;
  margin: 0 0 0 0;
  height: 52px;
}

.col_wrap[aria-expanded="true"] {
  border: 2px solid #0282f9;
  height: 50px;
}

.card-title1 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  border-radius: 0;
}

.card-title1:hover {
  text-decoration: none;
}

.accordion>.card1 {
  overflow: visible;
  border-radius: 0;
}

.card-body1 {
  transition: all .5s linear;
}

.ico_rht {
  float: right;
  padding: 5px 0;
}


.rotate {
  -moz-transition: all .5s linear;
  -webkit-transition: all .5s linear;
  transition: all .5s linear;
  float: right;
  padding: 7px 0;


}

.btn-11-12-wrap a {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

a.btn-11 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  background: #e2f1ff;
  border: 3px solid #0282f9;
  padding: 5px 25px;
  color: #0282f9;
}

a.btn-12 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  border: 3px solid #000;
  padding: 5px 25px;
  color: #000;
}

.btn-side a {
  border: 4px solid #000;
  padding: 5px 20px;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  float: right;
  margin: 30px 0;
}

.btn-11-12-wrap {
  display: table;
}

.notebtn a {
  background: #e2f1ff;
  border: 3px solid #0282f9;
  color: #0282f9;
  font-family: 'Basier Circle';
  font-weight: bold;
  padding: 5px 20px;
  margin: 20px 0 0 0;
  display: table;
}

.col_wrap_3 {
  border: 4px solid #000;
  padding: 10px 22px;
  border-radius: 0;
  cursor: pointer;
  margin: -4px 0 0 0;
}

.check-5 img {
  position: absolute;
  left: -72px;
  padding: 0 !important;
  border: 0 !important;
}

.check-step-2 img {
  position: absolute;
  left: -72px;
  padding: 0 !important;
  border: 0 !important;
  top: -30px;
}

.edit-1 {
  border-color: #0282f9 !important;
}

.edit {
  color: #0282f9;
  border-color: #0282f9;
  margin-bottom: 4px;
}

.check-2 {
  position: absolute;
  left: 0;
}

.edit .card-title1 {
  color: #0282f9;
}

.edit-res {
  position: relative;
}

.wrap-colp {
  padding: 20px;
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  border-bottom: 4px solid #000;
  margin: -4px 0 0 0;
}

.down1 {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.collapse-details:first-child {
  margin: 40px 0 0 0;
}

.navi2 {
  margin: 40px 0 0 0;
}

.navi2 ul {
  padding: 0;
}

.navi2 ul li {
  position: relative;
  padding: 8px 15px;
  border: 4px solid #aaa;
  margin-top: -4px;
  width: 252px;
}

.navi2 li a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #aaa;
  list-style: none;
  padding: 0;
  margin-left: 0 !important;
}

.arrow2 {
  position: absolute;
  right: -11px;
  top: calc(50% - 7px);
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
  -moz-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.navi2 li a.active {
  padding: 0;
  margin-left: 0 !important;
}

.navi2 .nav>li>a {
  padding: 0 !important;
}

.navbar.navi2 {
  padding-left: 0;
}

.navi2 li.active a {
  color: #000;
}

.navi2 ul li.active {
  position: relative;
  padding: 8px 15px;
  border: 4px solid #000;
  margin-top: -4px;
  z-index: 1;
}

.navi2 ul li.active2 {
  position: relative;
  padding: 8px 15px;
  border: 4px solid #000;
  margin-top: -4px;
  z-index: 1;
}

.navi2 ul li.active2 a {
  color: #000;
}

.ayuda .nav-tabs>li a.active {
  padding: 0;
  border: 0 !important;
  background-color: transparent !important;
}

/*inner Page end*/
.textare-step4 h2 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

.textare-step4 p {
  font-family: 'Basier Circle';
  font-weight: normal;
  font-size: 18px;
  color: #000;
}

.textare-step4 textarea {
  width: 450px;
  padding: 15px;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
}

.textare-step4 {
  padding: 40px 0;
}

.condition-error {
  background-color: #ffeef5;
  color: #f9026a;
  font-size: 14px;
  padding: 15px;
  height: 73px;
  margin-top: 10px;
}

.condition-error p {
  margin-bottom: 0;
}

.lftcontent {
  float: left;
  width: 70%;
}

.rhtcontent {
  float: right;
  width: 30%;
}

.arrow3 {
  background: url("../public/assets/images/arrow_3.png") no-repeat;
  position: absolute;
  position: absolute;
  top: 0px;
  left: -13px;
  z-index: 999;
  height: 25px;
  width: 13px;
}

ul.breadcrumb {
  padding: 0px 0px 10px;
  list-style: none;
  background: none;
  margin-bottom: 0;

}

ul.breadcrumb li {
  display: inline;
  font-size: 12px;
}

ul.breadcrumb li+li:before {
  padding: 4px;
  color: black;
  content: "/\00a0";
}

ul.breadcrumb li a {
  font-family: 'Basier Circle';
  color: #000;
  text-decoration: none;
  text-transform: capitalize;
}

ul.breadcrumb li a:hover {
  font-family: 'Basier Circle';
  color: #000;
  text-decoration: underline;
}

ul.small-bread {
  padding: 0px 0 0 0;
  list-style: none;
  background: none;
  margin: 0;

}

ul.small-bread li {
  display: inline;
  font-size: 12px;
}

ul.small-bread li+li:before {
  padding: 4px;
  color: black;
  content: "/\00a0";
}

ul.small-bread li a {
  font-family: 'Basier Circle';
  color: #000;
  text-decoration: none;
}

ul.small-bread li a:hover {
  font-family: 'Basier Circle';
  color: #000;
  text-decoration: underline;
}



input.textfield {
  width: 100%;
  padding: 8px 16px;
  border-radius: 0;
  font-family: 'Basier Circle';
  color: #000;
  font-size: 15px;
  border: 1px solid;

}

.agenda .A-la-venta-esta-sema {
  margin: 7px 0 0 0;
}

.navi3 li.active {
  position: relative;
  border: 4px solid #000;
  z-index: 1;
}

.navi3 li {
  padding: 8px 14px;
  border: 4px solid #aaa;
  float: left;
  margin-right: -4px;
  text-align: center;
  height: 52px;
  /*width: 130px;*/
}

input.textfield:focus-visible {

  border-radius: 0;


}

.icosearch {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 12px;
  top: 10px;

}

.radio1edcion h4 {
  font-family: 'Basier Circle';
  font-size: 13px;
  font-weight: bold;
  margin: 30px 0 20px 0;
  color: #000;
}

.search {
  position: relative;
  margin-top: 40px;
}

.drowdown label {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.drowdown {
  float: right;
  margin-left: 20px;
}

.drowdown-2 label {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.drowdown-2 {
  float: right;
  margin-left: 20px;
  width: 170px;
}

.drop {
  z-index: 999;
}

.drop1 {
  width: 192px;
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 0;
  font-family: 'Basier Circle';
  color: #000;
  font-size: 15px;
}

.drop1:active {
  border-radius: 0;
}

.drop-2 {
  width: 192px;
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 0;
  font-family: 'Basier Circle';
  color: #000;
  font-size: 15px;
}

.drop-2:active {
  border-radius: 0;
}


.drop2 {
  width: 192px;
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 0;
  font-family: 'Basier Circle';
  color: #000;
  font-size: 15px;
}

.drop2:active {
  border-radius: 0;
}

.drop3 {
  width: 192px;
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 0;
  font-family: 'Basier Circle';
  color: #000;
  font-size: 15px;
}

.drop3:active {
  border-radius: 0;
}

.dropdown-select {
  outline: none;
  border: none;
}

option {
  font-family: 'Basier Circle';
  font-size: 15px;
}

option:checked {
  background: #000;
  color: #fff;
}

option:focus {
  background: #000;
  color: #fff;
}

select {
  font-family: 'Basier Circle';
  font-size: 15px;
  background-color: white;
  /* inline SVG */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 0px;
  border: none;
  color: #ffffff;
  padding: 10px 30px 10px 10px;
  width: 192px;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #000;
}


.option:nth-child(1) input[type="radio"]:checked {
  background-color: #000;
  border-radius: 4px 4px 0 0;
}

.container.cont-rht {
  padding: 0;
  margin: 0;
}

.position1 {
  position: absolute;
  top: -8px;
}

.cont-rht ul {
  margin: 0;
  padding: 0;
}

.cont-rht li {
  float: left;
  margin: 40px 0 0 20px;
  width: 145px;
}

.cont-rht li img {
  width: 145px;
  margin: 0 0 20px 0;
}

.container.cont-rht-2 {
  padding: 0;
  margin: 0;
}

.product .ant-pagination.pagination {
  margin-left: 85px;
}

.product .cont-rht .col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
  cursor: pointer;
  padding-left: 0;
}

.product.content .cont-rht .col-2 {
  flex: 0 0 25%;
  max-width: 25%;
  cursor: pointer;
  padding-left: 0;
}

.product.biblioteca .cont-rht .col-2 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  cursor: pointer;
  padding-left: 0;

}

.product .cont-rht .col-2:nth-child(5n) {
  /*padding-right: 0;*/
}

.product .cont-rht .col-2 h2 {
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  margin-bottom: 0;
}

.radio1edcion .check_radio {
  margin-bottom: 7px;
}

.filter-inner-wrapper {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-search {
  background: transparent;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  height: 42px;
  margin-bottom: 10px;
}

.col-9.product-div,
.producttitlerow .col-3,
.producttitlerow .col-4 {
  padding-right: 0;
  padding-left: 0;
}

.producttitlerow .row,
.cont-wrap-cover.product .row {
  margin-right: 0;
  margin-left: 0;
  align-items: flex-end;
}

.producttitlerow .col-3 {
  flex: 0 0 22%;
  max-width: 22%;
}

.producttitlerow .col-4 {
  flex: 0 0 36.3%;
  max-width: 36.3%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.col-3.filter-div {
  padding-left: 0;
}

.product .cont-rht .col-2 label.nrml-text {
  width: 100%;
}

.product .cont-rht .col-2 .authors-wrapper {
  height: 90px;
  margin-top: 10px;
  overflow: hidden;
}

.product .cont-rht .col-2 img {
	width: 100%!important; 
    height: auto!important;
    min-height: auto!important;
    /* object-fit: cover;*/
}

.product .cont-rht .col-2 .img-wrapper {
  width: 100%;
  height: 248px;
  margin: 0 0 20px 0;
  position: relative;
}

.product .cont-rht .col-2 .img-wrapper img {
  transition: transform .5s ease;
}

.product .cont-rht .col-2 .img-wrapper:hover img {
  transform: scale(1.5);
}

.cont-rht-2 ul {
  margin: 0;
  padding: 0;
}

.cont-rht-2 li {
  float: left;
  margin: 40px 0 0 10px;
  width: 178px;
  position: relative;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-item {
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

.ant-pagination {
  font-family: 'Basier Circle' !important;
}

.ant-pagination-item.ant-pagination-item-active {
  border: 1px solid #000 !important;
}

.ant-pagination-item-link,
.ant-pagination-item {
  border: 0 !important;
}

.ant-pagination-options {
  display: none !important;
}

.ant-pagination-item-active a {
  font-size: 14px;
  font-weight: bold;
}

.cont-rht-2 li img {
  width: 100%;
  margin: 0 0 15px 0;
  height: 277px;
}

.favoritos .cont-rht-2 .row {
  margin: 0;
  padding: 0;
  margin-right: -20px;
}

.favoritos .cont-rht-2 .col-2 {
  margin: 0px 0 60px 0px;
  position: relative;
  padding-left: 0;
  padding-right: 20px;
  flex: auto;
  max-width: 20%;
}

.favoritos .cont-rht-2 .col-2 img {
  width: 100%;
  margin: 0 0 15px 0;
  height: 305px;
  object-fit: cover;
}

.favoritos .cont-rht-2 .col-2 .close-2 img {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}

.favoritos .cont-rht-2 {
  margin-top: 40px;
}

.favoritos .cont-rht-2 h2 {
  font-weight: normal;
  min-height: 40px;
}

.favoritos .cont-rht-2 h2 a {
  color: #000
}

.favoritos .cont-rht-2 h2 a:hover {
  text-decoration: none;
}

.favoritos p span {
  font-size: 15px;
}

.favoritos .container-pagination {
  display: flex;
  justify-content: space-between;
}

.cont-rht-4 ul {
  margin: 0;
  padding: 0;
}

.cont-rht-4 li {
  float: left;
  margin: 40px 0 0 10.4px;
  width: 145px;
  position: relative;
}

.cont-rht-4 li img {
  width: 145px;
  margin: 0 0 20px 0;
}

.container.cont-rht-3 {
  padding: 0;
  margin: 0;
}

.container.cont-rht-11 {
  margin-top: 30px;
  height: auto;
}

i.close-2 {
  position: absolute;
  top: 5px;
  right: 26px;
  padding: 3px 8px;
  background: #fff;
  color: #000;
}

.cont-rht-2 li .close-2 img {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}

.cont-rht-3 ul {
  margin: 0;
  padding: 0;
}

.cont-rht-3 li {
  float: left;
  margin: 40px 0 0 10.8px;
  width: 150px;
}

.cont-rht-3 li img {
  width: 100%;
  margin: 0 0 20px 0;
}

.heading-cont-left-2 {
  float: left;
  width: 80%;
}

.cont-rht-11 .bibli-img {
  width: 166px;
  height: 260px;
}

.cont-rht-11 .bibli-img img {
  width: 100%;
  height: 100%;
}

/*radion button*/
.calender img {
  width: 100%;
}

.calender h4 {
  font-family: 'Basier Circle';
  font-size: 13px;
  font-weight: bold;
  margin: 30px 0 20px 0;
  color: #000;
}

.radio1edcion p {
  margin: 0;
}

.radio1edcion2 p {
  margin: 0;
}

.radio1edcion2 {
  margin: 15px 0 0 0;
}

.calender {
  margin: 15px 0 0 0;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  font-family: 'Basier Circle';
  font-size: 15px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000;
  width: 100%;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #000;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cont-rht h2 {
  font-family: 'Basier Circle';
  font-size: 15px;
  line-height: 21px;
}

.cont-rht label {
  margin: 10px 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.cont-rht span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
  font-size: 15px;
}

.cont-rht-2 h2 {
  font-family: 'Basier Circle';
  font-size: 15px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cont-rht-2 h3 {
  font-family: 'Basier Circle';
  font-size: 12px;
  line-height: 21px;
}

.cont-rht-2 label {
  margin: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.cont-rht-2 span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
}

.cont-rht-4 h2 {
  font-family: 'Basier Circle';
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0 10px 0;
}

.cont-rht-4 label {
  margin: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.cont-rht-4 span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
}

.cont-rht-3 h2 {
  font-family: 'Basier Circle';
  font-size: 15px;
  line-height: 21px;
}

.cont-rht-3 label {
  margin: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.cont-rht-3 span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
}

.cont-rht-11 h2 {
  font-family: 'Basier Circle';
  font-size: 15px;
  line-height: 21px;
}

.cont-rht-11 label {
  margin: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.cont-rht-11 span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
}

.page-item.active .page-link {
  z-index: 3;
  color: #000;
  background-color: #fff;

  border: 1px solid #000;
}

.spacer {
  height: 50px;
}

.pagination {

  margin: 50px 0 0 0;
  float: left;
  margin-right: 0;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.pagination-sm .page-link {
  padding: 0.40rem 0.8rem;
  font-size: .875rem;
  line-height: 1.5;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: none;
}

.navbar-expand-md .navbar-nav {
  flex-direction: column;
  padding: 0 12px 0 0;
  display: flex;
}


button:focus {
  outline: 0;
  outline: 0;
}

.header_wrap {
  /*border-bottom: 1px solid #000;*/
  display: table;
}

.small-nav {
  float: right;
  position: relative;
  z-index: 10;
}

.small-nav li {
  float: left;
  margin: 0 0 0 15px;
  padding-bottom: 20px;
}

.small-nav li a {
  font-family: 'Basier Circle';
  font-size: 12px;
  color: #000;
}

.nav-inner ul {
  padding: 0;
  margin: 0;
}

.nav-inner ul li {
  float: left;
  padding: 5px 15px 20px 15px;
  line-height: 25px;
}

.nav-inner ul li a {
  font-family: 'Basier Circle';
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  font-size: 14px;
  color: #000;
}

.major-nav .navbar {
  padding: 0;
  margin: 0;
}

.white-header .social2 ul li a {
  color: #fff;
}

.social2 ul li a {
  color: #000;
  padding: 0;
  margin: 0
}

.social2 ul {
  display: table;
  padding: 0;
  margin: 0
}

.social2 ul li {
  float: left;
  padding: 0;
  margin: 0
}

.social3 ul li a {
  color: #000;
  padding: 0;
  margin: 0;
}

.social3 ul {
  display: table;
  padding: 0;
  margin: 0;
}

.social3 ul li {
  float: left;
  padding: 0;
  margin: 0;
}

.social3 .search-icon {
  position: relative;
  z-index: 10;
}

.logouticon {
  height: 11px;
  width: 11px;
  font-size: 11px !important;
  margin-right: 5px;
}

.dropdown.personMenuDesktop:hover .dropdown-menu {
  display: block;
}

.dropdown.personMenuDesktop .dropdown-menu {
  padding: 30px 40px 40px;
  background: #000;
  position: absolute !important;
  top: 56px !important;
  left: -150px !important;
  position: relative;
}

.is-sticky-home .dropdown.personMenuDesktop .dropdown-menu {
  top: 40px !important;
}

.is-sticky-home .dropdown.personMenuDesktop .search-triangle-black {
  right: 60px;
}

.dropdown.personMenuDesktop .dropdown-menu.login_menu {
  padding: 20px 40px 20px;
  left: -82px !important;
}

.dropdown.personMenuDesktop .search-triangle-black {
  right: 50px;
}

.dropdown.personMenuDesktop .dropdown-menu .dropdown-item {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
  line-height: 13px;
  text-align: left;
}

.dropdown-menu a {
  color: #fff !important;
}

.dropdown.personMenuDesktop .dropdown-menu .dropdown-item:hover {
  background: none;
}

.dropdown.personMenuDesktop .dropdown-menu .dropdown-item.logout {
  margin-left: -12px !important;
}

.dropdown.personMenuDesktop .dropdown-menu .dropdown-item.useremail {
  margin-bottom: 20px;
  text-transform: unset;
  font-weight: normal;
}

.user_menu_triangle {
  width: 10px;
  height: 10px;
  border-bottom: solid 11px #000;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
  position: absolute;
  top: 48px;
  right: 8px;
  display: none;
}

.personMenuDesktop:hover .user_menu_triangle {
  display: inline;
}

.usermenu {
  background-color: #000 !important;
}

.usermenu .ant-dropdown-menu-title-content {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.ant-dropdown {
  min-width: 54px;
  left: 1120px;
  top: 113px;
  background: #000;
  padding: 30px 40px 40px;
}

.social4 {
  clear: both;
}

.social4 ul li a {
  color: #000;
  padding: 0;
  margin: 0;
}

.social4 ul {
  display: table;
  padding: 0;
  float: right;
  margin: 0;
}

.social4 ul li {
  float: left;
  padding: 0;
  margin: 0;
}

.socialico5.favrouitechecked {
  color: #0282f9 !important
}

.social2 {
  float: left;
}

.box5 {
  font-size: 21px !important;
  border: 1px solid #000;
  padding: 8px;
  margin: 0 0 0 15px;
  vertical-align: top;
  color: #000;
}

.socialico5 {
  font-size: 20px !important;
  margin-left: 16px;
  margin-top: 4px;
}

.socialico5 .checked {
  font-size: 20px;
  margin-left: 13px;
  margin-top: 4px;
}

.social-icon-prod-tab .socialico5 {
  margin-right: 13px;
  margin-left: 0;
}

.major-nav {
  float: left;
}

.social3 {
  float: right;
}

.social3 ul li {
  cursor: pointer;
  padding-bottom: 20px;
}

.social2 {
  float: right;
  padding: 3px 10px;
  position: relative;
}

.button2 {

  color: #000;
  border: 0;
  float: right;
  padding: 0;
}

.postion {
  float: right;
  display: block;
}

.heading-left {
  float: left;
  width: auto;
  margin-top: 60px;
}

.serch-right {
  float: right;
  width: auto;
  margin-top: 60px;
}

.navigation-center {
  float: left;
  width: auto;
  margin-left: 40px;
  margin-top: 60px;
}

.serch-right select {
  width: 192px;
}

.drowdown2 label {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.drowdown2 {
  float: right;
  margin-left: 20px;
}

.cont-w li {
  width: 16%;
  float: left;
}

.img-w img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.event-wrapper.col-2 {
  margin-bottom: 50px;
  padding-right: 5px;
  padding-left: 0px;
}

.event-wrapper.col-2:nth-child(6n) {
  padding-right: 0px;
}

.event-wrapper .event-title,
.connoticias1 .event-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 39px;
  margin-bottom: 0;
}

.event-wrapper .event-text,
.connoticias1 .event-text {
  font-size: 15px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 45px;
  color: #000;
  font-size: 15px;
}


.month-mob {
  display: none;
}

.cont-w {
  margin-top: 50px;
}

.cont-w .row {
  margin-right: 0;
  margin-left: 0;
}

.cont-w a:hover {
  text-decoration: none;
}

.cont-w p {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
  margin: 10px 0 0 0;
  padding: 0 10px 0 0;
}

.date-w {
  width: 61px;
  height: 61px;
  background: #fff;
  padding: 10px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.date-w h6 {
  font-family: 'Basier Circle';
  font-size: 10px;
  padding: 0;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
}

.date-w p {
  font-family: 'Basier Circle' !important;
  ;
  font-weight: bold;
  font-size: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
  line-height: 28px;
}

.drowdown_5 {
  float: left;
  width: 100px;
  margin: 0 15px 0 0;
}

.price_tag {
  float: left;

  margin: 5px 15px 0 0;
}

select#cars {
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
}

select#cars1 {
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  font-size: 15px;
}

select.eventfilter {
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  font-size: 15px;
}

.serch-right select:focus {
  outline: 0;
  box-shadow: unset;
}

/*Popup*/




/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7);
  /* Black w/ opacity */
}

.modal1 {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 2px;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: none;
  background-color: none;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 5px solid #000;
  width: 60%;
  border-radius: 0;
}

.modal-content1 {
  background-color: #e2f1ff;
  padding: 40px;
  border: 0;
  width: 70%;
  float: right;
  border-radius: 0;
  position: relative;
}

.model-lft-content {
  float: left;
  width: 18%;
  margin-right: 20px
}

.model-rht-content {
  float: right;
  width: 80%;
}

.model-lft-content h2 {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Basier Circle';
  font-weight: bold;
  text-transform: uppercase;
}

.model-lft-content ul {
  margin: 0;
  padding: 0;
}

.model-lft-content ul li {
  border-bottom: 1px solid #aaa;
}

.model-lft-content ul li a {
  font-family: 'Basier Circle';
  font-size: 14px;
  display: table;
  padding: 6px 0;
  color: #000;
}

.model-rht-content h2 {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Basier Circle';
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 0 10px;
}

.model-rht-content ul {
  margin: 0;
  padding: 0;
}

.model-rht-content-1 {
  float: right;
  width: 80%;
}

.model-lft-content-1 h2 {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Basier Circle';
  font-weight: bold;
  text-transform: uppercase;
}

.model-lft-content-1 ul {
  margin: 0;
  padding: 0;
}

.model-lft-content-1 ul li {
  border-bottom: 1px solid #aaa;
}

.model-lft-content-1 ul li a {
  font-family: 'Basier Circle';
  font-size: 14px;
  display: table;
  padding: 6px 0;
  color: #000;
}

.model-rht-content-1 h2 {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Basier Circle';
  font-weight: bold;
  text-transform: uppercase;
}

header li a:hover,
.small-nav a:hover,
.nav-inner a:hover {
  text-decoration: none;
  ;
}

/* The Close Button */
.close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal2 {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content2 {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 5px solid #000;
  width: 60%;
  border-radius: 0;
}

/* The Close Button */
.close2 {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}

.close2:hover,
.close2:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-2 {
  padding: 30px 45px;
  display: table;
}

.img-pop-wrap {
  width: 100%;
}

.img-pop-wrap img {
  width: 100%;
}

.pop-rht {
  width: 60%;
}

.pop-lft {
  width: 35%;
  margin-right: 5%;
  position: relative;
}

.container-fluid.popup {
  padding: 40px 80px;
}

.pop-rht p {
  font-size: 19px;
  line-height: 26px;
  font-family: 'Basier Circle';
}

.popup-btn {
  margin: 90px 0 0 0
}

.popup-btn li {
  float: left;
  margin-right: 20px;
}

.popup-btn li:last-child {
  margin-left: 0px;
}

.popup-btn li a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border: 4px solid #000;
  color: #000;
  padding: 7px 20px;
}

.popup-btn ul {
  padding: 0;
  margin: 0;
  display: table;
}

.wrap-pop-date {
  width: 250px;
  padding: 20px;
  background: #fff;
  position: absolute;
  top: 30px;
  left: 30px;
}

.wrap-pop-date h3 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

.wrap-pop-date p {
  font-family: 'Basier Circle';
  font-size: 18px;
  margin: 0;
}

.banner-wrap {
  /*background: url(""); background-size: cover; background-repeat: no-repeat;*/
  background-position-y: -140px;
  margin: 0;
  padding: 0;
  height: 750px;
  position: relative;
}

.header_wrap1 {
  border-bottom: 1px solid #000;
  display: table;
  position: absolute;
  top: 0;
}

.nav-color ul li a {
  color: #fff;
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.ecc_comics_news_body_wrapper .slick-track{
  width: 100% !important;
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.slider11 .wrap-co h2 {
  font-family: 'Basier Circle';
  font-size: 15px;
  line-height: 21px;
}

.slider11 .wrap-co label {
  margin: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.slider11 .wrap-co span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
}

.slider11 {
  padding: 0;
  margin: 0;
}

.slider11 .wrap-co {
  float: left;
  margin: 40px 0 0 24.2px;
  width: 145px !important;
}

.slider11 .wrap-co:first-child {
  margin: 40px 0 0 0px;
}

.slider11 .wrap-co {
  float: left;
  margin: 40px 0 0 22px;
  width: 145px !important;
}

.slider11 .wrap-co img {
  width: 145px;
  margin: 0 0 20px 0;
}

.slider11 .slick-track {
  margin: 0;
  padding: 0;
}

.slider11 .slick-slide {
  width: 150px !important;
}

.heading_lft {
  float: left;
  width: 25%;
  margin-right: 5%;
}

.heading_rht {
  float: right;
  width: 70%;
}

.heading_lft_cont {
  float: left;
  width: 22%;
  margin-right: 4%;
}

.heading_rht_cont {
  float: right;
  width: 74%;
}

.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0 !important;
}

.cont_form ul {
  padding-left: 0;
  margin-bottom: 0;
}

.cont_form li h3 {
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #000;
  font-size: 14px;
}

.cont_form li p {
  font-family: 'Basier Circle';
  color: #000;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 15px;
  line-height: 25px;
}

.cont_form.address ul li {
  margin-bottom: 30px;
}

.cont_form.address ul li:last-child {
  margin-bottom: 0px;
}

.order-details-add p {
  margin-top: 20px;
}

.order-details-add .radButtons {
  display: flex;
  margin-bottom: 10px
}

.order-details-add .radButtons>div {
  width: 50%;
  display: flex;
  align-items: center;
}

.order-details-add .radButtons .radio {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.btn2_cont.detail {
  width: 179px;
  height: 39px;
  margin-top: 10px;
}

.btn2_cont_btn {
  border: 3px solid #000;
  padding: 5px 10px;
  width: 200px;
  border-radius: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  color: #000;
  list-style: none;
  text-align: center;
  margin: 5px 0;
}

.btn2_cont_btn:hover {
  border: 3px solid #e2f1ff;
  color: #0282f9;
  background: #e2f1ff;
}

.validation_email_btn {
  margin-top: 30px;
  margin-bottom: 40px;
}

.table-form {
  overflow: scroll;
  width: 100%;
  font-family: 'Basier Circle';
  margin: 0 0 40px 0;
  font-size: 15px;
}

.table-form tr th {
  border: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  padding: 0 0 10px 0;
  font-size: 14px;
}

.table-form tr td {
  border: 1px solid #000;
  padding: 15px;
}

.table-form tr:first-child {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.table-form tr td:last-child {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  padding: 0 0 0 20px;
  margin: 5px 0 0 0;
  width: 100px;
  font-size: 14px;
  display: grid;
}

.pedidos .dudas {
  width: 200px;
  float: right;
  text-align: left;
}

.table-form tr td:first-child {
  font-weight: bold;
  width: 35%;
}

.order-details-tr {
  pointer-events: none;
  cursor: default;
}

.order-details-tr td:last-child {
  pointer-events: auto;
  cursor: pointer;
}

.table-form tr td a {
  font-family: 'Basier Circle';
  font-weight: bold;
  border: 4px solid #000;
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  text-align: center;
  width: 100px;
  height: 39px;
}

.table-form-wrap tr td a {
  font-family: 'Basier Circle';
  font-weight: bold;
  border: 4px solid #000;
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  text-align: center;
  display: block;
}

.table-form-wrap {
  overflow: scroll;
  width: 100%;
  font-family: 'Basier Circle';
  margin: 0 0 40px 0;
  font-size: 15px;
}

.table-form-wraptr th {
  border: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  padding: 0 0 10px 0;
  font-size: 14px;
}

.table-form-wrap tr td {
  border: 1px solid #000;
  padding: 10px 15px;
}

.table-form-wrap tr td:last-child {
  padding: 10px 0;
}


.table-form-wrap tr td:first-child {
  font-weight: bold;
  width: 45%;
}

.table-form-wrap tr td a:hover {
  background: #e2f1ff;
  border: 4px solid #e2f1ff;
  color: #0282f9;
  text-decoration: none;
}


.table-form-2 {
  overflow: scroll;
  width: 100%;
  font-family: 'Basier Circle';
  margin: 0 0 40px 0;
  font-size: 15px;
}

.table-form-2 tr th {
  border: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  padding: 0 0 10px 0;
  font-size: 14px;
}

.table-form-2 tr td {
  border: 1px solid #000;
  padding: 10px;
}

.table-form-2 tr:first-child {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.table-form-2 tr td:first-child {
  padding: 5px;
  margin: 0;
  font-weight: bold;
  width: 0%;
}

.table-form-2 tr td a {
  font-family: 'Basier Circle';
  font-weight: bold;
  border: 4px solid #000;
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  text-align: center;
}

.table-form-2 tr td:last-child {

  width: 15%;
}

.table-form-2 img {
  width: 100%;
}

.table-form-2 p {
  padding: 0;
  margin: 6px 0;
  font-family: 'Basier Circle';
  font-weight: normal;
  font-size: 15px;
}


hr {
  border-top: 1px solid #000;
}


.table-form-3 {
  overflow: scroll;
  width: 100%;
  font-family: 'Basier Circle';
  margin: 0;
  font-size: 15px;
  margin-top: 20px;
}

.table-form-3 tr th {
  border: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  padding: 0 0 10px 0;
  font-size: 14px;
  text-transform: uppercase;
}

.table-form-3 tr td {
  border: 1px solid #000;
  padding: 15px;
  text-align: center;
}

.table-form-3 tr:first-child {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.table-form-3 tr td:first-child {
  padding: 5px;
  margin: 0;
  font-weight: bold;
  width: 80px;
  text-align: center;
}

.table-form-3 tr td:nth-child(2),
.table-form-3 tr td:nth-child(3) {
  text-align: left;
  width: 45%;
}

.table-form-3 tr td:last-child {

  width: 15%;
  text-align: left;
}

.table-form-3 tr td:nth-child(3) br {
  display: none;
}

.info-select img {
  margin: -5px 7px 0px 33px;
}

.info-select a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  border: 3px solid #000;
  color: #000;
  padding: 5px 20px;
  float: right;
  margin: 50px 0 80px 0;
}

.link_1 a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  float: right;
  color: #000;
  margin: 20px 0;
}

.info-select p {
  color: #0282f9;
  font-family: 'Basier Circle';
  margin: 0;
}

.note h3 {
  color: #0282f9;
  font-size: 14px;
  font-family: 'Basier Circle';
  margin: 0;
  padding: 0;
}

.note p {
  color: #0282f9;
  font-size: 12px;
  font-family: 'Basier Circle';
  margin: 0;
  padding: 0;
}

.right-arrow {
  float: left;

}

.table-form-3 img.cross,
.table-form-3 img.cross1 {
  width: 10px;
  float: right;
  cursor: pointer;
  margin-top: 6px;
}

.left-arrow {
  float: left;

}

.quan_arrow_back {
  position: absolute;
  top: 55px;
  left: 6px;
  cursor: pointer;
}

.quan_arrow_frwd {
  position: absolute;
  top: 55px;
  right: 6px;
  cursor: pointer;
}

.table-form-3 tr td:nth-child(6) {
  position: relative;
}

.productimg {
  width: 70px;
}

input.field_1 {
  width: 45px;
  height: 43px;
  text-align: center;
  border: 1px solid;
}

.note {
  background: #e2f1ff;
  padding: 20px;
}

.input.field_1 {
  float: left;
}

.table-form-3 p {
  border: 0;
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
  margin: 0;
  font-weight: normal;
}

.table-form-3 a {
  border: 0;
  font-family: 'Basier Circle';
  font-size: 12px;
  color: #000;
  text-decoration: underline;
}

.table-form-3 span {
  background: url("../public/assets/images/pattern.png") repeat;
  margin-right: 10px;
}

.table-form-3 td:nth-child(2) {
  width: 400px;
}

.favoritos .pagination {
  margin-top: 30px;
}

.favoritos .link_cont {
  margin-top: 35px;
}

.favoritos .row,
.favoritos .col-5 {
  padding-right: 0px;
  padding-left: 0px;
}

.favoritos .row {
  margin-right: 0;
  margin-left: 0;
}

.favoritos .row .col-7 {
  padding-right: 0;
}

.subtotal {
  padding: 0 0 0 83px;
  text-align: left;
}

.blue-wrap {
  background: #e2f1ff;
  padding: 20px;
  margin-bottom: 40px;
}

.blue-wrap p {
  color: #0282f9;
  padding: 0;
  margin: 5px 0 0;
  font-size: 18px;
}

.heading-cont-left {
  float: left;
}

.wrap_1 {
  height: 3px;
  display: table;
  margin-top: 5px;
  border-bottom: 4px solid #000;
}

.editorial_wrap ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.editorial_wrap ul li {
  flex: 0 0 calc(50% - 10px);
  margin-top: 30px;
}

.editorial_wrap ul li img {
  width: 100%;
}

.editorial_wrap ul li.artey .under-img {
  opacity: 0.59;
}

.editorial_wrap ul li.whakoom .under-img {
  opacity: 0.4;
}

.editorial_wrap h3 {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  margin: 30px 0 11px 0;
}

.editorial_wrap p {
  font-family: 'Basier Circle';
  font-size: 18px;
  padding: 0;
  margin: 0 0 30px;
}

.editorial_wrap .over-img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.editorial_wrap .over-img.artey {
  width: 75%
}

.editorial_wrap .over-img.whakoom {
  width: 50%;
}

.editorial_wrap .over-img {
  width: 46%;
}

.editorial_wrap ul li>div {
  position: relative;
  width: 100%;
}

.registrar {
  width: 54%;
  margin: 0 auto;
}

.registrar a {
  color: #000
}

.registrar a:active,
.registrar a:hover {
  color: #000
}

.registrar .form.name_container {
  display: flex;
  justify-content: space-between;
}

.registrar .form.name_container .form_name {
  width: 49%;
}

.registrar .form.name_container .form_surname {
  width: 49%;
}

.libreros-container {
  margin-top: 156px;
  margin-bottom: 130px;
}

.cont_form_2 {
  margin: 40px 0 0 0;
}

.libreros-container .form input {
  margin-bottom: 30px;
}

.cont_form_2 h3 {
  font-family: 'Basier Circle';
  font-size: 14px;
  margin: 30px 0;
}

.cont_form_2 h5 {
  font-family: 'Basier Circle';
  font-size: 22px;
  margin: 30px 0;
  font-weight: bold;
}

.cont_form_2 h4 {
  font-family: 'Basier Circle';
  font-size: 15px;
}

.cont_form_2 p {
  font-family: 'Basier Circle';
  font-size: 14px;
}

.error-msg {
  background: #ffeef5;
  color: #f9026a;
  padding: 13px 20px;
  position: absolute;
  top: 44px;
  width: 100%;
  font-size: 12px;
}

.error {
  border: 1px solid #f9026a;
}


.input-wrap {
  position: relative;
}

.input-wrap input[type="text"] {
  font-family: 'Basier Circle';
  font-size: 15px;

  padding: 10px 15px;
  width: 100%;
  margin: 0 0 30px 0;
}

.input-wrap input[type="text"]:focus-visible {
  border: 1px solid #f9026a;
}

input.blue {
  border: 1px solid #0282f9 !important;
  position: absolute;
}

.blue-msg {
  background: #e2f1ff;
  position: absolute;
  top: 52px;
  width: 100%;
}

.blue-wrap-pos {
  top: 0;
  position: relative;
  margin: 0 0 80px 0;
}

input.error {
  border: 1px solid #f9026a !important;
}

.email p {
  color: #0282f9;
  font-family: 'Basier Circle';
  font-size: 15px;
  font-weight: bold;
}

.edit img {
  width: 36px;
  border: 1px solid #0282f9;
  padding: 5px;
  margin: -8px 0 0 10px;
  cursor: pointer;
}

.blue-msg ul {
  float: left;
  padding: 10px;
  margin: 0 20px 0 0;
}

.blue-msg li {
  font-size: 12px;
  color: #0282f9;
  margin: 0;
}

.blue-msg img {
  margin-right: 5px;
}

.email input {
  border: 1px solid #0282f9;
  color: #0282f9;
  padding: 7px 10px;
}

.link-form {
  float: right;
}

.link-form a {
  font-family: 'Basier Circle';
  font-size: 14px;
  color: #000;
  float: right;
}


.de_lectura ul {
  padding: 0;
}

.de_lectura ul li:nth-child(3n+3) {
  margin: 30px 0 10px 0;
}

.de_lectura ul li {
  padding: 0;
  float: left;
  width: 31%;
  margin: 30px 3.5% 10px 0;
}

.de_lectura ul li img {
  width: 100%;
}

.de_lectura ul li p {
  padding: 0;
  font-family: 'Basier Circle';
  font-size: 15px;
}

.de_lectura ul li h2 {
  padding: 0;
  font-family: 'Basier Circle';
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.image_slider_releases h1 {
  font-family: 'Basier Circle';
  font-size: 15px;
  margin: 0;
}

.image_slider_releases p {
  font-family: 'Basier Circle';
  font-size: 30px;
  font-weight: bold;
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image_slider_releases .connoticias {
  padding: 20px 15px;
}

.image_slider_home_letura .slick-slide.slick-active.slick-current {
  margin: 0;
  padding: 0;
}

.lft-titulo {
  float: left;
  width: 70%
}

.rht-titulo {
  float: right;
  width: 30%;
}

.lft-titulo p {
  font-family: 'Basier Circle';
  font-size: 22px;
  font-weight: bold;
}

.rht-titulo a {
  border: 3px solid #000;
  padding: 6px 10px;
  float: right;
  font-family: 'Basier Circle';
  font-size: 14px;
  font-weight: bold;
  color: #000;
  width: 220px;
  text-align: center;
  margin: 20px 0 0 0;
}

.margin-w img {
  width: 100%;
}

.mer-filters-card h6 {
  text-align: center;
  font-family: 'Basier Circle';
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0;
}

.mer-filters-card {
  padding-left: 5px
}

.merchandise-space {
  margin-top: 50px;
}

.center-block {
  border-bottom: 4px solid #000;
}

ul.nav.nav-tabs.align-tab {
  margin: 0 auto;
  padding: 0;
  justify-content: center;
}

.nav>li {

  border: 1px solid #000;
  border-right: 0;

}

.sellos.nav>li:last-child:not(.active) {
  border-right: 1px solid #000;
  /*Wrong style ayuda menú HD & XXL */
}

.align-tab.sellos>li>a {
  padding: 10px 15px !important;
}

.nav-tabs>li>a {
  margin-right: 0;
  line-height: 1.42857143;
  border: 1px solid transparent !important;
  border-radius: 0;
}

li.active>a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 0;
}

.align-tab li a {
  font-family: 'Basier Circle';
  font-size: 17px;
  font-weight: bold;
}

.align-tab li a {
  color: #000;
}

/*.align-tab li a:hover {background: #fff;}*/
.nav-tabs {
  border-bottom: 0 !important;
}

.nav>li>a {
  padding: 10px 26px !important;
}

.catalogo .nav-tabs>li:hover a {
  background: #fff !important;
  border-bottom: 2px solid #fff !important;
}

.catalogo .nav-tabs>li a {
  margin-right: 0 !important;
  border-bottom: 0px solid !important;
  border-radius: 0;
}

.align-tab ul {
  border-bottom: 0;
  margin-right: 0 !important;
  padding: 0;
  border-bottom: 0px solid #fff !important;
}

.catalogo .nav-tabs>li a.active {
  border-top: 4px solid #000 !important;
  border-left: 4px solid #000 !important;
  border-right: 3px solid #000 !important;
  border-bottom: 0px;
  color: #000 !important;
  border-radius: 0;
}

/*.catalogo .nav-tabs>li>a.active, .nav-tabs>li>a.active:focus, .nav-tabs>li>a.active:hover{border-bottom:3px solid #fff !important; color: #000 !important; background: #fff !important;}*/
.center-block {
  border-bottom: 4px solid #000;
  position: absolute;
  bottom: -1px;
  z-index: -99;
  width: 100%;
}

.catalogo .nav-tabs>li.active>a:focus {
  background: #fff;
}

.nav-wrapper {
  position: relative;
  width: 100%;
}

.heading-wrap {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.heading-wrap p {
  font-family: 'Basier Circle';
  font-size: 18px;
  text-align: left;
  color: #000;
  margin: 20px 0;
}

.cont-wrap p {
  font-family: 'Basier Circle';
  font-size: 17px;
  text-align: left;
  color: #000;
  margin: 30px 0;
}

.cont-wrap ul {
  padding: 0;
  margin: 0;
}

.cont-wrap ul li {
  float: left;
  justify-content: center;
  width: 16.5%;
}

.cont-wrap ul li h2 {
  text-align: center;
  font-family: 'Basier Circle';
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
}

.cont-wrap ul li p {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.cont-wrap .row .col-2 h2 {
  text-align: center;
  font-family: 'Basier Circle';
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
}

.cont-wrap .row .col-2 p {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
}

.cont-wrap img {
  width: 100%;
}

.publishline.cont-wrap .col-2 {
  text-align: center;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 0;
  margin-bottom: 44px;
}

.publishline.cont-wrap .img-wrapper {
  position: relative;
  margin: 0 auto;
}

.cont-wrap .row .col-2 img {

  object-fit: cover;
}

.publishline h2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px !important;
}

.cont-wrap {
  display: table;
  padding: 0 0 30px 0;
  margin: 0 0 30px 0;
}

.inner-wrap li img {
  width: 100%;
}

.inner-wrap ul {
  margin: 0 auto;
  display: table;
  width: 100%;
  padding: 0 40px;

}

.table-form-3 tr td.img-wrapper img {
  width: 100%;
  cursor: pointer;
}

.inner-wrap li:last-child {
  margin-right: 0;
}

.inner-wrap li {
  float: left;
  width: 11%;
  margin-right: 1.7%;
}

.inner-wrap {
  margin: 80px 0 0 0;
  display: table;
}

.rights a:hover {
  text-decoration: none;
}

.rights .inner-wrap .col-2 img {
  width: 100%;
  object-fit: cover;
}

.rights .inner-wrap .col-2 .img-wrapper {
  height: 270px;
  position: relative;
}

.rights .inner-wrap .row {

  margin-left: 0;

}

.rights .inner-wrap .col-2:last-child {
  margin-right: 0;
}

.rights .inner-wrap .col-2 {
  flex: auto;
  padding-right: 20px;
  padding-left: 0;
  margin-bottom: 44px;
  cursor: pointer
}

.rights .ant-pagination.pagination {
  margin-bottom: 40px;
}

.inner-wrap p {
  font-family: 'Basier Circle';
  font-size: 15px;
  color: #000;
  margin: 16px 0;
}

.rights .inner-wrap p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cont-rht-4 img {
  width: 100%;
}

.facebook {
  float: left;
  width: 100px;
  background: #0282f9;
  margin-right: 20px;
  text-align: center;
  padding: 2px 0 5px;
  height: 39px;
}

.google {
  float: left;
  width: 100px;
  background: #f9a802;
  text-align: center;
  padding: 3px 0 5px;
  height: 39px;
}

.facebook img {
  width: 20px;
}

.google-login-btn,
.facebook-login-btn {
  border: 0;
  padding: 0;
  width: 100%;
  vertical-align: middle;
}

.google img {
  width: 20px;
}

.goo_face_wrap h3 {
  font-family: 'Basier Circle';
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.goo_face_wrap {
  border-top: 1px solid #000;
  margin: 50px 0 0 0;
}

.register-text {
  font-size: 14px !important;
}

.goo-face-lft {
  float: left;
  width: 45%;
  border-top: 1px solid #000;
}

.goo-face-rht {
  float: right;
  width: 45%;
  border-top: 1px solid #000;
}

.image_slider_20 {
  margin: 50px 0 0 0;
}

.margin_below {
  margin: 0 0 50px 0;
}

.cont_lft {
  float: left;
  width: 26%;
  margin-right: 2%;
}

.cont_rht {
  float: right;
  width: 70%;
}

.slider-for-2 .slick-slide>div {
  height: 100%;
}

.slider-for-2 .slick-slide .img-wrap {
  height: 100%;
  display: flex !important;
  align-items: center;
}

.slider-for-2 img {
  width: 100%;
  object-fit: cover;
  margin-right: 10px;
  transition: all 0.2s ease;
  cursor: pointer
}

.header_img img {
  width: 100%;
  margin: 0;
  object-fit: contain;
}

.prod_imgs {
  float: left;
  width: 46%;
  height: 190px;
  position: relative;
}

.prod_imgs:first-child {
  margin-right: 7%;
}

.tienda_lft {
  float: left;
  margin: 30px 0 0 0;
}

.tienda_rht {
  float: right;
  margin: 30px 0 0 0;
}

.tienda_lft ul {
  padding: 0;
}

.tienda_lft ul li {
  font-family: 'Basier Circle';
  font-size: 20px;
}

.tienda_lft ul li a {
  color: #000
}

.tienda_rht h3 {
  text-align: right;
  font-family: 'Basier Circle';
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.price h4 {
  font-family: 'Basier Circle';
  font-size: 36px;
  font-weight: bold;
}

.new_price {
  display: flex;
}

.price .actual_price {
  background: url("../public/assets/images/pattern.png") repeat;
  font-weight: normal;
}

.price .discount_price {
  font-weight: bold;
  color: #0282f9;
  margin-left: 20px;
}

.price button {
  font-family: 'Basier Circle';
  font-size: 22px;
  font-weight: bold;
  border: 4px solid #000;
  padding: 5px 50px;
  margin: 0 0 60px 0;
  width: 225px;
}

.content_ten p,
.content_ten div {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.margin_up {
  margin: 50px 0 0 0;
}

.image_slider_actualidadvideo img {
  width: 100%;
}

.prodEditions .card-wrapper {
  border-left: 0;
  border-right: 0;

}

.prodEditions .slick-slide {
  margin-right: 0;
}

.prodEditions .card-wrapper .title {
  height: 43px;
  margin-bottom: 5px;
}

.prodEditions .slick-slide.slick-active.slick-current {
  padding-left: 0px;
}

.prodEditions .btn2 {
  margin-top: 15px;
}

.prodEditions .slick-track {
  padding: 0;
  float: left;
}

.prodEditions .inner-wrap-prod.noslider .col-2 {
  max-width: 166px;
  flex: auto;
  padding-right: 20px;
}

.inner-wrap-prod.rowdata.noslider {
  margin: 0 15px
}

.inner-wrap-prod.rowdata .col-2:last-child {
  padding-right: 0px;
}

.noticias_1 img {
  width: 100%;
  height: 100%;
}

.connoticias_1 h1 {
  font-family: 'Basier Circle';
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.image_slider_longnews a {
  color: #000;
}

.prodEditions {
  margin-bottom: 40px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.imglft {
  float: left;
  width: 250px;
  height: 352px;
  position: relative;
  cursor: pointer;
}

.imglft img {
  width: 100%;
  height: 100%;
}

.listrht {
  float: right;
  width: 58%;
}

.listrht_img {
  width: 184px;
  float: left;
  height: 104px;
  position: relative;
}

.listrht_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listrht ul {
  margin: 0;
  padding: 0;
}

.listrht ul li {
  margin: 0 0 5px 0;
  display: table;
}

.listrht p {
  font-size: 16px;
  color: #000;
}

.listrht a:hover {
  text-decoration: none;
}

.actualidad_wrap_lft .actualidad_wrap_lft_img {
  width: 100%;
}

.actualidad_wrap_lft img {
  width: 100%;
  height: 100%;
}

.actualidad_wrap_lft a:hover,
.image_slider_longnews a:hover {
  text-decoration: none;
}

.actualidad_longnews_heading {
  font-size: 42px;
  font-weight: bold;
  margin: 20px 0 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 60px;
  cursor: pointer;
}

.actualidad_longnews_ellipsis {
  font-size: 17px;
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 55px;
  cursor: pointer
}

.image_slider_longnews .connoticias_1 h1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 60px;
}

.image_slider_longnews .connoticias_1 p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.schedule {
  margin-bottom: 80px;
}

.connoticias_1 p {
  color: #000;
}

.actual-sec-1 .col-7,
.actual-sec-1 .col-7 {
  padding-right: 0px;
  padding-left: 0px;
}

.actual-sec-1 .row {
  margin-right: 0;
  margin-left: 0;
}

.actual-sec-1 .col-7 {
  flex: 0 0 63% !important;
  max-width: 63% !important;
}

.actual-sec-1 .col-5 {
  flex: 0 0 35% !important;
  max-width: 35% !important;
  padding-right: 0;
  padding-left: 0;
}

.actual-sec-1 .col-5 .col-6 {
  padding-right: 0;
}

.actual-sec-1 {
  margin-bottom: 65px;
}

.magzine .main-row {
  margin-right: 0;
  margin-left: 0;
}

.magzine {
  margin-bottom: 40px;
  margin-top: 30px;
}

.magazin-readMore {
  border-bottom: 1px solid black;
  /* width: auto; */
  /* padding: 7px 0 1px 0; */
  padding-bottom: 1px;
}

.magzine .text-block {
  cursor: pointer
}

.magzine .col-6.wrapper {
  padding-right: 0;
  padding-left: 0;
}

.actualidad_wrap_rht .row {
  margin-bottom: 28px;
}

.actualidad_wrap_rht .heaact_lft p {
  font-size: 16px;
}

.actualidad_wrap_rht .heaact_rht img {
  width: 100%;
  height: 100%;
}

.actualidad_shortnews_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 1em; */
  max-height: 63px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.image_slider_actualidadvideo .slick-track {
  padding: 0;
}

.image_slider_actualidadvideo .slick-slide {
  margin-right: 0px;
}

.actualidad_shortnews_heading {
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 1em; */
  min-height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.actualidad_content_list_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  max-height: 78px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.container.footer_sec {
  margin-top: 85px;
}

.heaact_lft {
  margin-bottom: 12px;
  line-height: 20px;
  cursor: pointer;
  padding-right: 3px;
}

.header_actualidad_rht li {
  clear: both;
}

.image_slider_longnews {
  margin-bottom: 40px;
}

/* .image_slider_longnews .slick-slide:nth-child(odd) {
  margin-left: 40px;
  margin-right: 40px;
} */

.image_slider_longnews .slick-list {
  margin-left: -20px;
  margin-right: -20px;
}

.image_slider_longnews .slick-slide {
  padding-left: 20px;
  padding-right: 20px;
}

.image_slider_longnews .slick-slide {
  margin: 0px;
}

.image_slider_longnews .slick-prev {
  top: 118px;
  left: 0px;
}

.image_slider_longnews .slick-next {
  top: 118px;
}

.image_slider_longnews .noticias_1 .img-wrapper {
  width: 100%;
  position: relative;
}

.image_slider_actualidadevents .res1 {
  width: 100%;
  margin-bottom: 15px;
}

.image_slider_actualidadevents .res1 img {
  height: 100%;
}

.image_slider_actualidadevents a:hover {
  text-decoration: none;
}

.image_slider_actualidadvideo .img_Wrap {
  height: 350px;
  position: relative;
}

.image_slider_actualidadvideo .img_Wrap img {
  height: 347px;
  width: 620px;
}

.image_slider_actualidadevents .slick-next {
  right: 12px;
  top: 28px;
}

.image_slider_actualidadevents .slick-prev {
  right: 52px;
  top: 28px;
  left: unset;
}

.schedule .col-6:first-child {
  padding-right: 40px;
}

.schedule {
  margin-top: 20px;
  margin-bottom: 60px;
}


.actualidadproduct .image_slider_homeproducts .slick-slide.slick-active.slick-current,
.actualidadproduct .image_slider_homeproducts .slick-slide {
  padding-left: 0;
}

.subs-email input {
  width: 300px;
  height: 42px;
}

.subs-privacy-link a {
  color: #000;
}

.actualidadproduct .row {
  margin-right: 0;
  margin-left: 0;
}

.schedule .col-6 {
  padding-right: 0;
  padding-left: 0;
}

.image_slider_actualidadevents .connoticias1 h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 5px 0 0px;
  font-size: 15px;
  min-height: 0px;
  font-weight: bold;
  line-height: 21px;
  min-height: 41px;
}

.actualidad_content_list_heading {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 5px 0 0px;
  font-size: 15px;
  min-height: 0px;
  font-weight: bold;
  line-height: 21px;
}

.image_slider_actualidadevents .actualidad_shortnewscarr_ellipsis p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  min-height: 20px;
  margin: 5px 0 0px;
  margin: 0px 0 20px;
  line-height: 21px;
}

.actualidad_video_heading {
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0 5px 0;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 45px;
  cursor: pointer;
}

.actualidadproduct .row .col-12 {
  padding-right: 0;
  padding-left: 0;
}

.actualidad_video_ellipsis {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 45px;
  cursor: pointer;
}

.image_slider_actualidadvideo .slick-prev,
.image_slider_actualidadvideo .slick-next {
  top: 180px;
}

.image_slider_actualidadevents .connoticias1 {
  height: auto;
  cursor: pointer
}

.schedule .row {
  margin-right: 0;
  margin-left: 0;
}

i.large.material-icons.box10 {
  padding: 4px 10px;
  cursor: pointer;
}

.mat-box5-input {
  width: 460px;
  border: 1px solid #000;
  margin-top: 0;
  vertical-align: top;
  margin-left: 40px;
}

.eventModal {
  width: 1072px !important;
}

.tabdataabove,
.tabdatabelow,
.mobdata {
  display: none;
}

.eventModal .ant-modal-content {
  padding: 30px !important;
  max-height: calc(100vh - 17%);
  overflow: auto;
}

.eventModal .ant-modal-footer {
  display: none !important;
}

.eventModal .col-5 .eventImg {
  width: 100%;
  height: auto;
}

.eventModal .ant-modal-close {
  top: -15px;
  right: 4px;
}

.eventModal .ant-modal-close-x {
  width: 21px;
  height: 21px;
  color: #000;
}

.eventModal .col-5 .eventImg img {
  width: 100%;
  height: 100%;
}

.eventModal .col-7,
.eventModal .col-5 {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.eventModal .eventTitle {
  font-size: 42px;
  font-weight: bold;
  line-height: 48px;
}

.eventModal .eventDetail {
  font-size: 18px;
  margin-top: 30px;
  height: auto;
  margin-bottom: 50px;
}

.eventModal .cta {
  width: 100%;
  bottom: 0;
  margin: 0;
}

.eventModal .eventCTA {
  padding: 3px 5px;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  border: 3px solid #000;
}

.eventModal .eventCTA a {
  color: #000;
}

.eventModal .eventDate {
  position: absolute;
  background: #fff;
  padding: 20px;
  top: 25px;
  left: 45px;
  width: 309px;
  height: 87px;
}

.eventModal .col-7 {
  padding-left: 0;
  padding-right: 0;
}

.eventModal .eventDate h5,
.eventModal .eventDate p {
  font-size: 18px;
}

.eventModal .eventDate h5 {
  font-weight: bold;
}

.noticias_content .img-wrapper img {
  width: 100%;
  height: 100%;
}

.image_slider_releases .noticias_content img {
  width: 100%;
  height: 272px;
}

.image_slider_releases .img-wrapper {
  position: relative;
}

.image_slider_releases h5 {
  font-size: 15px !important;
}

.agenda .res img {
  width: 100%;
  height: 100%;
}

.agenda a {
  text-decoration: none !important;
}

.search-outer-container {
  display: none;
  position: absolute;
  justify-content: center;
  transform: translateX(-50%);
  left: 50%;
}

.search-outer-container .search-wrapper {
  z-index: 200;
  width: 95%;

  margin-top: -5px;
}

.search-outer-container.showSearch {
  display: flex;
  z-index: 10;
}

.search-container {
  width: 1220px !important;
  background: #e2f1ff;
  position: relative;
  margin-top: 4px;
  left: 15px;
}

.search-container .main-row {
  padding: 30px !important;
}

.search-container .img-wrapper {
  position: relative;
  cursor: pointer;
}

.search-container .col-2 {
  margin-bottom: 15px;
}

.search-container .left-bar h6,
.search-container .right-bar h6,
.search-tab-mob .list-bar h6 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.search-container .left-bar ul,
.search-tab-mob .list-bar ul {
  padding-left: 0;
}

.search-container .left-bar ul label {
  font-size: 14px;
  margin: 10px 0;
  color: #000;
}

.search-container .right-bar h6 {
  margin-bottom: 20px;
}

.search-container .left-bar ul li {
  border-bottom: 1px solid #aaa;
  cursor: pointer;
}

.search-container .left-bar ul li a label {
  cursor: pointer;
}

.search-container .right-bar img {
  width: 134px;
  height: 208px;
  margin-top: 30px;
}

.search-container .right-bar h2 {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 45px;
}

.right-bar .btn2 {
  margin-top: 15px;

}

.search-triangle {
  width: 10px;
  height: 10px;
  border-bottom: solid 11px #e2f1ff;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
  position: absolute;
  top: -10px;
  left: 20px;
}

.search-tab-mob {
  position: relative;
  display: none;
}

.search-tab-mob .header-row {
  background: #000;
  padding: 30px;
}

.white-header-row {
  position: absolute;
  width: 55%;
  top: -66px;
  left: 0;
  background: #fff;
  height: 50px;
}

.white-header-row .search-resp-input {
  width: 83%;
  height: 32px;
  margin-top: 10px;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  margin-left: 15px;
}

.white-header-row .box5 {
  padding: 6px;
  margin: 10px 0 0 10px;
  width: 32px;
  height: 32px;
}

.white-header-row i.large.material-icons.box10 {
  padding: 17px 15px 4px;
  font-size: 21px;
}

.search-tab-mob .container-fluid {
  padding: 0px;
}

.search-resp-input {
  /*border: 1px solid #fff;*/
  margin-top: 0;
  vertical-align: top;
 /* width: 520px;
  height: 42px;
  padding: 10px;
  font-size: 15px;*/
  background: transparent;
  color: #fff;
}

.search-tab-mob .btn2 {
  border: 3px solid #fff;
  color: #fff;
  height: 42px;
  width: 148px;
  float: none;
  margin: 0;
}

.search-tab-mob .list-bar {
  background: #fff;
  padding: 30px;
}

.search-tab-mob .list-bar ul label {
  font-size: 17px;
  margin: 10px 0;
  color: #000;
}

.search-tab-mob .list-bar ul li {
  border-bottom: 1px solid #000;
}

.search-triangle-black {
  width: 10px;
  height: 10px;
  border-bottom: solid 11px #000;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
  position: absolute;
  top: -10px;
  right: 150px;
}

.search-container #myBtn2 .material-icons {
  color: #000;
  font-size: 21px;
}

.personmenu-tab-mob {
  background: #000;
  display: none;
  position: absolute;
  z-index: 999;
  left: 0;
  width: 100%;
}

.personmenu-tab-mob .list-bar ul {
  padding-left: 10px;
  padding-right: 10px;
}

.personmenu-tab-mob .list-bar ul li {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.personmenu-tab-mob .list-bar ul a {
  font-size: 17px;
  margin: 10px 0;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.personmenu-tab-mob .list-bar {
  margin: 55px 20px 40px;
}

.personmenu-triangle-black {
  width: 10px;
  height: 10px;
  border-bottom: solid 11px #000;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
  position: absolute;
  top: -10px;
  right: 170px;
}

.cont_form u {
  float: right;
  margin-top: 22px;
  font-size: 14px;
  font-weight: bold;
}

.cont_form .ant-form-horizontal u {
  margin-top: 0px;
}

.personmenu {
  padding-left: 0;
}

.cont_form .buttonRight {
  margin-bottom: 15px;
}

.cont_form .ant-form-item input[type="checkbox"] {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 10px;
}

.cont_form .ant-btn>span {
  display: inline-block;
  font-weight: bold;
}

.ant-btn-primary.btn2_cont,
.btn2_cont.right {
  margin-top: 40px;
  width: 100px;
  padding: 7px 15px !important;
  height: 40px;
}

.ant-form-horizontal .ant-btn-primary.btn2_cont,
.ant-form-horizontal .btn2_cont.right {
  margin-top: 0px;
}

.ant-btn-primary.btn2_cont.xxl {
  width: 140px;
}

.btn2_cont.danger {
  border: 3px solid #0282f9 !important;
  color: #0282f9 !important;
}

.btn2_cont.right {
  float: right;
}

.cont_form ul.ant-pagination {
  margin-top: 30px;
  padding: 0;
  border-bottom: 0
}

.myspace .table-form {
  margin: 0;
  table-layout: fixed;
}

.myspace .heading_lft_cont .line4 {
  width: 93%;
}

.myspace .row {
  margin-right: 0;
  margin-left: 0;
}

.personmenuicon {
  display: none;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.ayuda .faq-select-tab-mob {
  display: none;
}

.svg-inline--fa.fa-chevron-right {
  font-size: x-large;
}

.ayuda .row .col-3,
.ayuda .row .col-9 {
  padding-left: 0;
  padding-right: 0;
}

.ayuda .row {
  margin-right: 0;
  margin-left: 0;
}

.ayuda .col_wrap {
  position: relative;
  width: 100%;
}

.ayuda .card-body1 {
  margin-bottom: 60px;
}

.ayuda .subheading {
  margin-bottom: 30px;
}

.ayuda .subheading br {
  display: none;
}

.ayuda .col_wrap::after {
  content: "\f0da";
  color: #000;
  top: 12px;
  right: 10px;
  position: absolute;
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: bold;
}

.ayuda .col_wrap[aria-expanded="true"]::after {
  content: "\f0d7";
}

.ayuda .line4.line-mob {
  display: none;
}

.forgotpasswordmsg .subheading {
  margin-top: 45px;
  margin-bottom: 80px;
}

.forgotpassword .ant-input-password,
.registrar .ant-input-password {

  border: 1px solid;
  padding: 0px 11px 0px 11px;
}

.forgotpassword .ant-input-password input,
.registrar .ant-input-password input {
  margin: 0 !important;
}

.p-relative {
  position: relative;
}

.eyeimg {
  position: absolute;
  right: 15px;
  top: 15px;
}

.mb-50 {
  margin-bottom: 50px;
}

.cart-count {
  color: #000;
  font-size: 12px;
  border: 1px solid #000;
  border-left: 0 !important;
  padding: 9px 10px;
  font-weight: bold;
  display: none;
}

.shopping_cart {
  display: flex;
}

.white-header .cart-count {
  border: 1px solid #fff;
  color: #fff;
}

.showCart {
  display: flex !important;
  justify-content: center;
}

.black-border {
  border-bottom: 1px solid #000;
}

.white-border {
  border-bottom: 1px solid #fff;
}

.catalogo .heading-wrap h2 {
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 11px;
}

.navigation-w .nav-item.active {
  background-color: #fff;
  border: 4px solid;
  border-bottom: none;
}

.navigation-w .nav-link {
  color: #000 !important;
}

.navigation-w .nav-link:hover {
  border-radius: 0;
}

.navigation-w .align-tab li {
  border-bottom: 0;
}

.publishline.cont-wrap {
  padding: 0;
  margin-bottom: 40px;
}

.collection_text {
  font-size: 15px;
  line-height: 21px;
  width: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 65px;
  margin: 0px auto !important;
}

.catTitle p {
  margin-bottom: 45px;
}

.cat-select-tab-mob {
  display: none;
}

ul.nav.nav-tabs.align-tab span {
  position: absolute;
  top: 80px;
}

.small-nav.mob {
  display: none;
}

.search {
  position: relative;
  margin-top: 40px;
}

.radio1edcion h4 {
  font-family: 'Basier Circle';
  font-size: 14px;
  font-weight: bold;
  margin: 40px 0 20px 0;
  color: #000;
  text-transform: uppercase;
}

.radio1edcion2 {
  margin: 15px 0 0 0;
}

.calender {
  margin: 15px 0 0 0;
}

.navbar-expand-md .navbar-nav {
  flex-direction: column;
  display: table;
}

.catTitle p {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.filter-div-show {
  animation: slidedown .5s ease-in-out;
  bottom: 0;
  display: block !important;
  background-color: #fff;
}

.filter-div .navi {
  width: 90%;
}

.producttitlerow .ant-select-selector {
  height: 42px !important;
}

.producttitlerow .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #000;
}

.producttitlerow .drowdown {
  margin-top: -10px;
}

.category_desc {
  font-size: 15px;
  line-height: 21px;
  font-weight: normal;
  margin-top: 40px;
}

.filter-header-tab,
.restaurar-btn,
.filter-btn,
.filter-footer-tab {
  display: none;
}

.cont-rht-4 img {
  display: table-row;
  width: 100%;
  float: left;
}

.card-wrapper {
  width: 166px;
  border-left: 7px solid #fff;
  border-right: 7px solid #fff;
  cursor: pointer;
}

.card-wrapper span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
  font-size: 15px;
}

.card-wrapper .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  font-size: 15px;
  margin-top: 16px
}

.card-wrapper .img-wrapper {
  width: 100%;
  height: 257px;
  position: relative;
}

.card-wrapper a {
  color: #000;
  text-decoration: none;
}

.card-wrapper a:hover {
  text-decoration: none;
}

.social-icon-prod-tab {
  display: none;
  margin-top: 30px;
}

.social-icon-prod-tab h3 {
  font-size: 14px;
  font-weight: bold;
}

.inner-wrap-prod.rowdata .col-2 {
  padding-left: 0;
  padding-right: 0;
}

.social-icon-prod-tab .social4 ul {
  float: none;
}

.card-wrapper .img-wrapper img {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}

ol._2jvtI {
  padding-left: 0;
}

.product-div .ant-pagination {
  margin-top: 0px;
  margin-bottom: 40px;
}

.cookieconfigmodal .card {
  margin-bottom: 15px;
}

.cookieconfigmodal .card-header {
  padding: 10px;
  background-color: #fff;
  border: 4px solid #000;
}

.blue-pattern-layer {
  display: none;
}

.cookieconfigmodal .collapse-btn {
  border: none;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.cookie-text {
  font-size: 18px;
  margin: 20px 0 !important;
}

.cookieconfigmodal .card {
  border: 0;
}

.cookieconfigmodal .collapse-btn:before {
  font-family: 'Glyphicons Halflings';
  content: "\e072";
  float: left;
  transition: all 0.5s;
  margin-right: 10px;
  font-size: 15px;
  margin-top: 3px;
}

.hide {
  display: none !important;
}

.cookiemodalpopup {
  border: 10px solid #000;
  padding: 60px;
}

.cookiemodalpopup .cookie-btn {
  font-size: 14px;
  border: 3px solid #000;
  font-weight: bold;
  padding: 2px 15px;
}

.ant-modal.cookieModal {
  width: 1440px !important;
  position: fixed;
  top: auto;
  left: auto;
  right: auto;
  bottom: 0;
  padding: 0;


}

.cookiemodalpopup a {
  color: #000;
}

.ant-modal-wrap {
  display: flex;
  justify-content: center;
}

.cookiemodalpopup .ant-modal-content {
  animation: slide .7s ease-in;
}

.cookieModal .ant-modal-body {
  padding: 0;
}

.cookieModal .ant-modal-footer {
  display: none;
}

.wrapper-content {
  margin-bottom: 30px;
}

.wrapper-content .cookie-list {
  padding-left: 0;
  margin-top: 15px;
}

.wrapper-content .cookie-list li {
  list-style: disc;
  margin-left: 8px;
}

.wrapper-content .cookie-list.roman li {
  list-style: upper-roman;
  margin-left: 15px;
}

.wrapper-content .cookie-link {
  display: block;
  text-decoration: underline;
  margin-top: 15px;
}

.wrapper-content .table {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.wrapper-content a {
  color: #000;
  text-decoration: underline;
}

.addressskeleton .addressskeletonbtn span {
  width: 145px;
}

.orderskeleton .orderskeletonbtn {
  width: 100px;
}

.social404 .nav-tabs {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.social404 ul li a {
  color: #000;
}

.btn404 {
  border: 3px solid #000;
  padding: 5px 10px;
  width: 150px;
  border-radius: 0;
  margin: 25px 0;
  font-weight: bold;
  font-size: 14px;
  color: #000;
  text-align: center;
  display: inline-block;
}

.btn404:hover {
  color: #000;
  text-decoration: none;
}

.img404,
.img-thanks {
  margin: 0 auto 20px;
}

.img404 img,
.img-thanks img {
  width: 100%;
}

.pink-pattern-layer img {
  width: 100%;
}

.pink-div-layer {
  background-color: #ffeef5;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  color: #f9026a;
  font-size: 12px;
  margin-top: -2px;
}

.blue-div-layer {
  background-color: #e2f1ff;
  height: 200px;
  width: 100%;
  padding: 30px 20px;
  color: #000;
  font-size: 12px;
}

.blue-div p {
  margin-top: 0;
}

.blue-div-layer p {
  margin-bottom: 5px;
}

.custom_radio .ant-radio-inner::after {
  background-color: #000;
}

.custom_radio .ant-radio-inner {
  top: 2px;
  left: 1px;
  border-color: #000;
}

.custom_radio.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #000;
}

.custom_radio .ant-radio-checked .ant-radio-inner {
  border-color: #000;
}

.searched-div {
  background-color: #e2f1ff;
  padding: 20px;
  color: #0282f9;
  display: none;
  margin-top: 30px;
  position: relative;
}

.showsearcheddiv.showsearcheddiv p {
  margin: 0
}

.showsearcheddiv.showsearcheddiv {
  display: block;
}

.searched-div .searched-div-close {
  position: absolute;
  top: -4px;
  right: 6px;
}

.custom_checkbox_wrapper {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  font-size: 15px;
}

/* Hide the browser's default checkbox */
.custom_checkbox_wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #000;
}

.custom_checkbox_wrapper input:checked~.checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom_checkbox_wrapper input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom_checkbox_wrapper .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.blue-div {
  background-color: #e2f1ff;
  padding: 20px;
  color: #0282f9;
  font-size: 12px;
  margin-bottom: 30px;
  height: 55px;
}

.reserveproducts {
  margin-top: 60px;
}

.laterproducts {
  margin-top: 80px;
}

.laterprod-blue-div {
  background-color: #e2f1ff;
  padding: 20px;
  color: #0282f9;
  font-size: 12px;
  margin-bottom: 60px;
  height: 99px;
}

.cart-step {
  margin-bottom: 80px;
}

.add-radio {
  float: left;
  margin-top: 6px;
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.add-radio.billing-choice {
  margin-top: 3px;
}

.sel-add p {
  margin-left: 22px;
  margin-top: 20px;
}

.postal-sel-add p {
  margin-bottom: 0px;
  font-size: 18px;
}

.selected-add {
  margin-left: 24px;
  margin-top: 65px;
  margin-bottom: 30px;
}

.no-courier {
  margin-left: 24px;
}

.no-courier p {
  margin-bottom: 5px;
}

.postal-sel-add {
  margin-left: 24px;
  margin-top: 45px;
  margin-bottom: 15px;
  float: left;
}

.postal-add p {
  font-size: 18px;
  margin-bottom: 0
}

.postal-add p b {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 25px;
}

.sel-btn {
  background-color: #e2f1ff;
  padding: 5px;
  color: #0282f9;
  border: 3px solid #0282f9;
  float: right;
  font-weight: bold;
  width: 189px;
  text-align: center;
  font-size: 14px;
  margin-top: -50px;
}

.sel-btn.new-address {
  margin-top: 0px;
}

.prefill-btn {
  color: #0282f9;
}

.address-btn {
  background-color: #e2f1ff !important;
  padding: 5px !important;
  color: #0282f9 !important;
  border: 3px solid #0282f9 !important;
  font-weight: bold !important;
  margin-left: 24px;
  float: right;
  width: 189px;
  text-align: center;
  height: 39px;
  margin-bottom: 20px;
  font-size: 14px;
  margin-top: 163px;

}

.checkforinvoice {
  display: flex;
}

.checkforinvoice .invoicerequired {
  margin-right: 20px;
}

.address-btn.bill-add {
  margin-top: -28px;
}

.step-5 .form-group {
  margin-bottom: 10px;
}

.atras-btn {
  background-color: #fff !important;
  padding: 5px !important;
  color: #aaa !important;
  border: 3px solid #aaa !important;
  font-weight: bold !important;
  float: left;
  margin-left: 20px;
  width: 120px;
}

.shop-add,
.ship-add {
  margin-bottom: 20px;
  font-size: 18px;
}

.shop-add p {
  font-size: 18px;
  margin-top: 10px;
  margin-left: 39px;
}

.shop-add span,
.ship-method span {
  font-size: 14px;
  margin-left: 10px;
}

.ship-add p b {
  font-size: 14px;
}

.ship-add p,
.selected-add p {
  font-size: 18px;
  margin-bottom: 0px;
}

.ship-add p:not(:first-child),
.ship-method p:not(:first-child),
.postal-add p:not(:first-child),
.sel-billing-add p:not(:first-child) {
  margin-bottom: 0px;
}

.ship-method p,
.selected-add p,
.no-courier p {
  font-size: 18px;
}

.ship-method p b,
.postal-sel-add p b {
  font-size: 14px;
  line-height: 27px;
}

a.disabled,
span.disabled {
  pointer-events: none;
  cursor: default;
}

.ship-method {
  margin-bottom: 30px;
  margin-left: 24px;
}

.shippingAddModal .ship-method {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.subs_disabled {
  cursor: default !important;
}

.shippingAddModal .ant-modal-footer {
  display: none;
}

.col_wrap_title {
  position: relative;
}

.showonTab {
  display: none;
}

.cart-accordion {
  margin-top: 20px;
}

.cart-accordion .col_wrap_title::after {
  content: "\f0da";
  color: #000;
  top: 12px;
  right: 10px;
  position: absolute;
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: bold;
}

.col_wrap_title[aria-expanded="true"]::after {
  content: "\f0d7";
}

.edit.col_wrap_title::after {
  color: #0282f9;
}

.delivery-accordion .acc_title {
  position: relative;
}

.delivery-accordion .acc_title::after {
  content: "\f0da";
  color: #000;
  top: -4px;
  right: 0px;
  position: absolute;
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: bold;
}

.acc_title[aria-expanded="true"]::after {
  content: "\f0d7";
}

.blue-div p {
  font-size: 12px !important;
  margin-top: 0;
  margin-left: 0 !important;
}

.shop-add .blue-div {
  margin-left: 38px;
}

.acc_text {
  margin-bottom: 10px;
}

.acc_text a {
  color: #000;
  font-weight: bold;
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

.tohidetd {
  display: none;
}

.blue {
  color: #0282f9 !important;
  text-decoration: none;
}

.info-select [type="radio"]:checked,
.info-select [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.info-select {
  margin-top: 60px;
}

.info-select form {
  font-size: 0;
}

.info-select form label {
  margin-bottom: 10px;
}

.cart-step .blue-text {
  color: #000;
  font-weight: normal;
}

.info-select h2 {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

.resumen h5 {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

.delivery-accordion {
  font-size: 12px;
  margin: 15px 0;
}

.delivery-mode {
  margin-bottom: 0;
}

.courier-name {
  margin-bottom: 10px;
  margin-top: 10px;
}

.courier {
  display: flex;
  justify-content: space-between;
}

.acc_text {
  margin-top: 10px;
}

.resumen .btn-side {
  width: 100%;
}

.order-details-add li {
  float: left;
  width: 50%;
  margin-bottom: 20px;
}

.resumen .acc_title a {
  color: #000;
}

.table-form-order {
  overflow: scroll;
  width: 100%;
  font-family: 'Basier Circle';
  margin: 0 0 40px 0;
  font-size: 15px;
}

.table-form-order tr th {
  border: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  padding: 0 0 10px 0;
  font-size: 14px;
}

.table-form-order tr td {
  border: 1px solid #000;
  padding: 10px;
}

.table-form-order tr:first-child {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.table-form-order tr td:first-child {
  text-align: center;
  width: 10%;
}

.table-form-order tr td a {
  font-family: 'Basier Circle';
  font-weight: bold;
  border: 4px solid #000;
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  text-align: center;
}

.table-form-order tr td:last-child {

  width: 15%;
}

.table-form-order p {
  padding: 0;
  margin: 6px 0;
  font-family: 'Basier Circle';
  font-weight: normal;
  font-size: 15px;
}

.blue-text {
  color: #0282f9;
  font-weight: bold;
  font-size: 15px;
}

.nrml-text {
  color: #000 !important;
  font-weight: bold !important;
  font-size: 15px;
  width: 100%;
}

.b-top {
  border-top: 1px solid #000;
  margin: 10px 0;
}

.empty-cart {
  text-align: center;
  padding: 100px 0;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.empty-latercart {
  text-align: center;
  padding: 15px 0;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.img-cart-empty {
  width: 150px;
  margin: 0 auto 20px;
}

.img-cart-empty img {
  width: 100%;
}

.empty-cart p {
  font-size: 20px;
}

.btn-continue {
  border: 3px solid #000;
  font-size: 18px;
  padding: 5px 20px;
  font-weight: bold;
  color: #000;
  margin-top: 10px;
  display: inline-block;
}

.btn-continue:hover {
  color: #000;
  text-decoration: none;
}

.ant-btn-sm {
  height: 30px !important;
  font-size: 16px !important;
  border: 2px solid #000 !important;
  font-weight: bold !important;
  background-color: #fff !important;
  color: #000 !important;
  padding: 0px 15px !important;
}

.ant-popover-message-title {
  font-weight: bold !important;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-14 {
  width: .875em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}

.fa-exclamation-triangle.fa-2x {
  font-size: 8em;
}

.btn-error {
  border: 3px solid #000;
  padding: 5px 10px;
  border-radius: 0;
  margin: 25px 0;
  font-weight: bold;
  font-size: 14px;
  color: #000;
  text-align: center;
  display: inline-block;
}

.btn-error:hover {
  color: #000;
  text-decoration: none;
}

.retry {
  width: 150px;
}

.change-pay {
  width: 250px;
}

.f-left {
  float: left;
}

.pdf-btn-div {
  float: right;
  margin-top: 50px;
}

.w-70 {
  width: 70%;
}

.pdf-btn {
  border: 3px solid #000;
  padding: 5px 10px;
  border-radius: 0;
  font-weight: bold;
  font-size: 14px;
  color: #000;
  text-align: center;
  display: block;
}

.pdf-btn:hover {
  color: #000;
  text-decoration: none;
}

.favourite span {
  cursor: pointer;
}

.inner-wrap-prod .slick-prev,
.inner-wrap-prod .slick-next {
  top: 29%;
}

.productos .header_img {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 15px;
}

.productos .prod_img-div .col-4 {
  flex: 0 0 30%;
  max-width: 30%;
}

.productos .prod_img-div .col-8 {
  flex: 0 0 70%;
  max-width: 70%;
}

.productos .slider-for-2 {
  margin-top: 10px;
  width: 100%;
}

.productos .slider-for-2>div {
  margin-right: -5px;
}

.productos .slider-for-2 .slick-slide {
  height: 162px;
  width: 130px;
  position: relative;
}

.productos .small-bread {
  margin-top: -7px;
}

.orderdetailimg {
  width: 70px;
  position: relative;
  height: 80px;
}

.tshirtdiv .row {
  height: 100%;
}

.tshirtdiv.col-6,
.tshirtdiv .col-12 {
  padding-right: 0px;
  padding-left: 0px;
}

.homepage_contest {
  position: relative;
}

.homepage_contest_content {
  width: 350px;
  position: absolute;
  left: 40px;
  color: #000
}

.banner_text_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}

.homepage_contest_content h3 {
  font-size: 42px;
  font-weight: bold;
  color: unset !important
}

.bannerText h3 {
  font-size: 42px;
  font-weight: bold;
  color: unset !important
}

.bannerText p {
  font-size: 20px;
  font-weight: normal;
}

.homepage_contest_content p {
  font-size: 20px;
}

.homereleases .img-wrapper {
  position: relative;
  height: 272px;
}

.homereleases .row {
  margin-right: 0;
  margin-left: 0;
  justify-content: center;
}

.homereleases .connoticias a {
  color: #000;
}

.homereleases .connoticias a:hover {
  text-decoration: none;
}

.homereleases .connoticias p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 30px;
  line-height: 34px;
  font-weight: bold;
  margin-top: 0px;
}

.homereleases .connoticias {
  padding: 15px;
}

.homereleases .connoticias h5 {
  font-size: 15px;
  line-height: 21px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.mer-card-content .img-wrapper {
  height: 257px;
  position: relative;
}

.mer-card-content h2 {
  font-size: 15px;
  line-height: 21px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 15px;
}

.mer-card-content .img-wrapper {
  height: 257px;
  position: relative;
}

.mer-filters-card .img-wrapper img {
  width: 100%;
  height: 100%;
}

.mer-filters-card.col-sm-6.col-md-4.col-xl-2 {
  padding-right: 5px;
}

.mer_filters .slick-prev,
.mer_filters .slick-next,
.mer_prod .slick-prev,
.mer_prod .slick-next {
  top: calc(50% - 25px);
}

.mer-filters-card .img-wrapper {
  position: relative;
  cursor: pointer
}

.mer-card-content label {
  margin: 0;
  font-family: 'Basier Circle';
  font-weight: bold;
  color: #0282f9;
  font-size: 15px;
  line-height: 21px;
}

.mer-card-content span {
  margin: 0 10px 0 0;
  font-weight: normal;
  color: #000;
  background: url("../public/assets/images/pattern.png") repeat;
}

.mer_prod .slick-prev,
.mer_prod .slick-next {
  top: 50%;
}

.bibli .inner-wrap {
  margin-top: 40px;
}

.bibli .inner-wrap .col-2 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.bibli .inner-wrap .col-2 .img-wrapper {
  height: 257px;
  position: relative;
}

.bibli .inner-wrap .row {
  margin-right: 0;
  margin-left: 0;

}

.bibli .inner-wrap .col-2:nth-child(7n) {
  padding-right: 0;
}

.bibli .inner-wrap .col-2 {
  flex: inherit;
  width: 182px;
  padding-right: 20px;
  padding-left: 0px;
  margin-bottom: 45px;
  cursor: pointer
}

.bibli .inner-wrap .col-2 p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.bibli .ant-pagination {
  margin-top: 15px;
  margin-bottom: 40px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #000 !important;
}

.prodEditions .img-wrapper img {
  transition: transform .5s ease;
}

.prodEditions .img-wrapper:hover img {
  transform: scale(1.5);
}

.container.footer_sec {
  width: 1440px !important;
  max-width: 1440px !important;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 0px !important;
  padding-left: 0px !important
}

.webs .editorial_wrap .A-la-venta-esta-sema,
.webs .editorial_wrap .line4 {
  width: 296px;
}

.webs a:hover {
  text-decoration: none;
}

.resultados .producttitlerow {
  margin-right: 0;
  margin-left: 0;
}

.resultados .navi2 ul li,
.resultados .searched-div {
  width: 280px;
}

.resultados .searched-div {
  margin-top: 50px;
}

.resultados .tabmobiledropdown {
  display: none;
}

.search-bar-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.result-search-bar {
  display: none;
  display: block;
  visibility: hidden;
}

.pdfviewer {
  margin-top: 20px
}

.pdfviewer .viewer,
.pdfviewer .viewer .webviewer {
  height: 67vh;
  border: 1px solid black;
}

.viewerModal {
  width: 90% !important;
}

.viewerModal.ant-modal {
  top: 0
}

.viewerModal .ant-modal-body {
  padding: 0;
}

.pdfModal {
  width: 90% !important;
  margin: 0 auto;
}

.viewerModal .ant-modal-footer {
  display: none;
}

.viewerModal .ant-modal-close {
  color: #fff;
}

.pdfModal .ant-modal-body {
  padding: 0;
}

.pdfModal .ant-modal-footer {
  display: none;
}

.pdfModal .ant-modal-body,
.pdfModal .ant-modal-content,
.pdfModal .viewer,
.pdfModal .webviewer,
.viewerModal .viewer,
.viewerModal .webviewer,
.viewerModal .ant-modal-content,
.viewerModal .ant-modal-body {
  height: 100%;
}

.pdfModal .ant-modal-close {
  top: -45px !important;
  right: -20px !important;
}

.fixed-link-div a {
  color: #000;
  font-size: 15px;
  line-height: 21px;
  font-weight: normal;
}

.fixed-link-div a:hover {
  text-decoration: none;
}

.is-sticky-home {
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  transition: transform 0.4s ease 0s;
  z-index: 400;
  width: 100%;
  height: 64px;
}

.is-sticky-home .small-nav li {
  padding-bottom: 5px;
}

.is-sticky-home .deco-wrap .navbar-nav a {
  color: #000;
  margin-left: 0;
}

.is-sticky-home .box5 {
  width: 28px;
  height: 28px;
  padding: 5px;
  border: 1px solid #000;

}

.is-sticky-home .showCart {
  width: 28px;
  height: 28px;
  padding: 4px 10px;
  border: 1px solid #000;
  color: #000;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.is-sticky-home .social-media-wrapper i {
  color: #000 !important;
  font-size: 17px !important;
}

.is-sticky-home .small-nav li a {
  color: #000 !important;
}

.is-sticky-home .small-nav ul.laEditorialUl li a {
  color: #fff !important;
}

.is-sticky-home .navbar-expand-md .navbar-nav {
  padding: 0;
  margin-top: -13px;
}

.is-sticky-home .nav-inner ul li.nav-item {
  padding-left: 20px;
  padding-right: 0;
}

.is-sticky-home .major-nav .navbar .navbar-nav.deco ul {
  background-color: #000 !important;
}

.is-sticky-home .major-nav .navbar .navbar-nav.deco .novedades {
  margin: 0 -90px;
  top: 2px;
}

.is-sticky-home .major-nav .navbar .navbar-nav.deco .arrownavcata {
  top: 12px;
}

.is-sticky-home .major-nav .navbar .navbar-nav.deco .espac {
  top: 3px;
}

.is-sticky-home .major-nav .navbar .navbar-nav.deco ul a {
  color: #fff !important;
}

.is-sticky-home .triangle-4,
.is-sticky-home .triangle-4cata,
.is-sticky-home .triangle-4espac {
  border-bottom: solid 11px #000;
}

.is-sticky-home .b1,
.is-sticky-home .b2 {
  border-right: 1px solid #fff;
}

.sticky-logo.logo {
  width: 90px;
  height: 35px;
  margin-top: -8px;
}

.ant-scrolling-effect {
  overflow: visible !important;
  width: 100% !important;
}

.ant-modal-wrap {
  overflow: visible !important;
}

.slide-left {
  opacity: 0;
  transform: translateX(-50px);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.slide-left.animation {
  opacity: 1;
  transform: translateX(0px) !important;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.header-zoom-in.animation img {
  -webkit-transition: transform 4s ease-in-out;
  transition: transform 4s ease-in-out;
  transform: scale(1.1);
}

.zoom-in.animation {
  -webkit-transition: transform 4s ease-in-out;
  transition: transform 4s ease-in-out;
  transform: scale(1.1);
}

.homepagecontestsec,
.image_slider_homereleases_content .img-wrapper,
.scale-in {
  overflow: hidden;
}

.scale-in:hover img {
  transform: scale(1.5);
  transition: transform .5s ease;
}

.userNotValidated label {
  margin-top: 20px;
  font-size: 14px;
  color: #db6a6a;
}

.publishLineHover {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.eccPublishingLine:hover .publishLineHover {
  bottom: 0;
  height: 100%;
}

.defaultForm .addMarginBottom {
  margin-bottom: 30px;
}

.billingAdressSelectedText {
  margin-left: 30px;
}

.sel-billing-add .add-radio {
  margin-top: 3px;
  margin-right: 0
}

.step-4 .datafilled {
  border-color: #007bff;
}

.sel-billing-add p.direccion {
  margin-top: 25px
}

.text-left {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
}

input {
  border-radius: 0;
  border: 1px solid;
}

input[type="search"] {
  -webkit-appearance: none;
}

.ant-picker-input>input {
  text-align: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0px !important;
}

.ant-picker-range-separator {
  line-height: 0 !important;
}

.verMas {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.ckeditor {
  font-family: 'Basier Circle' !important;
}

.ckeditor table {
  width: 100%;
}

.ckeditor h1,
.ckeditor h2,
.ckeditor h3,
.ckeditor h4,
.ckeditor h5,
.ckeditor h6 {
  color: #000 !important
}

.ckeditor h2 {
  font-size: 60px;
}

.ckeditor h3 {
  font-size: 36px;
}

.ckeditor h4 {
  font-size: 32px;
}

.ckeditor ul {
  margin-top: revert;
}

.ckeditor ul li {
  list-style: disc;
  font-size: 19px;
}

.ant-input-password input {
  border: none !important
}

.btn2.disabled,
.btn2.reservar.disabled {
  width: 260px
}

.product .btn2.disabled,
.product .btn2.reservar.disabled {
  width: 140px
}

.product a:hover {
  text-decoration: none !important;
}

.contentLinks {
  color: #000 !important;
  text-decoration: none !important;
}

.cont-wrap-cover.product {
  margin-top: 42px
}
.cont-wrap-cover.product .cont-rht {
	margin-left: -10px;
}

.ant-image-mask-info {
  display: flex;
  align-items: center;
}

.productos .ant-image {
  width: 100%;
}

.comic_specifics_name span {
  display: none;
}

.comic_specifics {
  display: flex;
  gap: 5px;
}

.comic_specifics_name span:nth-child(-n+3) {
  display: inline-block;
}

.comic_specifics_name span:nth-child(n+4) {
  display: none;
}

.comic_specifics_name.show_more span:nth-child(n+4) {
  display: inline-block;
}

.comic_specifics_name.show_more_div span:nth-child(3)::after {
  content: "...";
  display: inline-block;
  margin-left: 5px;
}

.comic_specifics_name.show_more span:nth-child(3)::after {
  display: none;
}

.show_all_wrapper a {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0282f9 !important;
}

.show_all_wrapper a i {
  font-size: 10px;
}

/*
.product_details_intro{
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; 
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  width: 100%; 
  position: relative;
}
.product_details_intro::before {
  content: '... ';
  position: absolute;
  right: 0;
  bottom: 7px;
  background-color: white; 
  padding-right: 20%;
}

.product_details_intro::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20%;
  background-color: white; 
}
.product_details_intro_wrapper{position: relative;
}

.product_details_intro_wrapper .show_all_wrapper {
  position: absolute;
  bottom: 8px;
  right: 105px;
}*/

.product-details-options {
  margin-top: 50px;
}

.product-details-options .navbar-expand-md .navbar-nav {
  padding: 0;
}

.product-details-options .navbar {
  padding-left: 0;
}

.product-details-options .navbar-nav {
  width: 100%;
  display: flex;
  flex-direction: unset;
}

.product-details-options .navi3 {
  width: 100%;
}

.product-details-options .navi3 li {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  cursor: pointer
}

.product-details-options .navi3 li a {
  font-size: 20px;
  font-weight: 600;
  color: #aaa;
}

.product-details-options .navi3 li.active a {
  font-family: 'Basier Circle';
  font-weight: bold;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.product-details-options-content .noticia-list ul {
  padding: 0;
  margin-left: 18px
}

.product-details-options-content ul li {
  list-style: initial !important;
}

.product-details-options-content {
  margin-top: 26px;
}

.product-details-options-content .noticia-list ul li a {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #0282f9 !important;
}

.product-details-options-content .noticia-list ul li:first-child {
  border-top: 0;
}

.product-details-options {
  padding-bottom: 50px;
  border-bottom: 1px solid #000;
}

.product-details-options-content .noticia-list ul li {
  padding: 5px 0;
  border-top: 1px solid #000;
}

.autores-content .title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  color: #0282f9;
  margin-bottom: 10px;
}

.autores-content .description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
}

.autores-content .content-wrapper {
  padding: 10px 0 20px;
  border-bottom: 1px solid #000;
}

.contenidos-content ul {
  padding-left: 15px;
}

.contenidos-content ul li {
  list-style-type: disc;
  color: #000;
  border-top: 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
}

.contenidos-content ul li a {
  color: #0282f9;
}

.contenidos-content .title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  color: #000;
}

.contenidos-content .para {
  border-top: 1px solid #000;
  padding-top: 20px;
}

.resenas-content .title,
.agenda-content .title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
}

.agenda-content .content-wrapper {
  border-bottom: 1px solid #000;
  padding: 10px 0;
  gap: 5px;
}

.resenas-content .content-wrapper {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #000;
  padding: 10px 0;
}

.resenas-content .title span {
  color: #0282f9;
  padding-right: 5px;
}

.leer-cta {
  height: 52px;
  background-color: #000;
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leer-cta a {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #fff !important;
  border: none;
  margin-bottom: 0px;
}

.video-wrapper {
  height: 190px;
  width: 100%;
  margin-top: 30px
}

.product-details-social-media {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.product-details-social-media h3 {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
}

.content_ten_tab {
  display: none;
}

.product_details .title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  font-size: 15px;
  margin-top: 16px;
  margin-bottom: 0;
}

.product_details .authors {
  margin-bottom: 0px;
}

.fob {
  color: #bbbaba;
}

.related-products-wrapper {
  display: flex;
  /*flex-direction: row-reverse;*/
  gap: 60px;
}

.ediciones-products {
  width: 30%;
  height: 583px;
  background-color: #ededed;
  padding: 29px 0px 36px;
  position: relative;
}

.ediciones-products .title-header {
  padding: 0 22px;
}

.relacionados-products {
  width: 70%;
  padding-top: 29px;
}

.relacionados-products .slick-list {
  margin-left: -14px;
}

.ediciones-products h2,
.relacionados-products h2 {
  font-size: 30px;
}

.ediciones-products .slider-content {
  margin-top: 10px;
  padding: 0 82px;
}

.image_slider_ediciones_content .img-wrapper {
  width: 100%;
  height: 260px;
  position: relative;

}

.relacionados-products .slick-prev,
.relacionados-products .slick-next {
  top: 35%;
}

.ediciones-products .slick-arrow {
  display: block !important;
}

.ediciones-products .slick-prev {
  left: 220px;
  top: -60px;
  background: transparent !important;
}

.ediciones-products .slick-next {
  right: -70px;
  top: -60px;
  background: transparent !important;
}

.product-details-tab-mob-tab {
  display: none;
}

.product-details-tab-mob-tab .header-title {
  border: solid 4px #000;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: -4px;
}

.product-details-tab-mob-tab .toggle-description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.image_slider_ediciones_content .card-wrapper {
  width: 100%;
  padding-left: 14px
}

.datos-content p {
  margin-bottom: 0;
}

.product_icon_list {
  display: flex;
  margin-left: -10px
}

.product_icon_wrapper {
  margin-bottom: 25px;
  text-align: center;
  padding: 10px
}

.product_icon_wrapper .product_text {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;

}

.product_icon_wrapper .product_icon img {
  width: 100%;
  height: 100%;
}

.notab-datos-content p {
  margin-bottom: 0;
}

.notab-datos-content {
  padding: 30px 0;
  border-bottom: solid 1px #000;
}

.confirmCustomModal .modalChangeAddress .buttonWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.confirmCustomModal .modalChangeAddress .buttonWrapper button {
  margin-top: 10px
}

.confirmCustomModal {
  width: 500px !important
}

.confirmCustomModal .ant-modal-confirm-btns {
  display: none;
}

.attribute-inner-wrapper{
	max-height: 475px;
    overflow: hidden;
    overflow-y: auto;
}

@keyframes slidedown {
  0% {
    bottom: 160px;
  }

  100% {
    bottom: 0;
  }
}

@keyframes slide {
  0% {

    bottom: 0px;
  }

  100% {

    bottom: 160px;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}