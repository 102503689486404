@media only screen and (min-width: 1920px) {
	.promo-batman-2024 .promotion-banner{
		margin-top:155px
	}
	
}
@media only screen and (min-width:1441px) and (max-width:1919px) {
	.promo-batman-2024 .promotion-banner{
		margin-top:155px
	}
	.promo-batman-2024 .promotion-section-4 .img-wrapper{
		height: 476px;
	}
}
@media only screen and (max-width: 1440px) {
	.promo-batman-2024 .promotion-banner{
		margin-top:155px
	}
	.promo-batman-2024 .promotion-section-4 .img-wrapper{
		height: 476px;
	}
}
@media only screen and (max-width: 1141px) {
	
	.promo-batman-2024 .promotion-banner{
		margin-top:155px
	}
	.promo-batman-2024 .promotion-section-4 .img-wrapper{
		height: 390px;
	}
}

@media only screen and (max-width: 767px) {
	.promo-batman-2024 .videoPromo{width: 100%;}
	.promo-batman-2024 .promotion-banner{
		margin-top:0
	}
	.promo-batman-2024 .promotion-banner{
		margin-top:155px
	}
	.promo-batman-2024 .promotion-section-4 .img-wrapper{
		height: 305px;
	}
	.promo-batman-2024 .creditoDC{
		color: #fff;
		margin: 5px;
		font-size: 12px;

	}
	.promo-batman-2024 .ant-input-search-large{
		width:320px!important
	}
	.promo-batman-2024.cta a{
		height: auto;
	}
	.promo-batman-2024 .logo{display: none;}
	.promo-batman-2024 .logo-promo{text-align: center;}
	.promo-batman-2024 .promotion-header-wrapper {
        display: block;
    }
	.promo-batman-2024.localiza-search-input{
		width: 100%;
	}
	.promo-batman-2024 .content-libraries {
		display: block;
		
	}
	.promo-batman-2024 .wrapper-libraries {	
		width: 100%;
	}
	.promo-batman-2024 .wrapper-maps-libraries {		
		display: block;
		height: 600px;
		margin-top: 30px;
		width: 100%;
	}
	.promo-batman-2024 .ant-pagination-prev, .promo-batman-2024 .ant-pagination-next,.promo-batman-2024 .ant-pagination-jump-prev,.promo-batman-2024 .ant-pagination-jump-next, .promo-batman-2024 .ant-pagination-item{
		min-width: 24px !important;
		width: 23px !important;
		height: 24px !important;
	}
	.promo-batman-2024 .ant-pagination-prev .ant-pagination-item-link,.promo-batman-2024 .ant-pagination-next .ant-pagination-item-link {
		font-size: 9px;
	}
	
	
}